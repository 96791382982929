import {
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Self,
  ViewContainerRef,
  inject,
  input,
  output,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ButtonComponent } from '@spaces-ui/meadow/components/button';
import { IconComponent } from '@spaces-ui/meadow/icons';
import { Close, Hide_Outline, Search, View_Outline } from '@spaces-ui/meadow/icons/svgs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[type="text"], input[type="password"], input[type="search"]',
  standalone: true,
})
export class InputDirective implements OnInit, OnDestroy {
  private element = inject(ElementRef);
  private renderer = inject(Renderer2);
  private viewContainerRef = inject(ViewContainerRef);

  public label = input<string>();
  public description = input<string>();
  public customErrorMessages = input<{ [key: string]: string }>({});

  public clear = output<void>();

  private control!: AbstractControl;
  private statusSubscription!: Subscription;
  private inputError!: HTMLDivElement;
  private nativeElement!: HTMLInputElement;
  private parentElement!: HTMLElement;

  public constructor(@Optional() @Self() private ngControl: NgControl) {}

  ngOnInit() {
    this.nativeElement = this.element.nativeElement;
    this.parentElement = this.renderer.parentNode(this.nativeElement);
    const inputType = this.nativeElement.attributes.getNamedItem('type')?.value;

    const labelString = this.label();
    const descriptionString = this.description();

    // Create the label element
    if (labelString) {
      const label = this.renderer.createElement('label') as HTMLElement;
      if (!descriptionString) {
        label.classList.add('no-description');
      }
      this.renderer.setAttribute(label, 'for', this.nativeElement.id);
      const labelText = this.renderer.createText(labelString);
      this.renderer.appendChild(label, labelText);
      this.renderer.insertBefore(this.parentElement, label, this.nativeElement);
    }

    // Create the description element
    if (descriptionString) {
      const description = this.renderer.createElement('div');
      this.renderer.addClass(description, 'input-description');
      const descriptionText = this.renderer.createText(descriptionString);
      this.renderer.appendChild(description, descriptionText);
      this.renderer.insertBefore(this.parentElement, description, this.nativeElement);
    }

    switch (inputType) {
      case 'search': {
        // surround the input with a container div and add a search icon (svg)
        const container = this.renderer.createElement('div');
        this.renderer.addClass(container, 'search-input-container');
        this.renderer.addClass(container, 'fill-flex');
        this.renderer.insertBefore(this.parentElement, container, this.nativeElement);
        this.renderer.appendChild(container, this.nativeElement);

        const clearBtn = this.viewContainerRef.createComponent(ButtonComponent);
        clearBtn.setInput('icon', Close);
        (clearBtn.location.nativeElement as HTMLElement).classList.add(
          'mdw-butto',
          'mdw-tertiary',
          'small-button',
          'clear-button',
        );
        (clearBtn.location.nativeElement as HTMLElement).addEventListener('click', () => {
          this.nativeElement.value = '';
          this.nativeElement.focus();
          this.clear.emit();
        });
        this.renderer.appendChild(container, clearBtn.location.nativeElement);

        const iconRef = this.viewContainerRef.createComponent(IconComponent);
        iconRef.instance.setIcon(Search);
        this.renderer.appendChild(container, iconRef.location.nativeElement);
        break;
      }
      case 'password': {
        // surround the input with a container div and add a show/hide password button
        const container = this.renderer.createElement('div');
        this.renderer.addClass(container, 'password-input-container');
        this.renderer.addClass(container, 'fill-flex');
        this.renderer.insertBefore(this.parentElement, container, this.nativeElement);
        this.renderer.appendChild(container, this.nativeElement);

        const showHideBtn = this.viewContainerRef.createComponent(ButtonComponent);
        showHideBtn.setInput('icon', View_Outline);
        (showHideBtn.location.nativeElement as HTMLElement).classList.add(
          'mdw-button',
          'mdw-tertiary',
          'small-button',
          'show-hide-button',
        );
        (showHideBtn.location.nativeElement as HTMLElement).addEventListener('click', () => {
          this.nativeElement.type = this.nativeElement.type === 'password' ? 'text' : 'password';
          showHideBtn.setInput(
            'icon',
            this.nativeElement.type === 'password' ? View_Outline : Hide_Outline,
          );
          this.nativeElement.focus();
        });
        this.renderer.appendChild(container, showHideBtn.location.nativeElement);
        break;
      }
    }

    if (this.ngControl && this.ngControl.control) {
      this.control = this.ngControl.control;

      this.statusSubscription = this.control.statusChanges.subscribe(() => {
        this.updateErrorDisplay();
      });

      // Initial display update
      this.updateErrorDisplay();
    }
  }

  ngOnDestroy() {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }

  private updateErrorDisplay() {
    this.removeErrorDisplay();

    if (this.control.errors && this.control.dirty) {
      const errorMessage = this.getErrorMessage();
      this.inputError = this.renderer.createElement('div');
      this.renderer.addClass(this.inputError, 'input-error');
      const errorText = this.renderer.createText(errorMessage);
      this.renderer.appendChild(this.inputError, errorText);
      this.renderer.appendChild(this.parentElement, this.inputError);
      this.renderer.addClass(this.nativeElement, 'error');
    }
  }

  private removeErrorDisplay() {
    if (this.inputError?.parentNode) {
      this.renderer.removeChild(this.inputError?.parentNode, this.inputError);
    }

    this.renderer.removeClass(this.nativeElement, 'error');
  }

  private getErrorMessage() {
    const errors = this.control.errors;
    let errorMessage = 'There seems to be an error with this field.';

    if (!errors) {
      return '';
    } else {
      Object.keys(errors).forEach(key => {
        if (this.customErrorMessages()[key]) {
          errorMessage = this.customErrorMessages()[key];
        } else {
          switch (key) {
            case 'required': {
              errorMessage = 'This field is required.';
            }
          }
        }
      });

      return errorMessage;
    }
  }
}
