/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { ColorPaletteService } from './color-palette.service';

@Component({
  selector: 'app-colors',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './colors.component.html',
  styleUrl: './colors.component.scss',
})
export class ColorsComponent implements OnInit {
  public neutrals: any[] = [];
  public blues: any[] = [];
  public greens: any[] = [];
  public teals: any[] = [];
  public yellows: any[] = [];
  public oranges: any[] = [];
  public reds: any[] = [];
  public pinks: any[] = [];
  public purples: any[] = [];

  constructor(private colorPaletteService: ColorPaletteService) {}

  async ngOnInit() {
    const colors = await this.colorPaletteService.getColorVariables();

    this.neutrals = this.filterColors(colors, [
      'N000',
      'N100',
      'N200',
      'N300',
      'N400',
      'N500',
      'N600',
      'N700',
      'N800',
      'N900',
    ]);
    this.blues = this.filterColors(colors, ['B100', 'B200', 'B300', 'B400', 'B500']);
    this.greens = this.filterColors(colors, ['G100', 'G200', 'G300', 'G400', 'G500']);
    this.teals = this.filterColors(colors, ['T100', 'T200', 'T300', 'T400', 'T500']);
    this.yellows = this.filterColors(colors, ['Y100', 'Y200', 'Y300', 'Y400', 'Y500']);
    this.oranges = this.filterColors(colors, ['O100', 'O200', 'O300', 'O400', 'O500']);
    this.reds = this.filterColors(colors, ['R100', 'R200', 'R300', 'R400', 'R500']);
    this.pinks = this.filterColors(colors, ['Pi100', 'Pi200', 'Pi300', 'Pi400', 'Pi500']);
    this.purples = this.filterColors(colors, ['P100', 'P200', 'P300', 'P400', 'P500']);
  }

  filterColors(colors: any, keys: string[]): any[] {
    return keys.map(key => ({
      name: key,
      hex: colors[key],
    }));
  }
}
