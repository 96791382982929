/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from '@angular/common/http';
import { StateSignal, patchState } from '@ngrx/signals';

import { MdwNotificationsService } from '@spaces-ui/meadow/components/notifications';

import { ArrayUtils } from './array-utils';

export interface LoadableEntitiesSignalState<T> {
  isLoading: boolean;
  entities: T[];
  entityMap: Record<string, T>;
  idKey: keyof T;
  hasError: boolean;
  error?: string;
}

export class LoadableEntitiesUtils {
  private static notificationService: MdwNotificationsService;

  public static registerNotificationService(notificationService: MdwNotificationsService) {
    LoadableEntitiesUtils.notificationService = notificationService;
  }

  public static createInitialState<T extends { id?: string }>(
    options: Partial<LoadableEntitiesSignalState<T>> = {},
  ): LoadableEntitiesSignalState<T> {
    const state: LoadableEntitiesSignalState<T> = {
      isLoading: false,
      entities: [],
      entityMap: {},
      idKey: 'id',
      hasError: false,
      ...options,
    };

    return state;
  }

  public static async setEntities<EntityType extends object, StateType extends object>(
    entities: EntityType[],
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
  ) {
    return await this.loadEntities(
      Promise.resolve(entities.map(e => ({ ...e }))),
      state,
      signalKey,
    );
  }

  public static async setEntity<EntityType, StateType extends object>(
    entity: EntityType,
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
  ) {
    return await this.loadEntity(Promise.resolve({ ...entity }), state, signalKey);
  }

  public static async deleteEntity<StateType extends object>(
    entityId: string,
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
  ) {
    return await this.removeEntity(Promise.resolve(), entityId, state, signalKey);
  }

  public static async deleteEntities<StateType extends object>(
    entityIds: string[],
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
  ) {
    return await this.removeEntities(Promise.resolve(), entityIds, state, signalKey);
  }

  /**
   * Load entities and replace the current state.
   * NOTE this removes existing entities from the list
   */
  public static async replaceEntities<EntityType extends object, StateType extends object>(
    apiReq: Promise<EntityType[]>,
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
    options?: {
      errorMessage?: string;
      successMessage?: string;
      transform?: (entities: EntityType[]) => EntityType[];
    },
  ) {
    return await this._loadEntities(
      apiReq,
      state,
      signalKey,
      options?.errorMessage,
      options?.successMessage,
      options?.transform,
      true,
    );
  }

  /**
   * Load entities and append them to the current state.
   * NOTE this does not remove existing entities from the list
   */
  public static async loadEntities<EntityType extends object, StateType extends object>(
    apiReq: Promise<EntityType[]>,
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
    options?: {
      errorMessage?: string;
      successMessage?: string;
      transform?: (entities: EntityType[]) => EntityType[];
    },
  ) {
    return await this._loadEntities(
      apiReq,
      state,
      signalKey,
      options?.errorMessage,
      options?.successMessage,
      options?.transform,
      false,
    );
  }

  public static clearEntities<EntityType, StateType extends object>(
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
  ) {
    const currentState = (state as any)[signalKey]() as LoadableEntitiesSignalState<EntityType>;

    currentState.entities = [];
    currentState.entityMap = {};

    patchState(state as any, { [signalKey]: { ...currentState } });
  }

  public static async loadEntity<EntityType, StateType extends object>(
    apiReq: Promise<EntityType>,
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
    options?: {
      errorMessage?: string;
      successMessage?: string;
    },
  ) {
    this.resetErrorState(state, signalKey);

    const results = await this.makeApiRequest(
      apiReq,
      state,
      signalKey,
      options?.errorMessage,
      options?.successMessage,
    );

    const currentState = (state as any)[signalKey]() as LoadableEntitiesSignalState<EntityType>;

    currentState.isLoading = false;
    const idKey = currentState.idKey;
    currentState.entities = [
      ...currentState.entities.filter(e => e[idKey] !== results[idKey]),
      results,
    ];
    currentState.entityMap = currentState.entities.reduce(
      (acc, entity) => ({ ...acc, [entity[idKey] as string]: entity }),
      {},
    );

    patchState(state as any, { [signalKey]: { ...currentState } });
  }

  public static async addAndRetrieveEntity<EntityType, StateType extends object>(
    postApiReq: Promise<string>,
    getApiReq: (id: string) => Promise<EntityType>,
    newEntity: Partial<EntityType>,
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
    options?: {
      errorMessage?: string;
      successMessage?: string;
    },
  ) {
    await this._addEntity(postApiReq, newEntity, state, signalKey, {
      ...(options ?? {}),
      getEntityApi: getApiReq,
    });
  }
  public static async addEntity<EntityType, StateType extends object>(
    apiReq: Promise<string>,
    newEntity: Partial<EntityType>,
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
    options?: {
      errorMessage?: string;
      successMessage?: string;
    },
  ) {
    await this._addEntity(apiReq, newEntity, state, signalKey, {
      ...(options ?? {}),
    });
  }

  public static async updateAndRetrieveEntity<EntityType, StateType extends object>(
    updateApiReq: Promise<void>,
    getApiReq: (id: string) => Promise<void>,
    entityToUpdate: Partial<EntityType>,
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
    options: {
      updateMethod: 'replace' | 'merge';
      errorMessage?: string;
      successMessage?: string;
    },
  ) {
    await this._updateEntity(updateApiReq, entityToUpdate, state, signalKey, {
      ...options,
      getEntityApi: getApiReq,
    });
  }

  public static async updateEntity<EntityType, StateType extends object>(
    apiReq: Promise<unknown>,
    entityToUpdate: Partial<EntityType>,
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
    options: {
      updateMethod: 'replace' | 'merge';
      errorMessage?: string;
      successMessage?: string;
    },
  ) {
    await this._updateEntity(apiReq, entityToUpdate, state, signalKey, {
      ...options,
    });
  }

  public static async removeEntities<EntityType, StateType extends object>(
    apiReq: Promise<unknown>,
    entityIds: string[],
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
    options?: {
      errorMessage?: string;
      successMessage?: string;
    },
  ) {
    this.resetErrorState(state, signalKey);

    await this.makeApiRequest(
      apiReq,
      state,
      signalKey,
      options?.errorMessage,
      options?.successMessage,
    );

    const currentState = (state as any)[signalKey]() as LoadableEntitiesSignalState<EntityType>;

    currentState.isLoading = false;
    const idKey = currentState.idKey;
    currentState.entities = [
      ...currentState.entities.filter(e => !entityIds.includes(e[idKey] as string)),
    ];

    currentState.entityMap = currentState.entities.reduce(
      (acc, entity) => ({ ...acc, [entity[idKey] as string]: entity }),
      {},
    );

    patchState(state as any, { [signalKey]: { ...currentState } });
  }

  public static async removeEntity<EntityType, StateType extends object>(
    apiReq: Promise<unknown>,
    entityId: string,
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
    options?: {
      errorMessage?: string;
      successMessage?: string;
    },
  ) {
    this.resetErrorState(state, signalKey);

    await this.makeApiRequest(
      apiReq,
      state,
      signalKey,
      options?.errorMessage,
      options?.successMessage,
    );

    const currentState = (state as any)[signalKey]() as LoadableEntitiesSignalState<EntityType>;

    currentState.isLoading = false;
    const idKey = currentState.idKey;
    currentState.entities = [...currentState.entities.filter(e => e[idKey] !== entityId)];

    currentState.entityMap = currentState.entities.reduce(
      (acc, entity) => ({ ...acc, [entity[idKey] as string]: entity }),
      {},
    );

    patchState(state as any, { [signalKey]: { ...currentState } });
  }

  private static async _loadEntities<EntityType extends object, StateType extends object>(
    apiReq: Promise<EntityType[]>,
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
    errorMessage?: string,
    successMessage?: string,
    transform?: (entities: EntityType[]) => EntityType[],
    replaceState = true,
  ) {
    this.resetErrorState(state, signalKey);

    const results = await this.makeApiRequest(
      apiReq,
      state,
      signalKey,
      errorMessage,
      successMessage,
    );

    const currentState = (state as any)[signalKey]() as LoadableEntitiesSignalState<EntityType>;

    let entities = replaceState
      ? [...results]
      : [
          ...currentState.entities.filter(
            e => !results.some(r => r[currentState.idKey] === e[currentState.idKey]),
          ),
          ...results,
        ];
    if (transform) {
      entities = transform(entities);
    }

    const uniqueEntities = ArrayUtils.uniqueBy<EntityType>(entities, currentState.idKey);

    currentState.isLoading = false;
    currentState.entities = uniqueEntities;
    currentState.entityMap = uniqueEntities.reduce(
      (acc, entity) => ({ ...acc, [entity[currentState.idKey] as string]: entity }),
      {},
    );

    patchState(state as any, { [signalKey]: { ...currentState } });
  }

  private static async _addEntity<EntityType, StateType extends object>(
    apiReq: Promise<string>,
    newEntity: Partial<EntityType>,
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
    options?: {
      errorMessage?: string;
      successMessage?: string;
      getEntityApi?: (id: string) => Promise<EntityType>;
    },
  ) {
    this.resetErrorState(state, signalKey);

    const newId = await this.makeApiRequest(
      apiReq,
      state,
      signalKey,
      options?.errorMessage,
      options?.successMessage,
    );

    if (options?.getEntityApi) {
      await this.loadEntity(options.getEntityApi(newId), state, signalKey, options);
    } else {
      const currentState = (state as any)[signalKey]() as LoadableEntitiesSignalState<EntityType>;

      currentState.isLoading = false;
      const idKey = currentState.idKey;
      currentState.entities = [
        ...currentState.entities.filter(e => e[idKey] !== newId),
        { ...newEntity, [idKey]: newId } as EntityType,
      ];
      currentState.entityMap = currentState.entities.reduce(
        (acc, entity) => ({ ...acc, [entity[idKey] as string]: entity }),
        {},
      );

      patchState(state as any, { [signalKey]: { ...currentState } });
    }
  }

  private static async _updateEntity<EntityType, StateType extends object>(
    apiReq: Promise<unknown>,
    entityToUpdate: Partial<EntityType>,
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
    options: {
      updateMethod: 'replace' | 'merge';
      errorMessage?: string;
      successMessage?: string;
      getEntityApi?: (id: string) => Promise<void>;
    },
  ) {
    this.resetErrorState(state, signalKey);

    await this.makeApiRequest(
      apiReq,
      state,
      signalKey,
      options.errorMessage,
      options.successMessage,
    );

    const currentState = (state as any)[signalKey]() as LoadableEntitiesSignalState<EntityType>;
    const idKey = currentState.idKey;

    if (options?.getEntityApi) {
      await this.loadEntity(
        options.getEntityApi(entityToUpdate[idKey] as string),
        state,
        signalKey,
        options,
      );
    } else {
      currentState.isLoading = false;
      const currentEntity = currentState.entityMap[entityToUpdate[idKey] as string];

      if (options.updateMethod === 'replace') {
        currentState.entities = [
          ...currentState.entities.filter(e => e[idKey] !== entityToUpdate[idKey]),
          { ...entityToUpdate } as EntityType,
        ];
      } else {
        currentState.entities = [
          ...currentState.entities.filter(e => e[idKey] !== entityToUpdate[idKey]),
          { ...currentEntity, ...entityToUpdate } as EntityType,
        ];
      }

      currentState.entityMap = currentState.entities.reduce(
        (acc, entity) => ({ ...acc, [entity[idKey] as string]: entity }),
        {},
      );

      patchState(state as any, { [signalKey]: { ...currentState } });
    }
  }

  private static resetErrorState<EntityType, StateType extends object>(
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
  ) {
    const currentState = (state as any)[signalKey]() as LoadableEntitiesSignalState<EntityType>;
    currentState.isLoading = true;
    currentState.hasError = false;
    currentState.error = undefined;

    patchState(state as any, { [signalKey]: { ...currentState } });
  }

  private static async makeApiRequest<APIResponseType, EntityType, StateType extends object>(
    apiReq: Promise<APIResponseType>,
    state: StateSignal<StateType>,
    signalKey: keyof StateType,
    errorMessage?: string,
    successMessage?: string,
  ) {
    const currentState = (state as any)[signalKey]() as LoadableEntitiesSignalState<EntityType>;

    const results = await apiReq.catch((error: HttpErrorResponse) => {
      currentState.isLoading = false;
      currentState.hasError = true;
      currentState.error = error.message;

      patchState(state as any, { [signalKey]: { ...currentState } });

      if (LoadableEntitiesUtils.notificationService && errorMessage) {
        LoadableEntitiesUtils.notificationService.showError(errorMessage, error.error);
      }

      throw error;
    });

    if (LoadableEntitiesUtils.notificationService && successMessage) {
      LoadableEntitiesUtils.notificationService.showSuccess(successMessage);
    }

    return results;
  }
}
