<div class="vflex-300">
  <h2>Buttons</h2>
  <div class="surface-muted pad-200">
    <code>
      import &#123; ButtonComponent &#125; from '&#64;spaces-ui/meadow/components/button';
    </code>
  </div>
  <h3>Default</h3>
  <section class="vflex-200 flex-no-stretch">
    <p>
      Default buttons are buttons without any additional classes. They are the most basic type of
      mdw-button.
    </p>
    <p>Default buttons should match Medium Secondary buttons.</p>
    <button class="mdw-button">Default</button>

    <button class="mdw-button" [icon]="icons.Home_Fill">Default</button>
    <button class="mdw-button" [icon]="icons.Home_Fill"></button>
  </section>

  <h3>Small</h3>
  <ng-template #buttonSmall data-sample="buttonSmall">
    <section class="vflex-300 flex-no-stretch">
      <button class="mdw-button mdw-primary small-button">Primary</button>
      <button class="mdw-button mdw-secondary small-button">Secondary</button>
      <button class="mdw-button mdw-tertiary small-button">Tertiary</button>
      <button class="mdw-button mdw-danger small-button">Danger!</button>
      <button class="mdw-button mdw-muted small-button">Muted</button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="buttonSmall" sampleName="buttonSmall"></app-sample>
  <h3>Medium</h3>
  <ng-template #buttonMedium data-sample="buttonMedium">
    <section class="vflex-200 flex-no-stretch">
      <button class="mdw-button mdw-primary medium-button">Primary</button>
      <button class="mdw-button mdw-secondary medium-button">Secondary</button>
      <button class="mdw-button mdw-tertiary medium-button">Tertiary</button>
      <button class="mdw-button mdw-danger medium-button">Danger!</button>
      <button class="mdw-button mdw-muted medium-button">Muted</button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="buttonMedium" sampleName="buttonMedium"></app-sample>
  <h3>Large</h3>
  <ng-template #buttonLarge data-sample="buttonLarge">
    <section class="vflex-200 flex-no-stretch">
      <button class="mdw-button mdw-primary large-button">Primary</button>
      <button class="mdw-button mdw-secondary large-button">Secondary</button>
      <button class="mdw-button mdw-tertiary large-button">Tertiary</button>
      <button class="mdw-button mdw-danger large-button">Danger!</button>
      <button class="mdw-button mdw-muted large-button">Muted</button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="buttonLarge" sampleName="buttonLarge"></app-sample>
  <h3>X-Large</h3>
  <ng-template #buttonXLarge data-sample="buttonXLarge">
    <section class="vflex-300 flex-no-stretch">
      <button class="mdw-button mdw-primary x-large-button" [icon]="icons.Home_Fill">
        Primary
      </button>
      <button class="mdw-button mdw-secondary x-large-button" [icon]="icons.Home_Fill">
        Secondary
      </button>
      <button class="mdw-button mdw-tertiary x-large-button" [icon]="icons.Home_Fill">
        Tertiary
      </button>
      <button class="mdw-button mdw-danger x-large-button" [icon]="icons.Home_Fill">Danger!</button>
      <button class="mdw-button mdw-muted x-large-button" [icon]="icons.Home_Fill">Muted</button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="buttonXLarge" sampleName="buttonXLarge"></app-sample>

  <h2>Buttons with Icons</h2>
  <h3>Small</h3>
  <ng-template #buttonWithIconSmall data-sample="buttonWithIconSmall">
    <section class="vflex-300 flex-no-stretch">
      <button class="mdw-button mdw-primary small-button" [icon]="icons.Home_Fill">Primary</button>
      <button class="mdw-button mdw-secondary small-button" [icon]="icons.Home_Fill">
        Secondary
      </button>
      <button class="mdw-button mdw-tertiary small-button" [icon]="icons.Home_Fill">
        Tertiary
      </button>
      <button class="mdw-button mdw-danger small-button" [icon]="icons.Home_Fill">Danger!</button>
      <button class="mdw-button mdw-muted small-button" [icon]="icons.Home_Fill">Muted</button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="buttonWithIconSmall" sampleName="buttonWithIconSmall"></app-sample>

  <h3>Medium</h3>
  <ng-template #buttonWithIconMedium data-sample="buttonWithIconMedium">
    <section class="vflex-300 flex-no-stretch">
      <button class="mdw-button mdw-primary medium-button" [icon]="icons.Home_Fill">Primary</button>
      <button class="mdw-button mdw-secondary medium-button" [icon]="icons.Home_Fill">
        Secondary
      </button>
      <button class="mdw-button mdw-tertiary medium-button" [icon]="icons.Home_Fill">
        Tertiary
      </button>
      <button class="mdw-button mdw-danger medium-button" [icon]="icons.Home_Fill">Danger!</button>
      <button class="mdw-button mdw-muted medium-button" [icon]="icons.Home_Fill">Muted</button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="buttonWithIconMedium" sampleName="buttonWithIconMedium"></app-sample>

  <h3>Large</h3>
  <ng-template #buttonWithIconLarge data-sample="buttonWithIconLarge">
    <section class="vflex-300 flex-no-stretch">
      <button class="mdw-button mdw-primary large-button" [icon]="icons.Home_Fill">Primary</button>
      <button class="mdw-button mdw-secondary large-button" [icon]="icons.Home_Fill">
        Secondary
      </button>
      <button class="mdw-button mdw-tertiary large-button" [icon]="icons.Home_Fill">
        Tertiary
      </button>
      <button class="mdw-button mdw-danger large-button" [icon]="icons.Home_Fill">Danger!</button>
      <button class="mdw-button mdw-muted large-button" [icon]="icons.Home_Fill">Muted</button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="buttonWithIconLarge" sampleName="buttonWithIconLarge"></app-sample>

  <h3>X-Large</h3>
  <ng-template #buttonWithIconXLarge data-sample="buttonWithIconXLarge">
    <section class="vflex-300 flex-no-stretch">
      <button class="mdw-button mdw-primary x-large-button" [icon]="icons.Home_Fill">
        Primary
      </button>
      <button class="mdw-button mdw-secondary x-large-button" [icon]="icons.Home_Fill">
        Secondary
      </button>
      <button class="mdw-button mdw-tertiary x-large-button" [icon]="icons.Home_Fill">
        Tertiary
      </button>
      <button class="mdw-button mdw-danger x-large-button" [icon]="icons.Home_Fill">Danger!</button>
      <button class="mdw-button mdw-muted x-large-button" [icon]="icons.Home_Fill">Muted</button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="buttonWithIconXLarge" sampleName="buttonWithIconXLarge"></app-sample>

  <h2>Icon Buttons</h2>
  <ng-template #iconButton data-sample="iconButton">
    <section class="vflex-300 flex-no-stretch">
      <button class="mdw-button mdw-primary small-button" [icon]="icons.Apple_Fill"></button>
      <button class="mdw-button mdw-secondary medium-button" [icon]="icons.Apple_Fill"></button>
      <button class="mdw-button mdw-primary large-button" [icon]="icons.Apple_Fill"></button>
      <button class="mdw-button mdw-danger x-large-button" [icon]="icons.Apple_Fill"></button>
      <button class="mdw-button mdw-tertiary color-preset-17" [icon]="icons.Apple_Fill"></button>
      <button class="mdw-button mdw-muted large-button" [icon]="icons.Apple_Fill"></button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="iconButton" sampleName="iconButton"></app-sample>

  <h2>Button with Dynamic Text</h2>
  <p>
    This is just a test scenario to make sure the button component properly displays dynamic text.
  </p>
  <section class="vflex-200 flex-no-stretch">
    <button class="mdw-button">
      {{ 'Dynamic Text' }}
    </button>
  </section>

  <h2>Dropdown/Menu/Flyout trigger buttons</h2>
  <p>
    The button component has an optional
    <code>showDownArrow</code>
    property to be used when the button acts as a trigger for a menu/flyout/dropdown, and the
    designer wants to show affordance to this.
  </p>
  <ng-template #dropDownButton data-sample="dropDownButton">
    <section class="vflex-300 flex-no-stretch">
      <button class="mdw-button mdw-primary" [showDownArrow]="true">Select space</button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="dropDownButton" sampleName="dropDownButton"></app-sample>

  <h2>Submit buttons</h2>
  <p>
    By default, the mdw-button component is a type="button". If you need a submit button, you can
    set the type attribute to `submit`.
  </p>
  <ng-template #submitButton data-sample="submitButton">
    <section class="vflex-300 flex-no-stretch">
      <button class="mdw-button mdw-primary" type="submit">Submit</button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="submitButton" sampleName="submitButton"></app-sample>

  <h2>Button Groups</h2>
  <p>
    Button groups are used to group buttons together. Each button size has its own spacing
    defintion.
  </p>
  <ng-template #buttonGroups data-sample="buttonGroups">
    <div class="vflex-200">
      <div class="button-group">
        <button class="mdw-button mdw-secondary">Secondary</button>
        <button class="mdw-button mdw-primary">Primary</button>
      </div>
      <div class="button-group-small">
        <button class="mdw-button mdw-secondary small-button">Secondary</button>
        <button class="mdw-button mdw-primary small-button">Primary</button>
      </div>
      <div class="button-group-medium">
        <button class="mdw-button mdw-secondary medium-button">Secondary</button>
        <button class="mdw-button mdw-primary medium-button">Primary</button>
      </div>
      <div class="button-group-large">
        <button class="mdw-button mdw-secondary large-button">Secondary</button>
        <button class="mdw-button mdw-primary large-button">Primary</button>
      </div>
      <div class="button-group-x-large">
        <button class="mdw-button mdw-secondary x-large-button">Secondary</button>
        <button class="mdw-button mdw-primary x-large-button">Primary</button>
      </div>
    </div>
  </ng-template>
  <app-sample [sampleContent]="buttonGroups" sampleName="buttonGroups"></app-sample>
</div>
