import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { TabGroupComponent } from '@spaces-ui/meadow/components/tab-group';

import { SampleComponent } from '../../components';

@Component({
  selector: 'app-tabs',
  standalone: true,
  imports: [CommonModule, SampleComponent, TabGroupComponent],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss',
})
export class TabsComponent {
  public selectedTab = 'tab1';
}
