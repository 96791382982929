import { Injectable } from '@angular/core';
import { EmojiSearch } from '@ctrl/ngx-emoji-mart';

import { MdwEmoji } from '../models';

@Injectable({ providedIn: 'root' })
export class MdwEmojiSearchService {
  public constructor(private emojiSearch: EmojiSearch) {}

  public search(query: string) {
    return this.emojiSearch.search(query) as MdwEmoji[];
  }

  public getEmojiByName(colonsName: string) {
    const emojiList = Object.values(this.emojiSearch.emojisList) as MdwEmoji[];

    return emojiList.find(emoji => emoji.colons === colonsName);
  }

  public getEmojiSkinTone() {
    const skinTone = localStorage.getItem('emoji-mart.skin') ?? '1';

    return skinTone === '1' ? '' : `:skin-tone-${skinTone}:`;
  }
}
