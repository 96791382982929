<div class="vflex-300">
  <h2>Forms</h2>
  <div class="surface-muted pad-200">This page is a work in progress.</div>
  <h3>Select</h3>
  <b>Inline Single Select</b>
  <div class="surface-muted pad-200">
    <code>import &#123; CdkListbox, CdkOption &#125; from '&#64;angular/cdk/listbox';</code>
  </div>
  <ul
    cdkListbox
    [(ngModel)]="order"
    aria-labelledby="example-toppings-label"
    class="example-listbox"
  >
    <li [cdkOption]="1">Item 1</li>
    <li [cdkOption]="2">Item 2</li>
    <li [cdkOption]="3">Item 3</li>
    <li [cdkOption]="4">Item 4</li>
    <li [cdkOption]="5">Item 5</li>
  </ul>
  <h3>Dropdown Multi Select</h3>
  <div>
    <button class="mdw-button mdw-primary mdw-select-trigger" [cdkMenuTriggerFor]="menu">
      {{ selectedItem || 'Please select an item' }}
    </button>
  </div>
  <ng-template #menu>
    <div class="surface-floating pad-100 flyout-extra-wide" cdkMenu>
      <ul cdkListbox [(ngModel)]="selectedItem">
        <li cdkOption="Item 1">Item 1</li>
        <li cdkOption="Item 2">Item 2</li>
        <li cdkOption="Item 3">Item 3</li>
        <li cdkOption="Item 4">Item 4</li>
        <li cdkOption="Item 5">Item 5</li>
      </ul>
    </div>
  </ng-template>
  selected item = {{ selectedItem }}
</div>
