<div class="vflex-300 content-container">
  <h2>Menus</h2>
  <div class="surface-muted pad-200">
    <code>
      import &#123; CdkMenu, CdkMenuItem, CdkMenuTrigger &#125; from '&#64;angular/cdk/menu';
    </code>
  </div>
  <p>
    Menus come from the
    <a href="https://material.angular.io/cdk/menu/overview">CDK Menu</a>
    . This gives us built-in ARIA support as well as keyboard interaction, and of course most of the
    implementation has already been developed and tested.
  </p>
  <p>This page shows a couple of examples of how to use the CDK Menu in Meadow.</p>
  <p>
    The list item styling comes from the meadow listitem component, so no additional code is
    required to make it look and behave as expected.
  </p>

  <ng-template #inlineMenu data-sample="inlineMenu">
    <div class="surface-flat-outline pad-100" cdkMenu>
      <menu>
        <ul>
          <li cdkMenuItem (cdkMenuItemTriggered)="alert('Item 1 clicked')" [icon]="icons.Logout">
            Logout
          </li>
          <li
            cdkMenuItem
            (cdkMenuItemTriggered)="alert('Item 2 clicked')"
            [icon]="icons.Mark"
            [cdkMenuItemDisabled]="true"
          >
            Create Space
          </li>
          <li cdkMenuItem (cdkMenuItemTriggered)="alert('Item 3 clicked')" [icon]="icons.Channel">
            Create Channel
          </li>
        </ul>
      </menu>
    </div>
  </ng-template>
  <app-sample [sampleContent]="inlineMenu" sampleName="inlineMenu"></app-sample>

  <h3>Flyout Menu</h3>
  <p>
    Both menu and nav list items can have a sub-header and a divider. The sub-header is a label that
    is styled differently from the list items. The divider is a horizontal line that separates
    groups of items.
  </p>
  <ng-template #flyoutMenu data-sample="flyoutMenu">
    <div>
      <button [cdkMenuTriggerFor]="menu" class="mdw-button mdw-primary">Trigger Flyout!</button>
    </div>
    <ng-template #menu>
      <div class="surface-floating pad-100 flyout-extra-wide" cdkMenu>
        <menu>
          <label>Some Menu Items</label>
          <ul>
            <li cdkMenuItem (cdkMenuItemTriggered)="alert('Item 1 clicked')" [icon]="icons.Logout">
              Logout
            </li>
            <li cdkMenuItem (cdkMenuItemTriggered)="alert('Item 2 clicked')" [icon]="icons.Mark">
              Create Space
            </li>
            <hr />
            <li cdkMenuItem (cdkMenuItemTriggered)="alert('Item 3 clicked')" [icon]="icons.Channel">
              Create Channel
            </li>
          </ul>
        </menu>
      </div>
    </ng-template>
  </ng-template>
  <app-sample [sampleContent]="flyoutMenu" sampleName="flyoutMenu"></app-sample>

  <h3>Spaces Sidebar Menu POC</h3>
  <ng-template #sidebarPOC data-sample="sidebarPOC">
    <div class="centered">
      <div class="surface-raised pad-200 hflex-200 inline-flex">
        <button
          class="mdw-button mdw-tertiary"
          [icon]="icons.DirectMessage_Fill"
          badge="9"
          color="B200"
        ></button>
        <button
          class="mdw-button mdw-tertiary"
          [icon]="icons.Home_Fill"
          badge="2"
          color="P400"
        ></button>
        <button
          [cdkMenuTriggerFor]="spacesMenu"
          class="mdw-button mdw-tertiary"
          [icon]="icons.Overflow"
          [cdkMenuPosition]="positions"
        ></button>
      </div>
    </div>
    <ng-template #spacesMenu []>
      <div class="surface-floating pad-100 flyout-extra-wide vflex-300" cdkMenu>
        <div class="hflex-auto">
          <div class="vflex-100 centered">
            <button
              class="mdw-button x-large-button bg-color-G200"
              [icon]="icons.ChannelNew"
              color="G500"
            ></button>
            <small>Channel</small>
          </div>
          <div class="vflex-100 centered">
            <button
              class="mdw-button x-large-button bg-color-B100"
              color="B300"
              [icon]="icons.DirectMessageNew_Fill"
            ></button>
            <small>Message</small>
          </div>
          <div class="vflex-100 centered">
            <button
              class="mdw-button x-large-button bg-color-P100"
              [icon]="icons.SectionNew_Fill"
              color="P400"
            ></button>
            <small>Section</small>
          </div>
          <div class="vflex-100 centered">
            <button
              class="mdw-button x-large-button bg-color-N100"
              color="N400"
              [icon]="icons.ChannelBrowse"
            ></button>
            <small>Browse</small>
          </div>
        </div>
        <menu>
          <ul>
            <li
              cdkMenuItem
              (cdkMenuItemTriggered)="alert('Item 2 clicked')"
              [icon]="icons.DirectMessage_Fill"
              badge="9"
              iconColor="B200"
            >
              Direct Messages
            </li>
            <li
              cdkMenuItem
              (cdkMenuItemTriggered)="alert('Item 3 clicked')"
              [icon]="icons.Home_Fill"
              iconColor="P400"
              badge="2"
            >
              Home
            </li>
          </ul>
        </menu>
      </div>
    </ng-template>
  </ng-template>
  <app-sample [sampleContent]="sidebarPOC" sampleName="sidebarPOC"></app-sample>

  <h3>Menu Placement - Connected Position</h3>
  <div class="hflex-200 flex-align-items-end">
    <mdw-select
      label="Origin X"
      description="This is used to designate the X position of the trigger that connects to the flyout"
      [options]="['start', 'center', 'end']"
      [ngModel]="originX()"
      (ngModelChange)="originX.set($event)"
    ></mdw-select>
    <mdw-select
      label="Origin Y"
      description="This is used to designate the Y position of the trigger that connects to the flyout"
      [options]="['top', 'center', 'bottom']"
      [ngModel]="originY()"
      (ngModelChange)="originY.set($event)"
    ></mdw-select>
    <mdw-select
      label="Overlay X"
      description="This is used to designate the X position of the flyout that connects to the trigger"
      [options]="['start', 'center', 'end']"
      [ngModel]="overlayX()"
      (ngModelChange)="overlayX.set($event)"
    ></mdw-select>
    <mdw-select
      label="Overlay Y"
      description="This is used to designate the Y position of the flyout that connects to the trigger"
      [options]="['top', 'center', 'bottom']"
      [ngModel]="overlayY()"
      (ngModelChange)="overlayY.set($event)"
    ></mdw-select>
    <div>
      <input
        label="Offset X"
        description="This is used to adjust the X position of the flyout that connects to the trigger"
        type="text"
        placeholder="offset X"
        [ngModel]="offsetX()"
        (ngModelChange)="offsetX.set($event)"
      />
    </div>
    <div>
      <input
        label="Offset Y"
        description="This is used to adjust the Y position of the flyout that connects to the trigger"
        type="text"
        placeholder="offset Y"
        [ngModel]="offsetY()"
        (ngModelChange)="offsetY.set($event)"
      />
    </div>
  </div>

  @if (showPlacementTrigger()) {
    <div>
      <button
        [cdkMenuTriggerFor]="positioningMenu"
        [cdkMenuPosition]="[connectedPosition()]"
        class="mdw-button mdw-primary"
      >
        Flyout Trigger
      </button>
    </div>
    <ng-template #positioningMenu>
      <div class="surface-floating pad-100 flyout-extra-wide" cdkMenu>
        <menu>
          <ul>
            <li cdkMenuItem>Item 1</li>
            <li cdkMenuItem>Item 2</li>
            <hr />
            <li cdkMenuItem>Item 3</li>
          </ul>
        </menu>
      </div>
    </ng-template>

    <p class="bold">Trigger Bindings:</p>
    <div class="surface-muted pad-200">
      <code>
        {{ bindingsString() }}
      </code>
    </div>
  }
</div>
