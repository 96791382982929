import { InjectionToken } from '@angular/core';

export interface Environment {
  oAuthClientId: string;
  ldClientId: string;
  APP_INSIGHT_KEY: string;
  uri: {
    webSocket: string;
    auth: string;
  };
  isProduction: boolean;
}

export const ENVIRONMENT = new InjectionToken<Environment>('environment');
