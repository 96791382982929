import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnInit, input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { IconComponent } from '@spaces-ui/meadow/icons';
import { Check_Small } from '@spaces-ui/meadow/icons/svgs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nav ul li, menu ul li, ul[cdkListbox] li',
  standalone: true,
  imports: [CommonModule, IconComponent, RouterLink, RouterLinkActive],
  templateUrl: './list-item.component.html',
  styleUrl: './list-item.component.scss',
})
export class ListItemComponent implements OnInit {
  public icon = input<string>('');
  public badge = input<string>();
  public iconColor = input<string>();
  public iconPresetColorNumber = input<number>(); // 1 - 24
  public routerLink = input<string | string[]>('');
  public routerLinkActive = input<string>('');
  public href = input<string>('');
  public disabled = input<boolean>(false);
  public cdkMenuItemDisabled = input<boolean>(false);
  public useCheckbox = input(false);
  public iconSize = input<'medium' | 'small'>('medium');
  public includeToggle = input(false);
  public toggleChecked = input(false);

  public iconColorClass = '';

  public icons = { Check_Small };

  @HostBinding('class.has-icon')
  public get hasIcon(): boolean {
    return !!this.icon();
  }

  @HostBinding('class.accented')
  public get hasBadge(): boolean {
    return !!this.badge();
  }

  @HostBinding('class.disabled')
  public get isDisabled(): boolean {
    return this.disabled() || this.cdkMenuItemDisabled();
  }

  public ngOnInit(): void {
    if (this.iconColor()) {
      this.iconColorClass = `color-${this.iconColor()}`;
    }
  }
}
