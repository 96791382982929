import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';

import { ButtonComponent } from '@spaces-ui/meadow/components/button';

@Component({
  selector: 'mdw-dialog-footer',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class DialogFooterComponent {
  public primaryButton = input();
  public secondaryButton = input();
  public tertiaryButton = input();
  public dangerButton = input();
  public primaryButtonDisabled = input(false);
  public secondaryButtonDisabled = input(false);
  public tertiaryButtonDisabled = input(false);
  public dangerButtonDisabled = input(false);
  public showSecondaryButton = input(false);

  public primaryButtonClicked = output();
  public secondaryButtonClicked = output();
  public tertiaryButtonClicked = output();
  public dangerButtonClicked = output();
}
