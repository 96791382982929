import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  OnInit,
  computed,
  effect,
  inject,
  input,
  signal,
  viewChild,
} from '@angular/core';

import { fadeInAnimation } from '@spaces-ui/animations/angular';
import { IconComponent } from '@spaces-ui/meadow/icons';
import { DropdownArrow } from '@spaces-ui/meadow/icons/svgs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button.mdw-button , a.mdw-button',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  animations: [fadeInAnimation()],
})
export class ButtonComponent implements OnInit {
  public element: ElementRef<HTMLButtonElement> = inject(ElementRef<HTMLButtonElement>);
  public textContent = viewChild<ElementRef<HTMLSpanElement>>('textContent');

  public type = input<'button' | 'submit' | 'reset'>('button');
  public badge = input<string | number>();
  public passiveBadge = input<boolean>();
  public icon = input<string>();
  public color = input<string>();
  public iconPresetColorNumber = input<number>();
  public contentIsIcon = input(false);
  public iconCalloutColor = input<string>();
  public iconCalloutSize = input<'medium' | 'large' | 'x-large'>();

  public showDownArrow = input<boolean>(false);
  public iconSize = signal<string>('medium');

  public colorClass = signal<string>('');

  public icons = { DropdownArrow };

  private _hasText = false;

  @HostBinding('class.has-icon')
  public get hasIcon(): boolean {
    return !!this.icon();
  }

  @HostBinding('class.content-is-icon')
  public get contentIsIconClass(): boolean {
    return this.contentIsIcon();
  }

  @HostBinding('class.has-text')
  public get hasText(): boolean {
    return this._hasText;
  }

  public classes = computed(() => {
    const iconSize = this.iconSize();
    const colorClass = this.colorClass();
    const iconPresetColorNumber = this.iconPresetColorNumber();
    const iconCalloutColor = this.iconCalloutColor();
    const iconCalloutSize = this.iconCalloutSize();

    const classes = {
      [`${iconSize}-icon`]: true,
      [colorClass]: true,
      [`color-preset-${iconPresetColorNumber}`]: iconPresetColorNumber !== undefined,
      [`icon-callout-${iconCalloutColor ?? 'neutral'}`]: iconCalloutColor !== undefined,
      [`${iconCalloutSize ?? 'medium'}-callout`]: iconCalloutSize !== undefined,
      'icon-callout': iconCalloutColor !== undefined,
    };

    return classes;
  });

  public constructor() {
    // Need to check if the button has text content, but the text content is not available
    // until after the view has been initialized
    effect(() => {
      const textContentRef = this.textContent();
      if (!textContentRef) {
        return;
      }

      const textContent = textContentRef.nativeElement.textContent ?? '';
      if (textContent.trim().length > 0) {
        this._hasText = true;
      }
    });
  }

  public ngOnInit(): void {
    if (this.button.classList.contains('small-button')) {
      this.iconSize.set('small');
    } else if (
      this.button.classList.contains('large-button') ||
      this.button.classList.contains('x-large-button')
    ) {
      this.iconSize.set('large');
    }

    if (this.color()) {
      this.colorClass.set(`color-${this.color()}`);
    }

    this.button.type = this.type();
  }

  public button: HTMLButtonElement = this.element.nativeElement;
}
