import { CommonModule } from '@angular/common';
import { Component, TemplateRef, computed, input, output } from '@angular/core';

import { ButtonComponent } from '@spaces-ui/meadow/components/button';
import { IconComponent } from '@spaces-ui/meadow/icons';
import { Close } from '@spaces-ui/meadow/icons/svgs';

@Component({
  selector: 'mdw-dialog-header',
  standalone: true,
  imports: [CommonModule, ButtonComponent, IconComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class DialogHeaderComponent {
  public mainTitle = input<string>();
  public subtitle = input<string>();
  public showCloseButton = input<boolean>(false);
  public icon = input<string | TemplateRef<HTMLElement>>();
  public rightConent = input<TemplateRef<HTMLElement>>();
  public closed = output<void>();

  public iconIsTemplate = computed(() => {
    const icon = this.icon();
    return icon instanceof TemplateRef;
  });

  public iconTemplate = computed(() => {
    const icon = this.icon();
    return icon instanceof TemplateRef ? icon : undefined;
  });

  public iconSvg = computed(() => {
    const icon = this.icon();
    return icon instanceof TemplateRef ? undefined : icon;
  });

  public icons = { Close };
}
