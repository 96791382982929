<div class="vflex-000 fill-viewport">
  <app-top-sidebar (toggleTheme)="toggleTheme()"></app-top-sidebar>
  <div class="hflex-200 fill-flex pad-200 overflow-hidden relative">
    <aside
      [(isCollapsed)]="sidebarIsCollapsed"
      [(isFloating)]="sidebarIsFloating"
      class="overflow-hidden"
    >
      <app-secondary-sidebar (collapse)="sidebarIsCollapsed = true"></app-secondary-sidebar>
    </aside>
    @if (sidebarIsCollapsed || sidebarIsFloating) {
      <div class="vpad-200">
        <button
          class="mdw-button mdw-tertiary mdw-aside-trigger"
          (click)="toggleSidebar()"
          [icon]="icons.Sidebar_Duotone"
        ></button>
      </div>
    }
    <div class="fill-flex pad-400 surface-flat vflex-200 scrollable">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
