import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SampleComponent } from '../../components';

@Component({
  selector: 'app-tooltips',
  standalone: true,
  imports: [CommonModule, SampleComponent, MatTooltipModule],
  templateUrl: './tooltips.component.html',
  styleUrl: './tooltips.component.scss',
})
export class TooltipsComponent {}
