import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ColorPaletteService {
  private scssFilePath = 'assets/styles/core/_colors.scss';

  constructor(private http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getColorVariables(): Promise<any> {
    return firstValueFrom(
      this.http
        .get(this.scssFilePath, { responseType: 'text' })
        .pipe(map(scssContent => this.parseScssVariables(scssContent))),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseScssVariables(scssContent: string): any {
    const regex = /\$([\w-]+):\s*(#[0-9a-fA-F]{6});/g;
    const colors: { [key: string]: string } = {};
    let match;

    while ((match = regex.exec(scssContent)) !== null) {
      colors[match[1]] = match[2];
    }

    return colors;
  }
}
