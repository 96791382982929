import { DOCUMENT } from '@angular/common';
import { Injectable, effect, inject, signal } from '@angular/core';

import { BrowserStorageService } from '../browser-storage/browser-storage.service';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private document = inject(DOCUMENT);

  public theme = signal('sunshine');

  public constructor() {
    this.initializeTheme();

    effect(() => {
      const theme = this.theme();
      BrowserStorageService.setLocalStorageItem('spaces-ui-theme', theme);
    });
  }

  public toggleTheme() {
    const body = this.document.body;
    if (body.classList.contains('sunshine')) {
      body.classList.remove('sunshine');
      body.classList.add('moonshine');
      this.theme.set('moonshine');
    } else {
      body.classList.remove('moonshine');
      body.classList.add('sunshine');
      this.theme.set('sunshine');
    }
  }

  private initializeTheme() {
    const currentTheme = BrowserStorageService.getLocalStorageItem('spaces-ui-theme');
    if (currentTheme) {
      this.theme.set(currentTheme);
      this.document.body.classList.add(currentTheme);
    } else {
      this.theme.set('sunshine');
      this.document.body.classList.add('sunshine');
    }
  }
}
