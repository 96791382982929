<div class="vflex-300">
  <h2>Chips</h2>
  <div class="surface-muted pad-200">
    <code>import &#123; ChipComponent &#125; from '&#64;spaces-ui/meadow/components/chip';</code>
  </div>

  <h3>Chips with only text content</h3>
  <ng-template #plainChips data-sample="plainChips">
    <div class="vflex-200">
      <mdw-chip>User 1</mdw-chip>
      <mdw-chip>User 2</mdw-chip>
      <mdw-chip>User 3</mdw-chip>
    </div>
  </ng-template>
  <app-sample [sampleContent]="plainChips" sampleName="plainChips"></app-sample>

  <hr />

  <h3>Chips with leading icon</h3>
  <ng-template #iconChips data-sample="iconChips">
    <div class="vflex-200">
      <mdw-chip>
        <mdw-icon class="color-preset-7" [icon]="icons.Apple_Fill"></mdw-icon>
        User 1
      </mdw-chip>
      <mdw-chip>
        <mdw-icon class="color-preset-3" [icon]="icons.Apple_Fill"></mdw-icon>
        User 2
      </mdw-chip>
      <mdw-chip>
        <mdw-icon class="color-preset-2" [icon]="icons.Apple_Fill"></mdw-icon>
        User 3
      </mdw-chip>
    </div>
  </ng-template>
  <app-sample [sampleContent]="iconChips" sampleName="iconChips"></app-sample>

  <hr />

  <h3>Chips with leading avatar</h3>
  <ng-template #avatarChips data-sample="avatarChips">
    <div class="vflex-200">
      <mdw-chip>
        <mdw-avatar name="Steve" [color]="1" [src]="undefined" [srcVersion]="1"></mdw-avatar>
        Steve
      </mdw-chip>
      <mdw-chip>
        <mdw-avatar name="Robbie" [color]="2" [src]="undefined" [srcVersion]="1"></mdw-avatar>
        Robbie
      </mdw-chip>
      <mdw-chip>
        <mdw-avatar name="John" [color]="3" [src]="undefined" [srcVersion]="1"></mdw-avatar>
        John
      </mdw-chip>
    </div>
  </ng-template>
  <app-sample [sampleContent]="avatarChips" sampleName="avatarChips"></app-sample>
</div>
