import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';

import { ButtonComponent } from '@spaces-ui/meadow/components/button';
import { DialogService } from '@spaces-ui/meadow/components/dialog';

import { BAMDialogComponent, SimpleFormDialogComponent } from './sample-dialogs';
import { SampleComponent } from '../../components';

@Component({
  selector: 'app-dialogs',
  standalone: true,
  imports: [CommonModule, SampleComponent, ButtonComponent],
  templateUrl: './dialogs.component.html',
  styleUrl: './dialogs.component.scss',
})
export class DialogsComponent {
  //#region sample:alertDialog
  public dialogService = inject(DialogService);
  public triggerAlert(useDanger = false) {
    if (useDanger) {
      this.dialogService.alertDanger('Hello, World!', 'Hello Scary World', 'Done!');
    } else {
      this.dialogService.alert('Hello, World!', 'Hello', 'Got it!');
    }
  }
  //#endregion

  //#region sample:confirmationDialog
  public triggerConfirmationDialog(useDanger = false) {
    this.dialogService.confirm({
      title: 'Are you sure?',
      message: 'This action cannot be undone.',
      primaryButton: useDanger ? undefined : 'Yes',
      secondaryButton: 'No',
      dangerButton: useDanger ? 'Delete' : undefined,
    });
  }
  //#endregion

  //#region sample:simpleFormDialog
  public triggerSimpleFormDialog() {
    this.dialogService.open(SimpleFormDialogComponent, {});
  }
  //#endregion

  //#region sample:bamDialog
  public triggerBamDialog() {
    this.dialogService.openBAM(BAMDialogComponent);
  }
  //#endregion
}
