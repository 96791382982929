import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, output } from '@angular/core';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';

import { MdwEmoji, icons } from '../../models';

@Component({
  selector: 'mdw-emoji-panel',
  templateUrl: './mdw-emoji-panel.component.html',
  styleUrls: ['./mdw-emoji-panel.component.scss'],
  standalone: true,
  imports: [CommonModule, PickerComponent],
})
export class MdwEmojiPanelComponent implements AfterViewInit {
  public emojiSelect = output<MdwEmoji>();

  public emojiIconOverrides = icons;

  private isInitialized = false;

  public ngAfterViewInit(): void {
    setTimeout(() => this.removeNativeTooltips(), 200);
  }

  public handleEmojiSelect(event: { emoji: MdwEmoji }) {
    this.emojiSelect.emit(event.emoji);
  }

  public setFocus() {
    const searchInput = document.querySelector(`emoji-search input`) as HTMLInputElement;
    searchInput?.focus();
  }

  public initEmojiPanel() {
    if (this.isInitialized) {
      return;
    }

    // called from outside this component when the flyout is opened
    this.initCategoryListeners();
    this.removeNativeTooltips();

    this.isInitialized = true;
  }

  private initCategoryListeners() {
    const selectedCategory = document.querySelector('.emoji-mart-anchor-selected') as HTMLElement;
    selectedCategory?.click();
  }

  private removeNativeTooltips() {
    const categories = document.querySelectorAll('.emoji-mart-anchor') as NodeListOf<HTMLElement>;
    categories.forEach(category => {
      category.title = '';
    });

    const defaultSkins = document.querySelectorAll('.emoji-mart-skin') as NodeListOf<HTMLElement>;
    defaultSkins.forEach(skin => {
      skin.title = '';
    });
  }
}
