import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef, input } from '@angular/core';
import 'prismjs';
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace.js';
import 'prismjs/plugins/line-numbers/prism-line-numbers.js';
import { filter, map } from 'rxjs/operators';

import { SampleService } from './sample.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Prism: any;

@Component({
  selector: 'app-sample',
  standalone: true,
  templateUrl: './sample.component.html',
  styleUrls: ['./sample.component.scss'],
  imports: [CommonModule],
})
export class SampleComponent {
  public sampleContent = input<TemplateRef<unknown>>({} as TemplateRef<unknown>);
  public sampleName = input<string>('');
  public stackVertical = input<boolean>(false);

  @Input()
  public indentOffset = 0;

  public sampleSnippet$ = this.sampleService.snippets$.pipe(
    filter(snippets => !!snippets),
    map(snippets => {
      const snippet = snippets[this.sampleName()];

      return snippet;
    }),
  );

  constructor(private sampleService: SampleService) {}

  public createSampleMarkup(htmlString: string) {
    return Prism.highlight(
      Prism.plugins.NormalizeWhitespace.breakLines(
        Prism.plugins.NormalizeWhitespace.removeIndent(htmlString),
        100,
      ),
      Prism.languages.markup,
      'html',
    );
  }

  public createSampleCode(tsString: string) {
    return Prism.highlight(
      Prism.plugins.NormalizeWhitespace.breakLines(
        Prism.plugins.NormalizeWhitespace.removeIndent(tsString),
        100,
      ),
      Prism.languages.javascript,
      'typescript',
    );
  }

  public createSampleScss(scssString: string) {
    return Prism.highlight(
      Prism.plugins.NormalizeWhitespace.breakLines(
        Prism.plugins.NormalizeWhitespace.removeIndent(scssString),
        100,
      ),
      Prism.languages.css,
      'scss',
    );
  }
}
