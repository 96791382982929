@if (label()) {
  <label for="">{{ label() }}</label>
}
@if (description()) {
  <div class="input-description">{{ description() }}</div>
}

@for (choice of choices(); track choice.value) {
  @if (multiSelect()) {
    <label class="checkbox-label">
      <input
        type="checkbox"
        [disabled]="isDisabled()"
        [checked]="isSelected(choice)"
        (change)="toggleSelection(choice)"
      />
      {{ choice.label }}
    </label>
  } @else {
    <label class="radio-label">
      <input
        type="radio"
        [disabled]="isDisabled()"
        [checked]="isSelected(choice)"
        (change)="toggleSelection(choice)"
      />
      {{ choice.label }}
    </label>
  }
}
@if (errorMessages.length > 0) {
  <div class="input-error">
    {{ errorMessages.join(', ') }}
  </div>
}
