import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { ListItemComponent } from '@spaces-ui/meadow/components/list-item';
import { Channel, Mark, Logout } from '@spaces-ui/meadow/icons/svgs';

import { SampleComponent } from '../../components';

@Component({
  selector: 'app-list-items',
  standalone: true,
  imports: [CommonModule, SampleComponent, ListItemComponent],
  templateUrl: './list-items.component.html',
  styleUrl: './list-items.component.scss',
})
export class ListItemsComponent {
  public icons = { Channel, Logout, Mark };
  public selectedItem = -1;
  public accentedItem = 2;
  public isDisabled = true;
  public selectedTab = 'tab1';

  public alert(alertValue: string) {
    alert(alertValue);
  }
}
