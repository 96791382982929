import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import {
  MdwEmoji,
  MdwEmojiComponent,
  MdwEmojiPanelComponent,
} from '@spaces-ui/meadow/components/emojis';

import { SampleComponent } from '../../components';

@Component({
  selector: 'app-emojis',
  templateUrl: './emojis.component.html',
  styleUrls: ['./emojis.component.scss'],
  standalone: true,
  imports: [CommonModule, MdwEmojiComponent, MdwEmojiPanelComponent, SampleComponent],
})
export class EmojisComponent {
  public selectedEmoji?: MdwEmoji;

  public handleEmojiSelect(emoji: MdwEmoji) {
    this.selectedEmoji = emoji;
  }
}
