import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { ButtonComponent } from '@spaces-ui/meadow/components/button';
import { IconComponent } from '@spaces-ui/meadow/icons';
import { Apple_Fill } from '@spaces-ui/meadow/icons/svgs';

import { SampleComponent } from '../../components';

@Component({
  selector: 'app-icon-callouts',
  standalone: true,
  imports: [CommonModule, SampleComponent, ButtonComponent, IconComponent],
  templateUrl: './icon-callouts.component.html',
  styleUrl: './icon-callouts.component.scss',
})
export class IconCalloutsComponent {
  public icons = { Apple_Fill };
}
