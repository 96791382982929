@if (label()) {
  <label for="">{{ label() }}</label>
}
@if (description()) {
  <div class="input-description">{{ description() }}</div>
}
<button
  class="mdw-select-trigger full-width"
  [cdkMenuTriggerFor]="dropdown"
  #menuTrigger="cdkMenuTriggerFor"
  [class.error]="formControl.touched && errorMessages.length > 0"
  [class.has-template]="selectedItemTemplateRef() && selectedValues().length > 0"
  [class.scrollable]="selectedItemTemplateRef() && selectedValues().length > 0"
>
  @if (selectedValues().length === 0) {
    <span class="secondary">{{ placeholder() }}</span>
  } @else if (selectedItemTemplateRef()) {
    <div
      class="hflex-075 pad-075 flex-align-items-center flex-wrap selected-item-template scrollable fill-width"
      [style.maxHeight.px]="maxSelectedItemTemplateSize()"
    >
      @for (selectedOption of selectedValues(); track $index) {
        <mdw-chip (dismiss)="removeSelectedOption(selectedOption, $event)">
          <ng-container
            *ngTemplateOutlet="
              selectedItemTemplateRef()!;
              context: { $implicit: selectedOption.rawOption }
            "
          ></ng-container>
        </mdw-chip>
      }
    </div>
  } @else {
    <span class="selected-value">{{ this.selectedValueDisplayField() }}</span>
  }
  @if (!selectedItemTemplateRef() || selectedValues().length === 0) {
    <mdw-icon [icon]="icons.DropdownArrow" class="chevron"></mdw-icon>
  }
</button>
<ng-template #dropdown>
  <div
    class="surface-floating pad-100 vflex-100 select-menu overflow-hidden"
    (closed)="dropdownClosed()"
    cdkMenu
  >
    @if (multiSelect()) {
      <div class="hflex-000">
        <button class="mdw-button mdw-muted fill-flex" #selectAllButton (click)="selectAll()">
          Select All
        </button>
      </div>
    }
    <ul
      cdkListbox
      [formControl]="formControl"
      [cdkListboxValue]="[valueProperty()]"
      [cdkListboxMultiple]="multiSelect()"
      navigateDisabledOptions
      (cdkListboxValueChange)="valueChange()"
      class="scrollable fill-flex"
    >
      @for (option of listOptions(); track $index) {
        <li [cdkOption]="option" [useCheckbox]="multiSelect()" [class.custom]="templateRef()">
          @if (templateRef()) {
            <ng-container
              *ngTemplateOutlet="templateRef()!; context: { $implicit: option.rawOption }"
            ></ng-container>
          } @else {
            {{ option.label }}
          }
        </li>
      }
    </ul>
    @if (noItems()) {
      <div class="secondary">No items found</div>
    }
    @if (search().length > 0) {
      <div class="secondary">
        <small>filter: {{ search() }}</small>
      </div>
    }
  </div>
</ng-template>

<!-- Enable the following line for debugging the list -->
<!-- <ng-container *ngTemplateOutlet="dropdown"></ng-container> -->

@if (formControl.touched && errorMessages.length > 0) {
  <div class="input-error">
    {{ errorMessages.join(', ') }}
  </div>
}
