import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';

import { ButtonComponent } from '@spaces-ui/meadow/components/button';
import { MdwNotificationsService } from '@spaces-ui/meadow/components/notifications';

import { SampleComponent } from '../../components';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [CommonModule, SampleComponent, ButtonComponent],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
})
export class NotificationsComponent {
  //#region sample:successExample|errorExample
  private notificationService = inject(MdwNotificationsService);
  //#endregion

  //#region sample:errorExample
  public showError() {
    this.notificationService.showError('This is an error notification!', 'Error message');
  }
  //#endregion

  //#region sample:successExample
  public showSuccess() {
    this.notificationService.showSuccess('This is a success notification!');
  }
  //#endregion
}
