import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, signal } from '@angular/core';

import { AsideComponent } from '@spaces-ui/meadow/components/aside';
import { ListItemComponent } from '@spaces-ui/meadow/components/list-item';

import { TokenService } from './tokens.service';
import { ColorPaletteService } from '../colors/color-palette.service';

interface TokenValue {
  label: string;
  value: string;
}
interface Token {
  token: string;
  sunshineValue: TokenValue;
  moonshineValue: TokenValue;
}
interface TokenCategory {
  category: string;
  tokens: Token[];
}

@Component({
  selector: 'app-tokens',
  standalone: true,
  imports: [CommonModule, AsideComponent, ListItemComponent],
  templateUrl: './token-overview.component.html',
  styleUrl: './token-overview.component.scss',
})
export class TokenOverviewComponent implements OnInit {
  public tokenService = inject(TokenService);
  public colorService = inject(ColorPaletteService);
  public tokens = signal<TokenCategory[]>([]);

  public backgroundColors = {
    sunshine: '#ffffff',
    moonshine: '#000000',
  };

  async ngOnInit() {
    const colors = await this.colorService.getColorVariables();
    const tokens = await this.tokenService.getTokens();
    const tokenGroups: TokenCategory[] = [];
    const categories = Object.keys(tokens);

    // loop through each property in the tokens object
    categories.forEach(category => {
      const tokenGroup = {
        category,
        tokens: tokens[category].map(token => {
          if (token.token === '$color-surface-background') {
            this.backgroundColors.sunshine = this.getColorValue(token.sunshineValue, colors);
            this.backgroundColors.moonshine = this.getColorValue(token.moonshineValue, colors);
          }
          return {
            token: token.token,
            sunshineValue: {
              label: this.getColorLabel(token.sunshineValue),
              value: this.getColorValue(token.sunshineValue, colors),
            },
            moonshineValue: {
              label: this.getColorLabel(token.moonshineValue),
              value: this.getColorValue(token.moonshineValue, colors),
            },
          };
        }),
      };
      tokenGroups.push(tokenGroup);
    });

    this.tokens.set(tokenGroups);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getColorValue(tokenValue: string, colors: any) {
    const colorName = tokenValue?.replace('#{$', '').replace('}', '');
    if (tokenValue?.startsWith('#{hex-to-rgba(')) {
      const rgbaElements = tokenValue.replace('#{hex-to-rgba(', '').replace(')}', '').split(', ');
      const color = this.hexToRgb(
        colors[rgbaElements[0].replace('$', '')],
        parseFloat(rgbaElements[1]),
      );
      return color;
    }
    return colors[colorName];
  }

  private getColorLabel(tokenValue: string) {
    if (!tokenValue) {
      return '';
    }

    const cleanedValue = tokenValue
      ?.replace('#{$', '')
      .replace('}', '')
      .replace('#{hex-to-rgba($', '')
      .replace(')', '');

    const valueElems = cleanedValue.split(', ');
    if (valueElems.length === 1) {
      return cleanedValue;
    }

    const percentage = parseFloat(valueElems[1]) * 100;
    return `${valueElems[0]} @ ${percentage}%`;
  }

  private hexToRgb(hex: string, opacity = 1) {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Parse the r, g, b values
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
}
