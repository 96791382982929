<div class="chip-container hflex-100 inline-flex flex-align-items-center border-radius-100">
  <div class="chip-content hflex-075 flex-align-items-center">
    <ng-content></ng-content>
  </div>
  <button
    class="mdw-button mdw-tertiary small-button"
    [icon]="icons.Close"
    (click)="dismiss.emit($event)"
  ></button>
</div>
