import { Route } from '@angular/router';

import {
  ColorsComponent,
  TypographyComponent,
  ContainersComponent,
  LayoutComponent,
  IconsComponent,
  ButtonsComponent,
  ListItemsComponent,
  AppElementsComponent,
  BadgesComponent,
  DialogsComponent,
  FormsComponent,
  MenusComponent,
  TokenOverviewComponent,
  InputsComponent,
  DividersComponent,
  TooltipsComponent,
  SelectsComponent,
  NotificationsComponent,
  AvatarsComponent,
  EmojisComponent,
  ChipsComponent,
  TabsComponent,
  IconCalloutsComponent,
} from './views';

export const appRoutes: Route[] = [
  {
    path: 'tokens',
    component: TokenOverviewComponent,
  },
  {
    path: 'colors',
    component: ColorsComponent,
  },
  {
    path: 'typography',
    component: TypographyComponent,
  },
  {
    path: 'containers',
    component: ContainersComponent,
  },
  {
    path: 'layout',
    component: LayoutComponent,
  },
  {
    path: 'icons',
    component: IconsComponent,
  },
  {
    path: 'buttons',
    component: ButtonsComponent,
  },
  {
    path: 'list-items',
    component: ListItemsComponent,
  },
  {
    path: 'app-elements',
    component: AppElementsComponent,
  },
  {
    path: 'badges',
    component: BadgesComponent,
  },
  {
    path: 'dialogs',
    component: DialogsComponent,
  },
  {
    path: 'menus',
    component: MenusComponent,
  },
  {
    path: 'forms',
    component: FormsComponent,
  },
  {
    path: 'inputs',
    component: InputsComponent,
  },
  {
    path: 'dividers',
    component: DividersComponent,
  },
  {
    path: 'tooltips',
    component: TooltipsComponent,
  },
  {
    path: 'selects',
    component: SelectsComponent,
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
  },
  {
    path: 'avatars',
    component: AvatarsComponent,
  },
  {
    path: 'emojis',
    component: EmojisComponent,
  },
  {
    path: 'chips',
    component: ChipsComponent,
  },
  {
    path: 'tabs',
    component: TabsComponent,
  },
  {
    path: 'icon-callout',
    component: IconCalloutsComponent,
  },
  {
    path: '**',
    redirectTo: 'colors',
    pathMatch: 'full',
  },
];
