<div class="hflex-075 hpad-150 vpad-100">
  @if (data.type === 'error') {
    <mdw-icon class="color-R200" [icon]="icons.Warning_Fill"></mdw-icon>
  } @else if (data.type === 'success') {
    <mdw-icon class="color-G200" [icon]="icons.Check_Large"></mdw-icon>
  }
  @if (data.message) {
    <div class="dense hflex-000 flex-align-items-center overflow-hidden">
      <div class="overflow-text-ellipses">
        {{ data.message }}
      </div>
    </div>
  }
</div>
