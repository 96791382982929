import { CommonModule } from '@angular/common';
import { Component, HostListener, output } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ButtonComponent } from '@spaces-ui/meadow/components/button';
import { ListItemComponent } from '@spaces-ui/meadow/components/list-item';
import { Sidebar_Duotone } from '@spaces-ui/meadow/icons/svgs';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, ListItemComponent, ButtonComponent],
  selector: 'app-secondary-sidebar',
  templateUrl: './app-secondary-sidebar.component.html',
  styleUrl: './app-secondary-sidebar.component.scss',
})
export class AppSecondarySidebarComponent {
  isCollapsed = false;
  isActive = false;
  collapse = output();
  icons = { Sidebar_Duotone };

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
  }

  setActive() {
    this.isActive = !this.isActive;
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onResize(event: any) {
    if (event.target.innerWidth > 768) {
      this.isCollapsed = false;
    }
  }
}
