<div [ngClass]="stackVertical() ? 'vflex-300' : 'hflex-200'">
  <div class="example pad-300">
    <ng-container *ngTemplateOutlet="sampleContent()"></ng-container>
  </div>

  <div *ngIf="sampleSnippet$ | async as snippet" class="samples vflex-050 surface-muted">
    <pre
      *ngIf="snippet.html"
      class="html fill-flex"
    ><code [innerHtml]="createSampleMarkup(snippet.html)"></code></pre>
    <pre
      *ngIf="snippet.code"
      class="ts fill-flex"
    ><code [innerHtml]="createSampleCode(snippet.code)"></code></pre>
    <pre
      *ngIf="snippet.styles"
      class="scss fill-flex"
    ><code [innerHtml]="createSampleCode(snippet.styles)"></code></pre>
  </div>
</div>
