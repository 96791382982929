export class EventUtils {
  /**
   * Used to prevent bubbling of events
   * @param event event
   */
  public static preventBubble(event: Event) {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    if (event.preventDefault) {
      event.preventDefault();
    }
  }
}
