import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { DialogComponent } from '@spaces-ui/meadow/components/dialog';

@Component({
  selector: 'app-bam-dialog',
  standalone: true,
  imports: [CommonModule, DialogComponent],
  templateUrl: './bam-dialog.component.html',
  styleUrl: './bam-dialog.component.scss',
})
export class BAMDialogComponent {}
