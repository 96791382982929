<div class="vflex-200">
  <h2>Layout</h2>
  <h3>Spacing Tokens</h3>
  <p>
    The spacing tokens are used to create consistent spacing between elements. The spacing tokens
    are between 0 and 1000, and they represent the percentages of 8px. The spacing tokens are used
    in the padding and margin classes. So, $space-100 is 8px, while $space-025 (25% of 8px) is 2px.
  </p>
  <p>
    The spacing tokens are available as sass variables, but we do have dedicated helper classes for
    padding and gutters (margins).
  </p>
  <table class="narrow spaced-500">
    <thead>
      <tr>
        <th>Token</th>
        <th>Value</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>$space-000</td>
        <td>0px</td>
      </tr>
      <tr>
        <td>$space-025</td>
        <td>2px</td>
      </tr>
      <tr>
        <td>$space-050</td>
        <td>4px</td>
      </tr>
      <tr>
        <td>$space-075</td>
        <td>6px</td>
      </tr>
      <tr>
        <td>$space-100</td>
        <td>8px</td>
      </tr>
      <tr>
        <td>$space-150</td>
        <td>12px</td>
      </tr>

      <tr>
        <td>$space-200</td>
        <td>16px</td>
      </tr>
      <tr>
        <td>$space-250</td>
        <td>20px</td>
      </tr>
      <tr>
        <td>$space-300</td>
        <td>24px</td>
      </tr>
      <tr>
        <td>$space-350</td>
        <td>28px</td>
      </tr>
      <tr>
        <td>$space-400</td>
        <td>32px</td>
      </tr>
      <tr>
        <td>$space-500</td>
        <td>40px</td>
      </tr>
      <tr>
        <td>$space-600</td>
        <td>48px</td>
      </tr>
      <tr>
        <td>$space-700</td>
        <td>56px</td>
      </tr>
      <tr>
        <td>$space-800</td>
        <td>64px</td>
      </tr>
      <tr>
        <td>$space-900</td>
        <td>72px</td>
      </tr>
      <tr>
        <td>$space-1000</td>
        <td>80px</td>
      </tr>
    </tbody>
  </table>
  <h3>Layout Helper Classes</h3>
  <strong>hflex-xxx</strong>
  <div>
    <code>hflex-&#123;space-size&#125;</code>
    is used to instantiate a display:flex container with flex-direction:row, with a gap matching the
    provided space size.
  </div>
  <strong>vflex-xxx</strong>
  <div>
    <code>vflex-&#123;space-size&#125;</code>
    is used to instantiate a display:flex container with flex-direction:column, with a gap matching
    the provided space size.
  </div>
  <strong>fill-flex</strong>
  <div>
    <code>fill-flex</code>
    is used to set the flex property to 1, which will cause the element to fill the available space.
  </div>
  <strong>pad-xxx</strong>
  <div>
    <code>pad-&#123;space-size&#125;</code>
    is used to define the amount of padding within the container. This will set equal padding
    amounts on all sides.
  </div>
  <strong>vpad-xxx</strong>
  <div>
    <code>vpad-&#123;space-size&#125;</code>
    is used to define the amount of padding on the top and bottom of the container.
  </div>
  <strong>hpad-xxx</strong>
  <div>
    <code>hpad-&#123;space-size&#125;</code>
    is used to define the amount of padding on the left and right of the container.
  </div>
  <strong>pad-top-xxx</strong>
  <div>
    <code>pad-top-&#123;space-size&#125;</code>
    is used to define the amount of padding on the top of the container.
  </div>
  <strong>pad-bottom-xxx</strong>
  <div>
    <code>pad-bottom-&#123;space-size&#125;</code>
    is used to define the amount of padding on the bottom of the container.
  </div>
  <strong>pad-left-xxx</strong>
  <div>
    <code>pad-left-&#123;space-size&#125;</code>
    is used to define the amount of padding on the left of the container.
  </div>
  <strong>pad-right-xxx</strong>
  <div>
    <code>pad-right-&#123;space-size&#125;</code>
    is used to define the amount of padding on the right of the container.
  </div>
  <strong>gutter-xxx</strong>
  <div>
    <code>gutter-&#123;space-size&#125;</code>
    is used to define the amount of margin around the element. This will set equal margin amounts.
  </div>
  <strong>top-gutter-xxx</strong>
  <div>
    <code>top-gutter-&#123;space-size&#125;</code>
    is used to define the amount of margin on the top of the element.
  </div>
  <strong>bottom-gutter-xxx</strong>
  <div>
    <code>bottom-gutter-&#123;space-size&#125;</code>
    is used to define the amount of margin on the bottom of the element.
  </div>
  <strong>left-gutter-xxx</strong>
  <div>
    <code>left-gutter-&#123;space-size&#125;</code>
    is used to define the amount of margin on the left of the element.
  </div>
  <strong>right-gutter-xxx</strong>
  <div>
    <code>right-gutter-&#123;space-size&#125;</code>
    is used to define the amount of margin on the right of the element.
  </div>
  <strong>border-radius-xxx</strong>
  <div>
    <code>border-radius-&#123;space-size&#125;</code>
    is used to define the border radius of the element.
  </div>
  <strong>border-radius-top-xxx</strong>
  <div>
    <code>border-radius-top-&#123;space-size&#125;</code>
    is used to define the border-top-left and border-top-right radius of the element.
  </div>
  <strong>border-radius-bottom-xxx</strong>
  <div>
    <code>border-radius-bottom-&#123;space-size&#125;</code>
    is used to define the border-bottom-left and border-bottom-right radius of the element.
  </div>
  <strong>sticky-xxx</strong>
  <div>
    <p>
      <code>sticky-&#123;space-size&#125;</code>
      is used to mark an element as sticky. The sticky top positioning will use the space token to
      determine where the sticky element will be pinned.
    </p>
    <b>NOTE: the ancestor scroll container cannot be a flex container</b>
  </div>
  <strong>border-primary</strong>
  <div>
    <code>border-primary</code>
    is used to define a 1px solid border with the primary color.
  </div>
  <strong>border-primary-xxx</strong>
  <div>
    <code>border-primary-&#123;top/bottom/left/right&#125;</code>
    is used to define a 1px solid border with the primary color for the given edge.
  </div>

  <strong>border-muted</strong>
  <div>
    <code>border-muted</code>
    is used to define a 1px solid border with the muted color.
  </div>
  <strong>border-muted-xxx</strong>
  <div>
    <code>border-muted-&#123;top/bottom/left/right&#125;</code>
    is used to define a 1px solid border with the muted color for the given edge.
  </div>
  <hr />

  <h3>Samples</h3>
  <div class="surface-flat-outline pad-000">
    <ng-template #helloSample data-sample="helloSample">
      <div class="pad-300 vflex-400 surface-flat-outline">
        <p>
          This is a sample container with a padding of 300. The container is a flex container with a
          gap of 400. The container is styled with a flat outline surface.
        </p>
        <label for="">label 1</label>
        <p>
          Some sample text goes in here. Note the spacing between the different elements as well as
          the padding
        </p>
        <button>Click me</button>
      </div>
    </ng-template>
    <app-sample [sampleContent]="helloSample" sampleName="helloSample"></app-sample>
  </div>
</div>
