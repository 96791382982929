import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

interface TokenGroup {
  [category: string]: Array<{ token: string; sunshineValue: string; moonshineValue: string }>;
}

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private tokensFilePath = 'assets/design_tokens.json';

  constructor(private http: HttpClient) {}

  getTokens(): Promise<TokenGroup> {
    return firstValueFrom(this.http.get<TokenGroup>(this.tokensFilePath));
  }
}
