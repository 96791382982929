import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ChoiceListComponent, InputDirective } from '@spaces-ui/meadow/components/input';
import { IconComponent } from '@spaces-ui/meadow/icons';
import { Home_Duotone } from '@spaces-ui/meadow/icons/svgs';

import { SampleComponent } from '../../components';

@Component({
  selector: 'app-inputs',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SampleComponent,
    IconComponent,
    InputDirective,
    ChoiceListComponent,
  ],
  templateUrl: './inputs.component.html',
  styleUrl: './inputs.component.scss',
})
export class InputsComponent {
  public icons = { Home_Duotone };

  public formGroup = new FormGroup({
    input: new FormControl('', Validators.required),
    checkBox1: new FormControl(false),
    checkBox2: new FormControl({ value: false, disabled: true }),
    singleSelection: new FormControl('', Validators.required),
    multiSelection: new FormControl([], Validators.required),
    toggle1: new FormControl(false),
    toggle2: new FormControl(true),
    toggle3: new FormControl({ value: false, disabled: true }),
    toggle4: new FormControl({ value: true, disabled: false }),
    toggle5: new FormControl({ value: false, disabled: false }),
  });

  //#region sample:radioInputs
  public options = [
    { label: 'Option 1', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' },
  ];
  //#endregion
}
