<header class="hflex-300 fill-width flex-align-items-center">
  <ng-content select="[header-left]"></ng-content>
  @if (icon()) {
    @if (iconTemplate()) {
      <ng-container *ngTemplateOutlet="iconTemplate()!"></ng-container>
    } @else {
      <mdw-icon [icon]="iconSvg()!"></mdw-icon>
    }
  }
  @if (mainTitle()) {
    <div class="fill-width overflow-hidden">
      <h3 class="fill-flex overflow-text-ellipses">{{ mainTitle() }}</h3>
    </div>
  }

  @if (rightConent()) {
    <div class="hflex-100 flex-align-items-center right-content pad-right-300">
      <ng-container *ngTemplateOutlet="rightConent()!"></ng-container>
    </div>
  }
  <ng-content select="[header-right]"></ng-content>
  @if (showCloseButton()) {
    <button
      (click)="closed.emit()"
      class="mdw-button mdw-tertiary close-button"
      [icon]="icons.Close"
    ></button>
  }
</header>
