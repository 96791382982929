<div
  class="fill-height aside-content"
  [@asideAnimation]="{
    value: isCollapsed() ? 'collapsed' : 'expanded',
    params: {
      width: width() + 'px',
    },
  }"
>
  <ng-content></ng-content>
</div>
