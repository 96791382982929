<nav class="emphasized tab-group">
  @if (selectedListItem()) {
    <div
      class="selected-highlight border-radius-100"
      [style.left.px]="selectedListItem()?.offsetLeft"
      [style.width.px]="selectedListItem()?.clientWidth"
      [style.height.px]="selectedListItem()?.clientHeight"
    ></div>
  }
  <ul>
    @for (tab of tabs(); track tab.alias) {
      <li
        [attr.id]="tab.alias"
        [attr.data-pw]="'go-to-' + tab.alias + '-page'"
        [class.selected]="selectedTab() === tab.alias"
        [icon]="tab.icon ?? ''"
        (click)="goTo.emit(tab.alias)"
      >
        {{ tab.label }}
      </li>
    }
  </ul>
</nav>
