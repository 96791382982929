<div class="hflex-200 overflow-hidden">
  <div class="vflex-300 fill-flex scrollable">
    <h2>Design Tokens</h2>
    <p>
      Tokens are used to semantically represent design decisions in a way that is constumizable by
      themes.
    </p>
    @for (category of tokens(); track $index) {
      <div class="vflex-300 surface-flat pad-200">
        <h2 [attr.id]="category.category">{{ category.category }}</h2>
        <table>
          <tr class="centered">
            <th width="40%">Token</th>
            <th width="30%">Sunshine</th>
            <th width="30%">Moonshine</th>
          </tr>
          @for (token of category.tokens; track $index) {
            <tr>
              <th>{{ token.token }}</th>
              <td class="secondary" [style.background]="backgroundColors['sunshine']">
                @if (token.sunshineValue.label) {
                  <div class="sample" [style.background-color]="token.sunshineValue.value"></div>
                  {{ token.sunshineValue.label }}
                } @else {
                  <span class="mdw-notification-badge">N/D</span>
                }
              </td>
              <td class="inverse" [style.background]="backgroundColors['moonshine']">
                @if (token.moonshineValue.label) {
                  <div class="sample" [style.background-color]="token.moonshineValue.value"></div>
                  {{ token.moonshineValue.label }}
                } @else {
                  <span class="mdw-notification-badge">N/D</span>
                }
              </td>
            </tr>
          }
        </table>
      </div>
    }
  </div>
  <aside [autoCollapse]="1440" pinnedSurfaceStyle="muted" class="pad-300">
    <h3>Table of Contents</h3>
    <ul>
      @for (category of tokens(); track $index) {
        <li href="tokens#{{ category.category }}">{{ category.category }}</li>
      }
    </ul>
  </aside>
</div>
