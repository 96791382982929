@if (isDeleted()) {
  <mdw-icon
    [class.show-count]="numberBadge() !== undefined && numberBadge()! > 0"
    class="initials deleted-user"
    [icon]="icons.User_Fill"
  ></mdw-icon>
} @else if (srcWithVersion()) {
  <img
    [src]="srcWithVersion()"
    [alt]="alt() || name()"
    [class.show-status]="online() !== undefined || snoozed() !== undefined"
    [class.snoozed]="!!snoozed()"
    [class.show-count]="numberBadge() !== undefined && numberBadge()! > 0"
  />
} @else if (name() && name()!.length > 0) {
  <div
    class="initials"
    [class.show-status]="online() !== undefined || snoozed() !== undefined"
    [class.snoozed]="!!snoozed()"
    [class.show-count]="numberBadge() !== undefined && numberBadge()! > 0"
    [ngClass]="'scheme-' + color()"
  >
    {{ name()![0] }}
  </div>
}

@if (numberBadge() !== undefined && numberBadge()! > 0) {
  @switch (numberBadge()) {
    @case (1) {
      <mdw-icon [icon]="icons.BadgeNumber_1" [ngClass]="size() + '-icon'"></mdw-icon>
    }
    @case (2) {
      <mdw-icon [icon]="icons.BadgeNumber_2" [ngClass]="size() + '-icon'"></mdw-icon>
    }
    @case (3) {
      <mdw-icon [icon]="icons.BadgeNumber_3" [ngClass]="size() + '-icon'"></mdw-icon>
    }
    @case (4) {
      <mdw-icon [icon]="icons.BadgeNumber_4" [ngClass]="size() + '-icon'"></mdw-icon>
    }
    @case (5) {
      <mdw-icon [icon]="icons.BadgeNumber_5" [ngClass]="size() + '-icon'"></mdw-icon>
    }
    @case (6) {
      <mdw-icon [icon]="icons.BadgeNumber_6" [ngClass]="size() + '-icon'"></mdw-icon>
    }
    @case (7) {
      <mdw-icon [icon]="icons.BadgeNumber_7" [ngClass]="size() + '-icon'"></mdw-icon>
    }
    @case (8) {
      <mdw-icon [icon]="icons.BadgeNumber_8" [ngClass]="size() + '-icon'"></mdw-icon>
    }
    @case (9) {
      <mdw-icon [icon]="icons.BadgeNumber_9" [ngClass]="size() + '-icon'"></mdw-icon>
    }
    @default {
      <mdw-icon [icon]="icons.BadgeNumber_9plus" [ngClass]="size() + '-icon'"></mdw-icon>
    }
  }
} @else {
  <!-- Status Indicators -->
  @if (online() && snoozed()) {
    <mdw-icon
      [icon]="icons.Status_Online_snoozed"
      class="status-icon online"
      [ngClass]="size() + '-icon'"
    ></mdw-icon>
  } @else if (online()) {
    <mdw-icon
      [icon]="icons.Status_Online"
      [ngClass]="size() + '-icon'"
      class="status-icon online"
    ></mdw-icon>
  } @else if (snoozed()) {
    <mdw-icon
      [icon]="icons.Status_Offline_snoozed"
      [ngClass]="size() + '-icon'"
      class="status-icon"
    ></mdw-icon>
  } @else if (online() === false) {
    <mdw-icon
      [icon]="icons.Status_Offline"
      [ngClass]="size() + '-icon'"
      class="status-icon"
    ></mdw-icon>
  }
}
