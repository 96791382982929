import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  effect,
  inject,
  input,
  model,
  output,
  signal,
} from '@angular/core';
import { Subject, throttleTime } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'aside',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './aside.component.html',
  styleUrl: './aside.component.scss',
  animations: [
    trigger('asideAnimation', [
      state('collapsed', style({ opacity: 0, width: 0 }), { params: { width: 0 } }),
      state('expanded', style({ opacity: 1, width: '{{width}}' }), { params: { width: 0 } }),
      transition('collapsed => expanded', [
        style({ width: 0, opacity: 0, overflow: 'hidden' }),
        animate('200ms ease-in', style({ width: '{{width}}' })),
        animate('200ms ease-in', style({ opacity: 1 })),
      ]),
      transition('expanded => collapsed', [
        style({ width: '{{width}}', opacity: 1, overflow: 'hidden' }),
        animate('200ms ease-in', style({ opacity: 0 })),
        animate('200ms ease-in', style({ width: 0 })),
      ]),
    ]),
  ],
})
export class AsideComponent implements OnInit {
  public element = inject<ElementRef<HTMLElement>>(ElementRef<HTMLElement>);

  public isCollapsed = model<boolean | undefined>(undefined);
  public isFloating = model<boolean | undefined>(undefined);
  public pinnedSurfaceStyle = input<'muted' | 'flat' | 'flat-inverse'>();
  public anchor = input<'left' | 'right'>('left');
  public floatAt = input(0);
  public width = input(311);
  public autoCollapse = input(1024);

  public hostWidth = signal(0);
  public clickedOutside = output();

  private resizeSubject: Subject<void> = new Subject<void>();
  private lastCheckedWidth = 0;

  @HostListener('window:click', ['$event'])
  public onClick(event: MouseEvent) {
    // close sidebar if it's open and user clicks outside of it
    if (
      this.isCollapsed() ||
      !this.isFloating() ||
      (event.target as HTMLElement).closest('.mdw-aside-trigger') !== null
    ) {
      return;
    }
    if ((event.target as HTMLElement).closest('aside') === null) {
      this.isCollapsed.set(true);
    }
  }

  @HostListener('window:resize')
  public onResize() {
    this.resizeSubject.next();
  }

  public constructor() {
    effect(() => {
      const isCollapsed = this.isCollapsed();
      if (isCollapsed) {
        setTimeout(() => {
          this.element.nativeElement.style.display = 'none';
        }, 400);
      } else {
        this.element.nativeElement.style.display = 'block';
      }
    });

    effect(() => {
      const isFloating = this.isFloating();
      if (isFloating) {
        this.element.nativeElement.classList.add('floating');
      } else {
        this.element.nativeElement.classList.remove('floating');
      }

      if (isFloating) {
        this.element.nativeElement.classList.add('surface-floating');
      } else {
        this.element.nativeElement.classList.remove('surface-floating');
        if (this.pinnedSurfaceStyle()) {
          this.element.nativeElement.classList.add(`surface-${this.pinnedSurfaceStyle()}`);
        }
      }
    });

    this.resizeSubject.pipe(throttleTime(150)).subscribe(() => {
      this.evalCollapsedAndFloatingState();
    });
  }

  public ngOnInit(): void {
    this.evalCollapsedAndFloatingState(true);
    this.element.nativeElement.classList.add(`anchor-${this.anchor()}`);
  }

  private evalCollapsedAndFloatingState(isInitital = false) {
    const shouldAutoCollapse = window.innerWidth < this.autoCollapse();
    const shouldAutoFloat = window.innerWidth < this.floatAt();

    if (isInitital) {
      if (shouldAutoCollapse) {
        this.isCollapsed.set(shouldAutoCollapse);
        this.isFloating.set(shouldAutoCollapse);
      } else if (shouldAutoFloat) {
        this.isFloating.set(shouldAutoFloat);
      }
      this.lastCheckedWidth = window.innerWidth;
    } else {
      // Only change the state if we just crossed the threshold
      const wasLastWidthAutoCollapsed = this.lastCheckedWidth < this.autoCollapse();
      const wasLastWidthAutoFloat = this.lastCheckedWidth < this.floatAt();
      this.lastCheckedWidth = window.innerWidth;

      if (shouldAutoCollapse !== wasLastWidthAutoCollapsed) {
        if (shouldAutoCollapse) {
          this.isCollapsed.set(true);
          setTimeout(() => {
            this.isFloating.set(true);
          }, 200);
        } else {
          this.isFloating.set(false);
          setTimeout(() => {
            this.isCollapsed.set(false);
          }, 0);
        }
      } else if (shouldAutoFloat !== wasLastWidthAutoFloat) {
        this.isFloating.set(shouldAutoFloat);
      }
    }
  }
}
