/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, input, signal } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'mdw-choice-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './choice-list.component.html',
  styles: ``,
})
export class ChoiceListComponent implements ControlValueAccessor {
  public label = input<string>();
  public description = input<string>();
  public multiSelect = input<boolean>();
  public choices = input([] as Array<{ label: string; value: any }>);

  public isDisabled = signal(false);
  public value = [] as Array<any>;

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  onChange = (_: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  constructor(public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  writeValue(value: any): void {
    this.value = value || [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  toggleSelection(option: { label: string; value: string }) {
    if (this.multiSelect()) {
      if (this.value.includes(option.value)) {
        this.value = this.value.filter((v: string) => v !== option.value);
      } else {
        const value = [...this.value];
        value.push(option.value);
        this.value = value;
      }
    } else {
      this.value = [option.value];
    }
    this.onChange(this.value);
    this.onTouched();
  }

  isSelected(option: { label: string; value: string }) {
    return this.value.includes(option.value);
  }

  get errorMessages() {
    const errors = this.ngControl.control?.errors;
    const errorMessages = [] as string[];

    if (!errors) {
      return errorMessages;
    } else {
      Object.keys(errors).forEach(key => {
        const error = 'There seems to be an error with this field.';

        switch (key) {
          case 'required': {
            errorMessages.push('Please select an item.');
            break;
          }
          default: {
            errorMessages.push(error);
          }
        }
      });
    }

    return errorMessages;
  }
}
