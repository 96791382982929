<div class="vflex-300">
  <h2>Icons</h2>
  <p>
    The Meadow Icons library provides a collection of icons for use in Meadow applications. They are
    compiled as ts files and can be imported into your application. Tree shaking will remove any
    unused icons from your bundle.
  </p>
  <div class="surface-muted pad-300 vflex-200">
    <h3>Icon component</h3>
    <p>
      To use an icon, simply add the
      <code>mdw-icon</code>
      component to your template and bind the
      <code>icon</code>
      input to the icon you want to display.
    </p>
    <p>
      The
      <code>icon</code>
      input accepts an SVG html string. In most cases this will be an imported icon from the meadow
      icons library. The way to import the icons is as follows:
    </p>
    <pre>
      component.ts
      <code>
        import &#123; Home_Outline &#125; from '&#64;spaces-ui/meadow/icons/svgs';
        <br>
        public icon = &#123; Home_Outline &#125;
      </code>
      component.html
      <code>
        &lt;mdw-icon [icon]="icons.Home_Outline"&gt;&lt;/mdw-icon&gt;
      </code>
    </pre>
  </div>
  <h3>Styling classes</h3>
  <p>
    The
    <code>mdw-icon</code>
    component has a number of classes that can be used to style the icon. These classes are
    <code>small-icon</code>
    ,
    <code>medium-icon (default)</code>
    ,
    <code>large-icon</code>
    ,
    <code>x-large-icon</code>
    .
  </p>
  <div class="surface-muted pad-300 hflex-300">
    <mdw-icon class="small-icon" [icon]="homeIcon"></mdw-icon>
    <mdw-icon class="medium-icon" [icon]="homeIcon"></mdw-icon>
    <mdw-icon class="large-icon" [icon]="homeIcon"></mdw-icon>
    <mdw-icon class="x-large-icon" [icon]="homeIcon"></mdw-icon>
  </div>
  <h3>Icon Library</h3>
  <div>
    <button class="mdw-button large-button mdw-primary" (click)="useColor = !useColor">
      Toggle Color
    </button>
  </div>
  <div class="vflex-200">
    @for (icon of allIcons; track $index) {
      <div class="surface-raised pad-200 hflex-200 icon-sample" [class.use-color]="useColor">
        <div class="icon-key">{{ icon.key }}</div>
        <div class="hflex-200">
          @for (variant of icon.value; track $index) {
            <div class="vflex-050">
              <mdw-icon [icon]="variant.value"></mdw-icon>
              <small class="secondary">{{ variant.style }}</small>
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>
