<div class="vflex-300">
  <h2>Tabs</h2>
  <div class="surface-muted pad-200">
    <code>
      import &#123; TabGroupComponent &#125; from '&#64;spaces-ui/meadow/components/tab-group';
    </code>
  </div>
  <h3>Tabbed Nav List</h3>
  <p>This uses the list item component in a tabbed structure</p>
  <ng-template #tabbedNavItems data-sample="tabbedNavItems">
    <mdw-tab-group
      [tabs]="[
        { label: 'Item 1', alias: 'tab1' },
        { label: 'Item 2', alias: 'tab2' },
        { label: 'Item 3', alias: 'tab3' },
      ]"
      [selectedTab]="selectedTab"
      (goTo)="selectedTab = $event"
    ></mdw-tab-group>
  </ng-template>
  <app-sample [sampleContent]="tabbedNavItems" sampleName="tabbedNavItems"></app-sample>
</div>
