import { CdkListbox, CdkOption } from '@angular/cdk/listbox';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import {
  Component,
  HostListener,
  TemplateRef,
  computed,
  effect,
  input,
  model,
  signal,
  viewChild,
  viewChildren,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';

import { ButtonComponent } from '@spaces-ui/meadow/components/button';
import { ChipComponent } from '@spaces-ui/meadow/components/chip';
import { InputDirective } from '@spaces-ui/meadow/components/input';
import { ListItemComponent } from '@spaces-ui/meadow/components/list-item';
import { IconComponent } from '@spaces-ui/meadow/icons';
import { Close, DropdownArrow } from '@spaces-ui/meadow/icons/svgs';
import { alphaSortByProperty, ArrayUtils, EventUtils, sort } from '@spaces-ui/shared';

export interface SelectOption<T, V> {
  label: string;
  value: V;
  rawOption: T;
}

@Component({
  selector: 'mdw-select',
  standalone: true,
  imports: [
    CommonModule,
    CdkOption,
    CdkListbox,
    CdkMenu,
    CdkMenuTrigger,
    ListItemComponent,
    IconComponent,
    ReactiveFormsModule,
    CdkMenuItem,
    InputDirective,
    ButtonComponent,
    FormsModule,
    ChipComponent,
  ],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
})
export class SelectComponent<T, V> implements ControlValueAccessor {
  public options = input<Array<T | string>>([]);
  public placeholder = input('');
  public label = input('');
  public description = input('');
  public multiSelect = input(false);
  public searchPlaceholder = input('');
  public clearSearchOnSelect = input(true);
  public labelProperty = input<keyof T>();
  public valueProperty = input<keyof T>();
  public sortProperty = input<keyof T>();
  public templateRef = input<TemplateRef<unknown>>();
  public selectedItemTemplateRef = input<TemplateRef<unknown>>();
  public allowSelectedItemDismiss = input(true);
  public maxSelectedItemTemplateSize = input(192);
  public customErrorMessages = input<{ [key: string]: string }>({});

  public icons = { DropdownArrow, Close };
  public isDisabled = signal(false);
  public value?: Array<V> | V = this.multiSelect() ? ([] as Array<V>) : undefined;
  public search = model('');

  public cdkOptions = viewChildren(CdkOption<SelectOption<T, V>>);
  public cdkMenuTrigger = viewChild<CdkMenuTrigger>('menuTrigger');

  public selectedValues = signal<Array<SelectOption<T, V>>>([]);

  public noItems = computed(() => {
    if (this.search().length === 0) {
      this.cdkOptions().forEach(option => (option.disabled = false));
      return false;
    } else {
      const enabledOptions: Array<SelectOption<T, V>> = [];
      this.cdkOptions().forEach(option => {
        const optionValue = option.value;
        option.disabled = !optionValue.label.toLowerCase().includes(this.search().toLowerCase());
        if (!option.disabled) {
          enabledOptions.push(optionValue);
        }
      });
      return enabledOptions.length === 0;
    }
  });
  public listOptions = computed<Array<SelectOption<T, V>>>(() => {
    const labelProperty = this.labelProperty();
    const valueProperty = this.valueProperty();
    const sortProperty = this.sortProperty();
    const options = this.options();
    const firstOption = options[0];
    const isString = typeof firstOption === 'string';
    const sortedOptions = sortProperty
      ? isString
        ? sort(options as string[], false)
        : alphaSortByProperty<T>(options as T[], sortProperty, false)
      : this.options();

    return sortedOptions.map(
      option =>
        ({
          label: this.usesStringArray()
            ? (option as string)
            : labelProperty
              ? (option as T)[labelProperty]
              : '',
          value: this.usesStringArray()
            ? (option as string)
            : valueProperty
              ? (option as T)[valueProperty]
              : option,
          rawOption: option,
        }) as SelectOption<T, V>,
    );
  });

  public selectedValueDisplayField = computed(() => {
    const value = this.selectedValues();
    return value.map(v => v.label).join(', ');
  });
  private usesStringArray = computed(() => typeof this.options()[0] === 'string');

  public formControl = new FormControl<SelectOption<T, V>[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  onChange = (_: V | V[]) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  @HostListener('document:keydown', ['$event'])
  public handleKeyDown(event: KeyboardEvent) {
    // only continue IF the select is open AND the search input is NOT focused
    if (!this.cdkOptions().length || document.activeElement?.tagName === 'INPUT') {
      return;
    }

    const controlKeys = [
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Control',
      'Shift',
      'Alt',
      'Meta',
      'CapsLock',
    ];

    if (event.key === 'Backspace') {
      this.search.set(this.search().slice(0, -1));
      return;
    } else if (event.key === 'Enter') {
      if (this.clearSearchOnSelect()) {
        this.search.set('');
      }
      return;
    } else if (event.key === ' ' && this.search().length === 0) {
      return;
    } else if (controlKeys.includes(event.key)) {
      return;
    }

    this.search.set(this.search() + event.key);
  }

  public constructor(public ngControl: NgControl) {
    ngControl.valueAccessor = this;

    this.formControl.valueChanges.subscribe(value => {
      const selectedValues = value?.map(v => v.value) || [];
      const isExternalWrite = this.multiSelect()
        ? ArrayUtils.areEqual(selectedValues, this.value as Array<V>)
        : selectedValues[0] === (this.value as V);

      this.selectedValues.set(value || []);

      // we need to set the correct value type based on multi/single select and valueProperty
      if (this.multiSelect()) {
        this.value = selectedValues;
      } else {
        this.value = selectedValues[0];
      }

      if (!isExternalWrite) {
        this.onChange(this.value);
        this.onTouched();
      }
    });

    effect(() => {
      const cdkOptions = this.cdkOptions();
      if (cdkOptions.length && cdkOptions.length > 0) {
        cdkOptions[0].focus();
      }
    });
  }

  writeValue(value: V): void {
    this.value = value || [];

    this.formControl.setValue(
      this.listOptions().filter(option => {
        if (this.multiSelect()) {
          return (this.value as Array<V>).includes(option.value);
        } else {
          return this.value === option.value;
        }
      }),
    );
  }

  removeSelectedOption(value: SelectOption<T, V>, event: MouseEvent) {
    EventUtils.preventBubble(event);

    const currentValue = this.formControl.value || [];
    this.formControl.setValue(currentValue.filter(option => option.value !== value.value));
  }

  registerOnChange(fn: (_: V | V[]) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  public selectAll() {
    const selectedValues: SelectOption<T, V>[] = [];
    const existingValues = this.formControl.value || [];
    this.cdkOptions().forEach(option => {
      if (!option.disabled) {
        option.select();
        if (!existingValues.includes(option.value)) {
          selectedValues.push(option.value);
        }
      }
    });
    this.formControl.setValue([...existingValues, ...selectedValues]);
    if (this.clearSearchOnSelect()) {
      this.search.set('');
    }
    this.cdkOptions()[0].focus();
  }

  public valueChange() {
    if (!this.multiSelect()) {
      this.cdkMenuTrigger()?.close();
    }
  }

  public dropdownClosed() {
    this.search.set('');
  }

  public handleSeachKeyPress(event: KeyboardEvent) {
    if (
      event.key === 'Tab' ||
      event.key === 'ArrowDown' ||
      event.key === 'ArrowUp' ||
      event.key === 'Enter'
    ) {
      event.preventDefault();
      const availableOptions = this.cdkOptions().filter(o => !o.disabled);

      if (availableOptions.length === 0) {
        return;
      }

      if (event.key === 'Enter') {
        this.selectAll();
        return;
      }

      setTimeout(() => {
        availableOptions[0]?.focus();
      }, 150);
    }
  }

  get errorMessages() {
    const errors = this.ngControl.control?.errors;
    const errorMessages = [] as string[];

    if (!errors) {
      return errorMessages;
    } else {
      Object.keys(errors).forEach(key => {
        const error = 'There seems to be an error with this field.';

        const customError = this.customErrorMessages()[key];

        if (customError) {
          errorMessages.push(customError);
          return;
        }

        switch (key) {
          case 'required': {
            errorMessages.push('Please select an item.');
            break;
          }
          default: {
            errorMessages.push(error);
          }
        }
      });
    }

    return errorMessages;
  }
}
