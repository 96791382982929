import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, TemplateRef, computed, inject, input, output } from '@angular/core';

import { DialogConfig } from '../../models';
import { DialogFooterComponent } from '../footer/footer.component';
import { DialogHeaderComponent } from '../header/header.component';

@Component({
  selector: 'mdw-dialog',
  standalone: true,
  imports: [CommonModule, DialogHeaderComponent, DialogFooterComponent],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
})
export class DialogComponent<T = boolean> {
  public dialogRef = inject(DialogRef<T>);
  // Passed in Data
  public _data = inject<DialogConfig>(DIALOG_DATA);

  public mainTitle = input<string>();
  public customButtonHandlers = input<boolean>(false);
  public icon = input<string | TemplateRef<HTMLElement>>();
  public primaryButtonDisabled = input(false);
  public secondaryButtonDisabled = input(false);
  public tertiaryButtonDisabled = input(false);
  public dangerButtonDisabled = input(false);
  public rightHeaderConent = input<TemplateRef<HTMLElement>>();
  public showCloseButton = input<boolean>(false);

  public primaryButtonClicked = output<void>();
  public secondaryButtonClicked = output<void>();
  public tertiaryButtonClicked = output<void>();
  public dangerButtonClicked = output<void>();

  public showHeader = computed(() => {
    return this.mainTitle() || this.data.title || this.data.allowDismiss || this.data.isBAM;
  });

  public data = {
    primaryButton: this._data?.dangerButton ? undefined : 'OK',
    secondaryButton: 'Cancel',
    allowDismiss: false,
    showSecondaryButton: true,
    ...this._data,
  };

  public complete(status: boolean) {
    this.dialogRef.close(status as T);
  }
}
