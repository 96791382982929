<div class="vflex-300">
  <h2>App Elements</h2>
  <p>
    This documents a set of components and stylings to aide in the construction of a meadow-based
    web app.
  </p>
  <h3>Aside</h3>
  <div class="surface-muted pad-200">
    <code>import &#123; AsideComponent &#125; from '&#64;spaces-ui/meadow/components/aside';</code>
  </div>
  <p>
    The aside component is a container for content that is not the primary focus of the page. It can
    be collapsed or floated to the side of the page.
  </p>
  <p>
    The component has opinionated stylings managing the collapsed and floating state but allows
    configuration to adjust some variables on when these states get triggered
  </p>
  <div class="surface-muted pad-300 vflex-200">
    <b>properties:</b>
    <ul>
      <li>
        <b>isCollapsed</b>
        : boolean - whether the aside is collapsed or not. Default is based on viewport width
      </li>
      <li>
        <b>isFloating</b>
        : boolean - whether the aside is floating or not. Default is based on viewport width
      </li>
      <li>
        <b>width</b>
        : number - the width of the aside when expanded. Default is 311
      </li>
      <li>
        <b>autoCollapse</b>
        : number - the width of the viewport when the aside should collapse. Default is 1024
      </li>
      <li>
        <b>anchor</b>
        : 'left'|'right' - the side of the viewport the aside should anchor to. Default is 'left'
      </li>
    </ul>
  </div>
  <p>
    In order for the sidebar to behave correctly when floating, you need to add the class
    <code>mdw-aside-trigger</code>
    to the button that will toggle the sidebar. This will allow the sidebar to be toggled when the
    button is clicked.
  </p>
  <p>
    Also, in order for the floating sidebar to appear correctly, you need to add the class
    <code>relative</code>
    to the parent container, or make sure the parent container has a position of relative.
  </p>

  <ng-template #aside data-sample="aside">
    <div class="hflex-200 relative" style="height: 600px">
      <aside
        class="pad-300"
        [(isCollapsed)]="isCollapsed"
        [(isFloating)]="isFloating"
        [autoCollapse]="1360"
      >
        Sidebar content goes here
      </aside>
      <div class="hflex-000 fill-flex">
        <div class="fill-flex pad-400 surface-flat vflex-200">
          Main content goes here.
          <div class="hflex-300">
            <button
              class="mdw-aside-trigger mdw-button mdw-primary"
              (click)="isCollapsed.set(!isCollapsed())"
            >
              Toggle Sidebar
            </button>
            <button
              class="mdw-aside-trigger mdw-button mdw-primary"
              (click)="isFloating.set(!isFloating())"
            >
              Toggle isFloating
            </button>
          </div>
          <div class="hflex-300">
            <button
              class="mdw-aside-trigger mdw-button mdw-primary"
              (click)="isSecondaryCollapsed.set(!isSecondaryCollapsed())"
            >
              Toggle Secondary Sidebar
            </button>
            <button
              class="mdw-aside-trigger mdw-button mdw-primary"
              (click)="isSecondaryFloating.set(!isSecondaryFloating())"
            >
              Toggle Secondary isFloating
            </button>
          </div>
        </div>
        <aside
          pinnedSurfaceStyle="flat-inverse"
          class="pad-300"
          [(isCollapsed)]="isSecondaryCollapsed"
          [(isFloating)]="isSecondaryFloating"
          [autoCollapse]="1200"
          anchor="right"
        >
          Secondary Content Goes Here
        </aside>
      </div>
    </div>
  </ng-template>
  <app-sample [sampleContent]="aside" sampleName="aside" [stackVertical]="true"></app-sample>

  <div class="surface-muted pad-300">
    <p>
      Optionally, you can have a placeholder sidebar that will be shown when the sidebar is
      collapsed. This would be fully managed outside of the aside component and no specific classes
      are needed.
    </p>
    <p>
      An example of this behavior is the sidebar for thie documentation site. When the sidebar is
      collapsed, a button is shown that will expand the sidebar. Below is the code that would be
      needed to support this.
    </p>
    <pre>
      <code>
&lt;aside [(isCollapsed)]="isCollapsed" [(isFloating)]="isFloating"&gt;
  &lt;app-secondary-sidebar&gt;&lt;/app-secondary-sidebar&gt;
&lt;/aside&gt;
&#64;if (isCollapsed || isFloating) &#123;
  &lt;div class="vpad-200"&gt;
    &lt;button
      class="mdw-button mdw-tertiary mdw-aside-trigger"
      (click)="toggleSidebar()"
      [icon]="icons.Sidebar_Duotone"
    &gt;&lt;/button&gt;
  &lt;/div&gt;
&#125;
      </code>
    </pre>
  </div>
</div>
