import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonComponent } from '@spaces-ui/meadow/components/button';
import { SelectComponent } from '@spaces-ui/meadow/components/select';

import { SampleComponent } from '../../components';

@Component({
  selector: 'app-selects',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SampleComponent, SelectComponent, ButtonComponent],
  templateUrl: './selects.component.html',
  styleUrl: './selects.component.scss',
})
export class SelectsComponent {
  public viewForm: FormGroup = new FormGroup({
    state: new FormControl('', [Validators.required]),
    states: new FormControl([], [Validators.required]),
    richStateAbbreviation: new FormControl('', [Validators.required]),
    richState: new FormControl('', [Validators.required]),
    templateStateAbbreviation: new FormControl('', [Validators.required]),
  });
  public basicOptions = ['CA', 'NC', 'WA', 'MD', 'NY', 'TX', 'FL', 'GA', 'OH', 'PA'];

  public objectOptions = [
    // list of states with their abbreviations, population, capital, and statehood date
    {
      name: 'California',
      abbreviation: 'CA',
      population: 39538223,
      capital: 'Sacramento',
      statehoodDate: '1850-09-09',
    },
    {
      name: 'North Carolina',
      abbreviation: 'NC',
      population: 10611862,
      capital: 'Raleigh',
      statehoodDate: '1789-11-21',
    },
    {
      name: 'Washington',
      abbreviation: 'WA',
      population: 7693612,
      capital: 'Olympia',
      statehoodDate: '1889-11-11',
    },
    {
      name: 'Maryland',
      abbreviation: 'MD',
      population: 6083116,
      capital: 'Annapolis',
      statehoodDate: '1788-04-28',
    },
    {
      name: 'New York',
      abbreviation: 'NY',
      population: 20201249,
      capital: 'Albany',
      statehoodDate: '1788-07-26',
    },
    {
      name: 'Texas',
      abbreviation: 'TX',
      population: 29145505,
      capital: 'Austin',
      statehoodDate: '1845-12-29',
    },
    {
      name: 'Florida',
      abbreviation: 'FL',
      population: 21538187,
      capital: 'Tallahassee',
      statehoodDate: '1845-03-03',
    },
    {
      name: 'Georgia',
      abbreviation: 'GA',
      population: 10711908,
      capital: 'Atlanta',
      statehoodDate: '1788-01-02',
    },
    {
      name: 'Ohio',
      abbreviation: 'OH',
      population: 11799448,
      capital: 'Columbus',
      statehoodDate: '1803-03-01',
    },
    {
      name: 'Pennsylvania',
      abbreviation: 'PA',
      population: 13002700,
      capital: 'Harrisburg',
      statehoodDate: '1787-12-12',
    },
    // Add more states here
    {
      name: 'Alabama',
      abbreviation: 'AL',
      population: 5024279,
      capital: 'Montgomery',
      statehoodDate: '1819-12-14',
    },
    {
      name: 'Alaska',
      abbreviation: 'AK',
      population: 731545,
      capital: 'Juneau',
      statehoodDate: '1959-01-03',
    },
    {
      name: 'Arizona',
      abbreviation: 'AZ',
      population: 7151502,
      capital: 'Phoenix',
      statehoodDate: '1912-02-14',
    },
    {
      name: 'Arkansas',
      abbreviation: 'AR',
      population: 3011524,
      capital: 'Little Rock',
      statehoodDate: '1836-06-15',
    },
    {
      name: 'Colorado',
      abbreviation: 'CO',
      population: 5773714,
      capital: 'Denver',
      statehoodDate: '1876-08-01',
    },
    {
      name: 'Connecticut',
      abbreviation: 'CT',
      population: 3605944,
      capital: 'Hartford',
      statehoodDate: '1788-01-09',
    },
    {
      name: 'Delaware',
      abbreviation: 'DE',
      population: 989948,
      capital: 'Dover',
      statehoodDate: '1787-12-07',
    },
    {
      name: 'Hawaii',
      abbreviation: 'HI',
      population: 1455271,
      capital: 'Honolulu',
      statehoodDate: '1959-08-21',
    },
    {
      name: 'Idaho',
      abbreviation: 'ID',
      population: 1839106,
      capital: 'Boise',
      statehoodDate: '1890-07-03',
    },
    {
      name: 'Illinois',
      abbreviation: 'IL',
      population: 12812508,
      capital: 'Springfield',
      statehoodDate: '1818-12-03',
    },
    {
      name: 'Indiana',
      abbreviation: 'IN',
      population: 6785528,
      capital: 'Indianapolis',
      statehoodDate: '1816-12-11',
    },
    {
      name: 'Iowa',
      abbreviation: 'IA',
      population: 3190369,
      capital: 'Des Moines',
      statehoodDate: '1846-12-28',
    },
    {
      name: 'Kansas',
      abbreviation: 'KS',
      population: 2937880,
      capital: 'Topeka',
      statehoodDate: '1861-01-29',
    },
    {
      name: 'Kentucky',
      abbreviation: 'KY',
      population: 4505836,
      capital: 'Frankfort',
      statehoodDate: '1792-06-01',
    },
    {
      name: 'Louisiana',
      abbreviation: 'LA',
      population: 4657757,
      capital: 'Baton Rouge',
      statehoodDate: '1812-04-30',
    },
    {
      name: 'Maine',
      abbreviation: 'ME',
      population: 1362359,
      capital: 'Augusta',
      statehoodDate: '1820-03-15',
    },
    {
      name: 'Massachusetts',
      abbreviation: 'MA',
      population: 7029917,
      capital: 'Boston',
      statehoodDate: '1788-02-06',
    },
    {
      name: 'Michigan',
      abbreviation: 'MI',
      population: 10077331,
      capital: 'Lansing',
      statehoodDate: '1837-01-26',
    },
    {
      name: 'Minnesota',
      abbreviation: 'MN',
      population: 5706494,
      capital: 'Saint Paul',
      statehoodDate: '1858-05-11',
    },
    {
      name: 'Mississippi',
      abbreviation: 'MS',
      population: 2961279,
      capital: 'Jackson',
      statehoodDate: '1817-12-10',
    },
    {
      name: 'Missouri',
      abbreviation: 'MO',
      population: 6154913,
      capital: 'Jefferson City',
      statehoodDate: '1821-08-10',
    },
    {
      name: 'Montana',
      abbreviation: 'MT',
      population: 1084225,
      capital: 'Helena',
      statehoodDate: '1889-11-08',
    },
    {
      name: 'Nebraska',
      abbreviation: 'NE',
      population: 1961504,
      capital: 'Lincoln',
      statehoodDate: '1867-03-01',
    },
    {
      name: 'Nevada',
      abbreviation: 'NV',
      population: 3104614,
      capital: 'Carson City',
      statehoodDate: '1864-10-31',
    },
    {
      name: 'New Hampshire',
      abbreviation: 'NH',
      population: 1377529,
      capital: 'Concord',
      statehoodDate: '1788-06-21',
    },
    {
      name: 'New Jersey',
      abbreviation: 'NJ',
      population: 9288994,
      capital: 'Trenton',
      statehoodDate: '1787-12-18',
    },
    {
      name: 'New Mexico',
      abbreviation: 'NM',
      population: 2117522,
      capital: 'Santa Fe',
      statehoodDate: '1912-01-06',
    },
    {
      name: 'North Dakota',
      abbreviation: 'ND',
      population: 779094,
      capital: 'Bismarck',
      statehoodDate: '1889-11-02',
    },
    {
      name: 'Oklahoma',
      abbreviation: 'OK',
      population: 3959353,
      capital: 'Oklahoma City',
      statehoodDate: '1907-11-16',
    },
    {
      name: 'Oregon',
      abbreviation: 'OR',
      population: 4237256,
      capital: 'Salem',
      statehoodDate: '1859-02-14',
    },
    {
      name: 'Rhode Island',
      abbreviation: 'RI',
      population: 1097379,
      capital: 'Providence',
      statehoodDate: '1790-05-29',
    },
    {
      name: 'South Carolina',
      abbreviation: 'SC',
      population: 5118425,
      capital: 'Columbia',
      statehoodDate: '1788-05-23',
    },
    {
      name: 'South Dakota',
      abbreviation: 'SD',
      population: 886667,
      capital: 'Pierre',
      statehoodDate: '1889-11-02',
    },
    {
      name: 'Tennessee',
      abbreviation: 'TN',
      population: 6910840,
      capital: 'Nashville',
      statehoodDate: '1796-06-01',
    },
    {
      name: 'Utah',
      abbreviation: 'UT',
      population: 3282115,
      capital: 'Salt Lake City',
      statehoodDate: '1896-01-04',
    },
    {
      name: 'Vermont',
      abbreviation: 'VT',
      population: 643077,
      capital: 'Montpelier',
      statehoodDate: '1791-03-04',
    },
    {
      name: 'Virginia',
      abbreviation: 'VA',
      population: 8626207,
      capital: 'Richmond',
      statehoodDate: '1788-06-25',
    },
    {
      name: 'West Virginia',
      abbreviation: 'WV',
      population: 1792147,
      capital: 'Charleston',
      statehoodDate: '1863-06-20',
    },
    {
      name: 'Wisconsin',
      abbreviation: 'WI',
      population: 5897473,
      capital: 'Madison',
      statehoodDate: '1848-05-29',
    },
    {
      name: 'Wyoming',
      abbreviation: 'WY',
      population: 576851,
      capital: 'Cheyenne',
      statehoodDate: '1890-07-10',
    },
  ];

  public selectState(value: string) {
    this.viewForm.controls['state'].setValue(value);
  }

  public selectStates(value: string[]) {
    this.viewForm.controls['states'].setValue(value);
  }
}
