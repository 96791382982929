<div class="vflex-200">
  <h2>Tooltips</h2>
  <div class="surface-muted pad-200">
    <code>import &#123; MatTooltipModule &#125; from '&#64;angular/material/tooltip';</code>
  </div>

  <p>
    The tooltip directive adds a pseudo block ::before the element it is attributed to. Currently it
    only supports plain text.
  </p>
  <p>Supported positions are 'top', 'bottom'</p>

  <ng-template #tooltips data-sample="tooltips">
    <div class="hflex-400 flex-wrap">
      <div class="mdw-badge mdw-success" matTooltip="This is a default tooltip">
        Just some element
      </div>
      <div
        class="mdw-badge mdw-info"
        matTooltip="This is a below tooltip"
        matTooltipPosition="below"
      >
        Another element
      </div>
    </div>
  </ng-template>
  <app-sample [sampleContent]="tooltips" sampleName="tooltips"></app-sample>
</div>
