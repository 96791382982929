<div class="vflex-300">
  <h2>Notifications</h2>
  <div class="surface-muted pad-200">
    <code>
      import &#123; MdwNotificationsService &#125; from
      '&#64;spaces-ui/meadow/components/notifications';
    </code>
  </div>

  <h3>Success Notification</h3>
  <ng-template #successExample data-sample="successExample">
    <section class="vflex-300 flex-no-stretch">
      <button class="mdw-button mdw-primary" (click)="showSuccess()">
        Trigger success notification
      </button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="successExample" sampleName="successExample"></app-sample>

  <h3>Error Notification</h3>
  <ng-template #errorExample data-sample="errorExample">
    <section class="vflex-300 flex-no-stretch">
      <button class="mdw-button mdw-danger" (click)="showError()">
        Trigger error notification
      </button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="errorExample" sampleName="errorExample"></app-sample>
</div>
