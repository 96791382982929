@if (!routerLink() && !href()) {
  <ng-container *ngTemplateOutlet="listItemContent"></ng-container>
} @else {
  @if (routerLink()) {
    <a class="list-item-link" [routerLink]="routerLink()" [routerLinkActive]="routerLinkActive()">
      <ng-container *ngTemplateOutlet="listItemContent"></ng-container>
    </a>
  } @else if (href()) {
    <a class="list-item-link" [href]="href()">
      <ng-container *ngTemplateOutlet="listItemContent"></ng-container>
    </a>
  }
}

<ng-template #listItemContent>
  <div class="interactive-overlay">
    @if (useCheckbox()) {
      <div class="check-box"></div>
    } @else {
      <div class="isSelected">
        <mdw-icon
          [icon]="icons.Check_Small"
          [class]="
            isDisabled ? 'disabled' : 'color-preset-' + iconPresetColorNumber() || iconColorClass
          "
        ></mdw-icon>
      </div>
    }
    @if (icon()) {
      <mdw-icon
        [icon]="icon()"
        class="secondary"
        [class.small-icon]="iconSize() === 'small'"
        [class]="
          isDisabled ? 'disabled' : 'color-preset-' + iconPresetColorNumber() || iconColorClass
        "
      ></mdw-icon>
    }
    <span class="text-content">
      <ng-content></ng-content>
    </span>
    @if (badge()) {
      <div class="mdw-notification-badge">{{ badge() }}</div>
    }
    @if (includeToggle()) {
      <input type="checkbox" toggle [checked]="toggleChecked()" />
    }
  </div>
</ng-template>
