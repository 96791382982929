import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';

import { ButtonComponent } from '@spaces-ui/meadow/components/button';
import { Sidebar_Outline } from '@spaces-ui/meadow/icons/svgs';

@Component({
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  selector: 'app-top-sidebar',
  templateUrl: './app-top-sidebar.component.html',
  styleUrl: './app-top-sidebar.component.scss',
})
export class AppTopSidebarComponent {
  @Output()
  public toggleTheme = new EventEmitter<void>();

  public icons = { Sidebar_Outline };
}
