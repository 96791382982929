import { Dialog } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, inject } from '@angular/core';
import { Observable, firstValueFrom, noop } from 'rxjs';

import { DialogComponent } from '../components';
import { DialogConfig } from '../models';
import { DialogOptions } from '../models/dialog-options';

@Injectable({ providedIn: 'root' })
export class DialogService {
  private cdkDialogService = inject(Dialog);

  public alert(message: string, title?: string, buttonText?: string) {
    this.confirm({
      title,
      message,
      primaryButton: buttonText || 'OK',
      showSecondaryButton: false,
      allowDismiss: false,
    });
  }

  public alertDanger(message: string, title?: string, buttonText?: string) {
    this.confirm({
      title,
      message,
      dangerButton: buttonText || 'OK',
      showSecondaryButton: false,
      allowDismiss: false,
    });
  }

  public async confirm(options: DialogConfig, onConfirm?: () => void, onCancel?: () => void) {
    const dialogRef = this.cdkDialogService.open(DialogComponent, {
      width: '472px',
      data: {
        ...options,
      },
      autoFocus: false,
    });

    const result = await firstValueFrom(dialogRef.closed);

    if (result) {
      onConfirm ? onConfirm() : noop();
    } else {
      onCancel ? onCancel() : noop();
    }
  }

  public async open<T, D>(
    dialogComponent: ComponentType<T>,
    options: DialogOptions,
    onConfirm?: (result: D) => void,
    onCancel?: () => void,
    onComplete?: () => void,
  ) {
    const dialogRef = this.cdkDialogService.open(dialogComponent, {
      panelClass: [],
      width: options?.width || '568px',
      data: options?.data,
      height: options?.height,
      maxHeight: options?.maxHeight,
      minHeight: options?.minHeight,
      maxWidth: options?.maxWidth,
      minWidth: options?.minWidth,
      autoFocus: false,
    });

    const result = await firstValueFrom(dialogRef.closed as Observable<D>);

    if (result && onConfirm) {
      onConfirm(result);
    } else if (onCancel) {
      onCancel();
    }

    if (onComplete) {
      onComplete();
    }

    return dialogRef;
  }

  public async openBAM<T>(dialogComponent: ComponentType<T>, _data?: unknown) {
    const data = _data ? { ..._data, isBAM: true } : { isBAM: true };

    const dialogRef = this.cdkDialogService.open(dialogComponent, {
      panelClass: ['bam-dialog'],
      data,
    });

    return dialogRef;
  }
}
