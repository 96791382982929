<div class="vflex-200">
  <h2>Containers</h2>
  <h3>Containers</h3>
  <div class="vflex-400">
    <ng-template #mutedSurface data-sample="mutedSurface">
      <div class="pad-800 surface-muted">This is the muted surface container.</div>
    </ng-template>
    <app-sample [sampleContent]="mutedSurface" sampleName="mutedSurface"></app-sample>
    <ng-template #flatSurface data-sample="flatSurface">
      <div class="pad-800 surface-flat">This is the flat surface container.</div>
    </ng-template>
    <app-sample [sampleContent]="flatSurface" sampleName="flatSurface"></app-sample>

    <ng-template #flatInverseSurface data-sample="flatInverseSurface">
      <div class="pad-800 surface-flat-inverse">This is the flat inverse surface container.</div>
    </ng-template>
    <app-sample [sampleContent]="flatInverseSurface" sampleName="flatInverseSurface"></app-sample>

    <ng-template #flatOutlineSurface data-sample="flatOutlineSurface">
      <div class="pad-800 surface-flat-outline">
        This is the flat outline surface container. (same as flat but without background color)
      </div>
    </ng-template>
    <app-sample [sampleContent]="flatOutlineSurface" sampleName="flatOutlineSurface"></app-sample>

    <ng-template #raisedSurface data-sample="raisedSurface">
      <div class="pad-800 surface-raised">This is the raised surface container.</div>
    </ng-template>
    <app-sample [sampleContent]="raisedSurface" sampleName="raisedSurface"></app-sample>

    <ng-template #floatingSurface data-sample="floatingSurface">
      <div class="pad-800 surface-floating">This is the floating surface container.</div>
    </ng-template>
    <app-sample [sampleContent]="floatingSurface" sampleName="floatingSurface"></app-sample>

    <ng-template #elevatedSurface data-sample="elevatedSurface">
      <div class="pad-800 surface-elevated">This is the elevated surface container.</div>
    </ng-template>
    <app-sample [sampleContent]="elevatedSurface" sampleName="elevatedSurface"></app-sample>

    <ng-template #buttonContainer data-sample="buttonContainer">
      <button type="button" class="pad-800 actionable-surface">
        This is the button actionable container.
      </button>
    </ng-template>
    <app-sample [sampleContent]="buttonContainer" sampleName="buttonContainer"></app-sample>

    <ng-template #linkContainer data-sample="linkContainer">
      <a href="/containers" class="pad-800 actionable-surface">
        This is the link actionable container.
      </a>
    </ng-template>
    <app-sample [sampleContent]="linkContainer" sampleName="linkContainer"></app-sample>
  </div>

  <h3>Backgrounds</h3>
  <p>
    Meadow has predefined styled background classes that can be used on any container element (div,
    span, mdw-badge, etc...)
  </p>
  <ng-template #backgroundClasses data-sample="backgroundClasses">
    <div>
      <div class="pad-400 mdw-success">This is a success container</div>
      <div class="pad-400 mdw-info">This is an info container</div>
      <div class="pad-400 mdw-warning">This is a warning container</div>
      <div class="pad-400 mdw-negative">This is a negative container</div>
      <div class="pad-400 mdw-danger">This is a danger container</div>
    </div>
  </ng-template>
  <app-sample [sampleContent]="backgroundClasses" sampleName="backgroundClasses"></app-sample>
</div>
