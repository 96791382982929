export const FileTypes_Zip = `<svg title="FileTypes_Zip" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
<path d="M3 3C3 1.34315 4.34315 0 6 0H15L21 6V21C21 22.6569 19.6569 24 18 24H6C4.34315 24 3 22.6569 3 21V3Z" fill="#D9D9D5"/>
<rect x="7" y="3" width="2" height="2" fill="#6B6861"/>
<rect x="9" y="5" width="2" height="2" fill="#6B6861"/>
<rect x="7" y="7" width="2" height="2" fill="#6B6861"/>
<rect x="9" y="9" width="2" height="2" fill="#6B6861"/>
<rect x="7" y="11" width="2" height="2" fill="#6B6861"/>
<rect x="9" y="13" width="2" height="2" fill="#6B6861"/>
<rect x="7" y="15" width="2" height="2" fill="#6B6861"/>
<rect x="9" y="17" width="2" height="2" fill="#6B6861"/>
<path opacity="0.4" d="M15 0L21 6H17C15.8954 6 15 5.10457 15 4V0Z" fill="#6B6861"/>
</svg>`;