<div class="vflex-300">
  <h2>List Items</h2>
  <div class="surface-muted pad-200">
    <code>
      import &#123; ListItemComponent &#125; from '&#64;spaces-ui/meadow/components/list-item';
    </code>
  </div>
  <h3>List</h3>
  <b>Nav List Items</b>
  <ng-template #listItems data-sample="listItems">
    <nav>
      <ul>
        <li
          [class.selected]="selectedItem === 1"
          [class.accented]="accentedItem === 1"
          (click)="selectedItem = 1"
        >
          Item 1
        </li>
        <li
          [class.selected]="selectedItem === 2"
          [class.accented]="accentedItem === 2"
          (click)="selectedItem = 2"
        >
          Item 2
        </li>
        <li
          [class.selected]="selectedItem === 3"
          [class.accented]="accentedItem === 3"
          (click)="selectedItem = 3"
        >
          Item 3
        </li>
      </ul>
    </nav>
  </ng-template>
  <app-sample [sampleContent]="listItems" sampleName="listItems"></app-sample>
  <b>With Icons</b>
  <p>
    List items allow for icons to be added to the left of the text. The icon is a reference to a
    meadow icon class.
  </p>
  <p>
    The icon can be colored by passing in the
    <code>color</code>
    token as a string ('B200', 'R100', etc.). You can also provide a
    <code>iconPresetColorNumber</code>
    , which is a number between 1 and 24, and will be converted to the corresponding pre-defined
    colors.
  </p>
  <ng-template #listItemsWithIcons data-sample="listItemsWithIcons">
    <div class="vflex-300">
      <nav>
        <ul>
          <li
            [icon]="icons.Channel"
            [class.selected]="selectedItem === 1"
            (click)="selectedItem = 1"
          >
            Item 1
          </li>
          <li
            [icon]="icons.Channel"
            [class.selected]="selectedItem === 2"
            (click)="selectedItem = 2"
          >
            Item 2
          </li>
          <li
            [icon]="icons.Channel"
            badge="23"
            [class.selected]="selectedItem === 3"
            (click)="selectedItem = 3"
          >
            Item 3
          </li>
        </ul>
      </nav>
      <label for="">With color presets</label>
      <nav>
        <ul>
          <li
            [icon]="icons.Channel"
            [class.selected]="selectedItem === 1"
            (click)="selectedItem = 1"
            [iconPresetColorNumber]="4"
          >
            Item 1
          </li>
          <li
            [icon]="icons.Channel"
            [class.selected]="selectedItem === 2"
            (click)="selectedItem = 2"
            [iconPresetColorNumber]="12"
          >
            Item 2
          </li>
          <li
            [icon]="icons.Channel"
            badge="23"
            [class.selected]="selectedItem === 3"
            (click)="selectedItem = 3"
            [iconPresetColorNumber]="21"
          >
            Item 3
          </li>
        </ul>
      </nav>
    </div>
  </ng-template>
  <app-sample [sampleContent]="listItemsWithIcons" sampleName="listItemsWithIcons"></app-sample>

  <h3>Emphasized Nav List</h3>
  <p>This list is primarily used for feature level navigation, rather than to entities.</p>
  <ng-template #emphasizedNavItems data-sample="emphasizedNavItems">
    <nav class="emphasized">
      <ul>
        <li routerLink="/colors" routerLinkActive="selected" class="selected">Item 1</li>
        <li routerLink="/layout" routerLinkActive="selected">Item 2</li>
        <li routerLink="/container" routerLinkActive="selected">Item 3</li>
      </ul>
    </nav>
  </ng-template>
  <app-sample [sampleContent]="emphasizedNavItems" sampleName="emphasizedNavItems"></app-sample>

  <h3>Menu List</h3>
  <p>
    Note!
    <code>isDisabled</code>
    is available on any li and will style the text and icon accordingly. This does not, however,
    control any methods on the listitem (ex: click). The implementation is responsible to check for
    the li's state during the event execution
  </p>
  <p>This list is used for dropdown, flyout, context menus.</p>
  <ng-template #menuItems data-sample="menuItems">
    <menu>
      <ul>
        <li (click)="alert('Item 1 clicked')" [icon]="icons.Logout">Logout</li>
        <li (click)="alert('Item 2 clicked')" [icon]="icons.Mark">Create Space</li>
        <li (click)="alert('Item 3 clicked')" [icon]="icons.Channel" iconColor="B200">
          Create Channel
        </li>
        <hr />
        <li
          [disabled]="isDisabled"
          (click)="!isDisabled && alert('Item 3 clicked')"
          [icon]="icons.Channel"
          iconColor="B200"
        >
          Create Channel
        </li>
      </ul>
    </menu>
  </ng-template>
  <app-sample [sampleContent]="menuItems" sampleName="menuItems"></app-sample>
</div>
