<ng-template #simpleFormDialog data-sample="simpleFormDialog">
  <mdw-dialog mainTitle="Modal Dialog">
    <section class="vflex-200">
      <p class="secondary">
        A modal appears in front of the main content and must be dismissed before the user can
        interact with the main content.
      </p>
      <form>
        <div class="vflex-200">
          <div>
            <input type="text" class="full-width" label="First Name" name="firstName" required />
          </div>
          <div>
            <input type="text" class="full-width" label="Last Name" name="lastName" required />
          </div>
        </div>
      </form>
    </section>
  </mdw-dialog>
</ng-template>
<ng-container *ngTemplateOutlet="simpleFormDialog"></ng-container>
