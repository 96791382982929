import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, computed, effect, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ButtonComponent } from '@spaces-ui/meadow/components/button';
import { InputDirective } from '@spaces-ui/meadow/components/input';
import { ListItemComponent } from '@spaces-ui/meadow/components/list-item';
import { SelectComponent } from '@spaces-ui/meadow/components/select';
import {
  Channel,
  Mark,
  Logout,
  DirectMessage_Fill,
  Home_Fill,
  Overflow,
  ChannelBrowse,
  ChannelNew,
  SectionNew_Fill,
  DirectMessageNew_Fill,
} from '@spaces-ui/meadow/icons/svgs';

import { SampleComponent } from '../../components';

@Component({
  selector: 'app-menus',
  standalone: true,
  imports: [
    CommonModule,
    SampleComponent,
    ButtonComponent,
    ListItemComponent,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItem,
    SelectComponent,
    FormsModule,
    InputDirective,
  ],
  templateUrl: './menus.component.html',
  styleUrl: './menus.component.scss',
})
export class MenusComponent {
  public icons = {
    Channel,
    Logout,
    Mark,
    DirectMessage_Fill,
    Home_Fill,
    Overflow,
    ChannelNew,
    ChannelBrowse,
    DirectMessageNew_Fill,
    SectionNew_Fill,
  };

  public originX = signal<'start' | 'end' | 'center'>('start');
  public originY = signal<'top' | 'bottom' | 'center'>('bottom');
  public overlayX = signal<'start' | 'end' | 'center'>('start');
  public overlayY = signal<'top' | 'bottom' | 'center'>('top');
  public offsetX = signal<number>(0);
  public offsetY = signal<number>(0);
  public showPlacementTrigger = signal<boolean>(true);

  public connectedPosition = computed(() => {
    return {
      originX: this.originX(),
      originY: this.originY(),
      overlayX: this.overlayX(),
      overlayY: this.overlayY(),
      offsetX: this.offsetX(),
      offsetY: this.offsetY(),
    };
  });

  public bindingsString = computed(() => {
    const positions = this.connectedPosition();
    const positionString = JSON.stringify(positions);
    const positionWithSingleQuotes = positionString.replace(/"/g, "'");

    return `<button [cdkMenuTriggerFor]="menu" [cdkMenuPosition]="[${positionWithSingleQuotes}]" class="mdw-button mdw-primary">Flyout Trigger</button>`;
  });

  public constructor() {
    effect(() => {
      setTimeout(() => this.showPlacementTrigger.set(false));
      setTimeout(() => this.showPlacementTrigger.set(true), 200);
    });
  }

  public alert(alertValue: string) {
    alert(alertValue);
  }

  public positions = [
    new ConnectionPositionPair(
      { originX: 'center', originY: 'top' },
      { overlayX: 'center', overlayY: 'bottom' },
    ),
  ];
}
