import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AvatarComponent } from '@spaces-ui/meadow/components/avatar';

import { SampleComponent } from '../../components';

@Component({
  selector: 'app-avatars',
  standalone: true,
  imports: [CommonModule, SampleComponent, AvatarComponent, MatTooltipModule],
  templateUrl: './avatars.component.html',
  styleUrl: './avatars.component.scss',
})
export class AvatarsComponent {}
