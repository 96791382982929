import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { ButtonComponent } from '@spaces-ui/meadow/components/button';
import { ListItemComponent } from '@spaces-ui/meadow/components/list-item';
import { IconComponent } from '@spaces-ui/meadow/icons';
import { Home_Fill } from '@spaces-ui/meadow/icons/svgs';

import { SampleComponent } from '../../components';

@Component({
  selector: 'app-badges',
  standalone: true,
  imports: [CommonModule, SampleComponent, ButtonComponent, ListItemComponent, IconComponent],
  templateUrl: './badges.component.html',
  styleUrl: './badges.component.scss',
})
export class BadgesComponent {
  public icons = { Home_Fill };
}
