<form [formGroup]="formGroup">
  <div class="vflex-200">
    <h2>Inputs</h2>
    <p>
      Inputs are mostly just styled, and do not provide additional functionality beyond the standard
      HTML implementations.
    </p>
    <p>
      Meadow has a class,
      <code>full-width</code>
      , for inputs that can be used to make the input full width (100% of the parent). Alternatively
      you can accomplish the same result by adding the
      <code>vflex-000</code>
      code. Just make sure to not use any other gap classes, since the input peer elements have
      predefined layout rules applied to them.
    </p>
    <h3>Basic Inputs</h3>
    <ng-template #basicInputs data-sample="basicInputs">
      <div class="vflex-200">
        <div class="vflex-200">
          <input type="text" placeholder="Text Input" />
          <input class="error" type="text" placeholder="Text Input With Error" />
          <input readonly type="text" placeholder="Readonly Text Input" />
          <input disabled type="text" placeholder="Disabled Text Input" />
          <hr />
          <input type="password" placeholder="Password Input" />
          <input type="search" placeholder="Search Something" />
        </div>
      </div>
    </ng-template>
    <app-sample [sampleContent]="basicInputs" sampleName="basicInputs"></app-sample>
    <h3>Input Recipes</h3>
    <ng-template #inputRecipes data-sample="inputRecipes">
      <div class="vflex-200">
        <div class="vflex-200">
          <div class="vflex-000">
            <label for="">Default Label</label>
            <input type="text" placeholder="Default Text Input" />
          </div>
          <div class="vflex-000">
            <label for="">
              Label with Description
              <mdw-icon class="small-icon" [icon]="icons.Home_Duotone"></mdw-icon>
            </label>
            <div class="input-description">Description</div>
            <input class="error" type="text" placeholder="Default Text Input" />
            <div class="input-error">
              <mdw-icon class="small-icon" [icon]="icons.Home_Duotone"></mdw-icon>
              Some error message
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <app-sample [sampleContent]="inputRecipes" sampleName="inputRecipes"></app-sample>

    <h3>Input Directive</h3>
    <div class="surface-muted pad-200">
      <code>
        import &#123; InputDirective &#125; from '&#64;spaces-ui/meadow/components/input';
      </code>
    </div>
    <p>
      This is a directive that combines the label, description, and error message into a single
      component, using the standard input element.
    </p>

    <ng-template #inputComponent data-sample="inputComponent">
      <div class="vflex-200">
        <div class="vflex-000">
          <input
            type="text"
            formControlName="input"
            placeholder="Default Text Input"
            [label]="'Some Label'"
            [description]="'Some description can go here'"
          />
        </div>
        <div class="vflex-000">
          <input
            type="text"
            formControlName="input"
            placeholder="Text Input With Custom Error Message"
            [label]="'Some Label'"
            [description]="'Some description can go here'"
            [customErrorMessages]="{ required: 'This input field needs to be filled in.' }"
          />
        </div>
      </div>
    </ng-template>
    <app-sample [sampleContent]="inputComponent" sampleName="inputComponent"></app-sample>

    <h2>Checkboxes</h2>
    <p>
      Single checkboxes are simply styled with a label. If you have multiple checkboxes, look at the
      <b>Choice List</b>
      component below.
      <code>mdw-choice-list</code>
      component that encapsulates the grouped label and error messaging.
    </p>
    <p>
      <b>Disabled Option</b>
      : in order to disable an option, add the class
      <code>disabled</code>
      to the label and use the formControl settings to disable the input element.
    </p>
    <ng-template #checkboxInputs data-sample="checkboxInputs">
      <div class="vflex-000">
        <label class="checkbox-label">
          <input type="checkbox" value="one" formControlName="checkBox1" />
          Checkbox Option 1
        </label>
        <label class="checkbox-label disabled">
          <input type="checkbox" value="two" formControlName="checkBox2" />
          Checkbox Option 2
        </label>
      </div>
    </ng-template>
    <app-sample [sampleContent]="checkboxInputs" sampleName="checkboxInputs"></app-sample>

    <h2>Choice List</h2>
    <p>
      The choice list component groupes a set op radio buttons or checkboxes with a label and a
      description. This component is a
      <b>ControlValueAccessor</b>
      component so it supports Angular Forms.
    </p>

    <div class="surface-muted pad-200">
      <code>
        import &#123; ChoiceListComponent &#125; from '&#64;spaces-ui/meadow/components/input';
      </code>
    </div>
    <h3>Single Select</h3>
    <ng-template #radioInputs data-sample="radioInputs">
      <div class="vflex-200">
        <mdw-choice-list
          [label]="'Pick your poison'"
          description="This list shows radio buttons and will only allow one selection at a time."
          [choices]="options"
          formControlName="singleSelection"
        ></mdw-choice-list>
        selection: {{ formGroup.value.singleSelection }}
      </div>
    </ng-template>
    <app-sample [sampleContent]="radioInputs" sampleName="radioInputs"></app-sample>

    <h3>Multi Select</h3>
    <ng-template #multiSelection data-sample="multiSelection">
      <div class="vflex-200">
        <mdw-choice-list
          [label]="'Pick your poisons'"
          description="This list shows checkboxes and will allow multiple selections at a time."
          formControlName="multiSelection"
          [multiSelect]="true"
          [choices]="options"
        ></mdw-choice-list>
        selection: {{ formGroup.value.multiSelection }}
      </div>
    </ng-template>
    <app-sample [sampleContent]="multiSelection" sampleName="multiSelection"></app-sample>

    <h2>Toggles</h2>
    <p>
      Toggles are styled checkboxes that are used to toggle a setting on or off. They are styled to
      look like a switch.
    </p>
    <p>
      To enable the toggle functionality, add the
      <code>toggle</code>
      attribute to the input element.
    </p>

    <p>
      To change the size of the toggle, add the
      <code>large-toggle</code>
      class to the input element.
    </p>
    <p>
      To change the color of the toggle, add the
      <code>danger-toggle</code>
      class to the input element.
    </p>
    <ng-template #toggleInputs data-sample="toggleInputs">
      <div class="vflex-300">
        <label class="toggle-label">
          <input type="checkbox" toggle formControlName="toggle1" />
          Toggle Option 1
        </label>
        <label class="toggle-label">
          <input type="checkbox" toggle formControlName="toggle2" />
          Toggle Option 2
        </label>
        <label class="toggle-label">
          <input type="checkbox" toggle formControlName="toggle3" />
          Toggle Option 3
        </label>
        <label class="toggle-label">
          <input type="checkbox" toggle formControlName="toggle4" class="danger-toggle" />
          Toggle Option 4 (Danger)
        </label>
        <label class="toggle-label">
          <input type="checkbox" toggle formControlName="toggle5" class="large-toggle" />
          Toggle Option 5 (Large)
        </label>
      </div>
    </ng-template>
    <app-sample [sampleContent]="toggleInputs" sampleName="toggleInputs"></app-sample>
    <hr class="hr-strong" />
    <h3>Form values:</h3>
    <div class="pad-300 surface-muted mdw-info">
      {{ formGroup.value | json }}
    </div>
  </div>
</form>
