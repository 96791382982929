export class ArrayUtils {
  public static uniqueBy<T extends object>(array: T[], prop: keyof T) {
    return array.filter(
      (value, index, self) =>
        self.findIndex(el => {
          // eslint-disable-next-line no-prototype-builtins
          if (el.hasOwnProperty(prop)) {
            return el[prop] === value[prop];
          }
          return false;
        }) === index,
    );
  }

  public static unique<T>(array: T[]) {
    return array.filter((value, index, self) => self.indexOf(value) === index);
  }

  public static areEqual<T>(a: T[], b: T[]) {
    if (a.length !== b.length) return false;
    return a.every(item => b.includes(item)) && b.every(item => a.includes(item));
  }
}
