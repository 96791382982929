import { CdkListbox, CdkOption } from '@angular/cdk/listbox';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ButtonComponent } from '@spaces-ui/meadow/components/button';
import { ListItemComponent } from '@spaces-ui/meadow/components/list-item';
import { Channel } from '@spaces-ui/meadow/icons/svgs';

@Component({
  selector: 'app-forms',
  standalone: true,
  imports: [
    CommonModule,
    CdkOption,
    CdkListbox,
    FormsModule,
    ListItemComponent,
    CdkMenu,
    CdkMenuTrigger,
    CdkMenuItem,
    ButtonComponent,
  ],
  templateUrl: './forms.component.html',
  styleUrl: './forms.component.scss',
})
export class FormsComponent {
  public order = 1;
  public selectedItem = undefined;

  public icons = { Channel };
}
