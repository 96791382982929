<div class="vflex-300">
  <h2>Icon Callout</h2>
  <p><span class="mdw-badge-info">Styles Only</span></p>

  <h3>Medium (default)</h3>
  <ng-template #defaultIconCallout data-sample="defaultIconCallout">
    <div class="hflex-100 flex-wrap">
      <mdw-icon class="icon-callout icon-callout-neutral" [icon]="icons.Apple_Fill"></mdw-icon>
      <mdw-icon class="icon-callout icon-callout-blue" [icon]="icons.Apple_Fill"></mdw-icon>
      <mdw-icon class="icon-callout icon-callout-green" [icon]="icons.Apple_Fill"></mdw-icon>
      <mdw-icon class="icon-callout icon-callout-purple" [icon]="icons.Apple_Fill"></mdw-icon>
      <mdw-icon class="icon-callout icon-callout-teal" [icon]="icons.Apple_Fill"></mdw-icon>
      <mdw-icon class="icon-callout icon-callout-orange" [icon]="icons.Apple_Fill"></mdw-icon>
      <mdw-icon class="icon-callout icon-callout-pink" [icon]="icons.Apple_Fill"></mdw-icon>
      <mdw-icon class="icon-callout icon-callout-red" [icon]="icons.Apple_Fill"></mdw-icon>
      <mdw-icon class="icon-callout icon-callout-yellow" [icon]="icons.Apple_Fill"></mdw-icon>
    </div>
  </ng-template>
  <app-sample [sampleContent]="defaultIconCallout" sampleName="defaultIconCallout"></app-sample>

  <h3>Large</h3>
  <ng-template #largeIconCallout data-sample="largeIconCallout">
    <div class="hflex-100 flex-wrap">
      <mdw-icon
        class="icon-callout large-callout icon-callout-neutral"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
      <mdw-icon
        class="icon-callout large-callout icon-callout-blue"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
      <mdw-icon
        class="icon-callout large-callout icon-callout-green"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
      <mdw-icon
        class="icon-callout large-callout icon-callout-purple"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
      <mdw-icon
        class="icon-callout large-callout icon-callout-teal"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
      <mdw-icon
        class="icon-callout large-callout icon-callout-orange"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
      <mdw-icon
        class="icon-callout large-callout icon-callout-pink"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
      <mdw-icon
        class="icon-callout large-callout icon-callout-red"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
      <mdw-icon
        class="icon-callout large-callout icon-callout-yellow"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
    </div>
  </ng-template>
  <app-sample [sampleContent]="largeIconCallout" sampleName="largeIconCallout"></app-sample>

  <h3>Extra Large</h3>
  <ng-template #xLargeIconCallout data-sample="xLargeIconCallout">
    <div class="hflex-100 flex-wrap">
      <mdw-icon
        class="icon-callout x-large-callout icon-callout-neutral"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
      <mdw-icon
        class="icon-callout x-large-callout icon-callout-blue"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
      <mdw-icon
        class="icon-callout x-large-callout icon-callout-green"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
      <mdw-icon
        class="icon-callout x-large-callout icon-callout-purple"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
      <mdw-icon
        class="icon-callout x-large-callout icon-callout-teal"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
      <mdw-icon
        class="icon-callout x-large-callout icon-callout-orange"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
      <mdw-icon
        class="icon-callout x-large-callout icon-callout-pink"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
      <mdw-icon
        class="icon-callout x-large-callout icon-callout-red"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
      <mdw-icon
        class="icon-callout x-large-callout icon-callout-yellow"
        [icon]="icons.Apple_Fill"
      ></mdw-icon>
    </div>
  </ng-template>
  <app-sample [sampleContent]="xLargeIconCallout" sampleName="xLargeIconCallout"></app-sample>

  <h3>Button Icon Callouts</h3>
  <ng-template #buttonIconCallout data-sample="buttonIconCallout">
    <div class="vflex-100">
      <div class="hflex-100 flex-align-items-center flex-wrap">
        <button
          class="mdw-button"
          iconCalloutColor="neutral"
          iconCalloutSize="medium"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="neutral"
          iconCalloutSize="large"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="neutral"
          iconCalloutSize="x-large"
          [icon]="icons.Apple_Fill"
        ></button>
      </div>
      <div class="hflex-100 flex-align-items-center flex-wrap">
        <button
          class="mdw-button"
          iconCalloutColor="blue"
          iconCalloutSize="medium"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="blue"
          iconCalloutSize="large"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="blue"
          iconCalloutSize="x-large"
          [icon]="icons.Apple_Fill"
        ></button>
      </div>
      <div class="hflex-100 flex-align-items-center flex-wrap">
        <button
          class="mdw-button"
          iconCalloutColor="green"
          iconCalloutSize="medium"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="green"
          iconCalloutSize="large"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="green"
          iconCalloutSize="x-large"
          [icon]="icons.Apple_Fill"
        ></button>
      </div>
      <div class="hflex-100 flex-align-items-center flex-wrap">
        <button
          class="mdw-button"
          iconCalloutColor="purple"
          iconCalloutSize="medium"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="purple"
          iconCalloutSize="large"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="purple"
          iconCalloutSize="x-large"
          [icon]="icons.Apple_Fill"
        ></button>
      </div>
      <div class="hflex-100 flex-align-items-center flex-wrap">
        <button
          class="mdw-button"
          iconCalloutColor="teal"
          iconCalloutSize="medium"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="teal"
          iconCalloutSize="large"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="teal"
          iconCalloutSize="x-large"
          [icon]="icons.Apple_Fill"
        ></button>
      </div>
      <div class="hflex-100 flex-align-items-center flex-wrap">
        <button
          class="mdw-button"
          iconCalloutColor="orange"
          iconCalloutSize="medium"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="orange"
          iconCalloutSize="large"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="orange"
          iconCalloutSize="x-large"
          [icon]="icons.Apple_Fill"
        ></button>
      </div>
      <div class="hflex-100 flex-align-items-center flex-wrap">
        <button
          class="mdw-button"
          iconCalloutColor="pink"
          iconCalloutSize="medium"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="pink"
          iconCalloutSize="large"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="pink"
          iconCalloutSize="x-large"
          [icon]="icons.Apple_Fill"
        ></button>
      </div>
      <div class="hflex-100 flex-align-items-center flex-wrap">
        <button
          class="mdw-button"
          iconCalloutColor="red"
          iconCalloutSize="medium"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="red"
          iconCalloutSize="large"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="red"
          iconCalloutSize="x-large"
          [icon]="icons.Apple_Fill"
        ></button>
      </div>
      <div class="hflex-100 flex-align-items-center flex-wrap">
        <button
          class="mdw-button"
          iconCalloutColor="yellow"
          iconCalloutSize="medium"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="yellow"
          iconCalloutSize="large"
          [icon]="icons.Apple_Fill"
        ></button>
        <button
          class="mdw-button"
          iconCalloutColor="yellow"
          iconCalloutSize="x-large"
          [icon]="icons.Apple_Fill"
        ></button>
      </div>
    </div>
  </ng-template>
  <app-sample [sampleContent]="buttonIconCallout" sampleName="buttonIconCallout"></app-sample>
</div>
