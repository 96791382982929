import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MdwEmoji } from '../models';

@Injectable({ providedIn: 'root' })
export class MdwEmojiService {
  public currentMessageId?: string;
  private _showEmojiPanel$ = new BehaviorSubject<boolean>(false);
  public showEmojiPanel$ = this._showEmojiPanel$.asObservable();
  public get panelIsShown() {
    return this._showEmojiPanel$.value;
  }
  private _initializeEmojiPanel$ = new BehaviorSubject<boolean>(false);
  public initializeEmojiPanel$ = this._initializeEmojiPanel$.asObservable();
  public panelPosition: { top: number; right: number } = { top: -9000, right: -9000 };

  private onSelectEmoji?: (emoji: MdwEmoji) => void;

  public constructor() {
    document.addEventListener('click', event => {
      if (
        this._showEmojiPanel$.value === false ||
        (event.target as HTMLElement).classList.contains('emoji-trigger')
      ) {
        return;
      }

      const emojiPanel = document.getElementById('floating-emoji-panel');
      const emojiTriggers = document.getElementsByClassName('emoji-trigger');
      if (!emojiPanel) {
        return;
      }

      const isInEmojiPanel = emojiPanel.contains(event.target as Node);
      const isInEmojiTrigger = Array.from(emojiTriggers).some(trigger =>
        trigger.contains(event.target as Node),
      );

      if (isInEmojiPanel || isInEmojiTrigger) {
        return;
      }

      this.hideEmojiPanel();
    });
  }

  public handleSelectEmoji(emoji: MdwEmoji) {
    if (this.onSelectEmoji) {
      this.onSelectEmoji(emoji);
    }
  }

  public initializeEmojiPanel() {
    this._initializeEmojiPanel$.next(true);
  }

  public showPanel(
    messageId: string | undefined,
    event: MouseEvent,
    onSelectEmoji: (emoji: MdwEmoji) => void,
    isInline = false,
  ) {
    this.currentMessageId = messageId;
    this.setPanelPosition(event, isInline);
    this._showEmojiPanel$.next(true);
    this.onSelectEmoji = onSelectEmoji;
  }

  public getBaseName(emoji: string) {
    let baseName = emoji;
    if (baseName.includes('skin-tone')) {
      const baseNameParts = baseName.split(':');
      baseName = `:${baseNameParts[1]}:`;
    }

    return baseName;
  }

  public hideEmojiPanel() {
    this.currentMessageId = undefined;
    this._showEmojiPanel$.next(false);
    this.onSelectEmoji = undefined;

    setTimeout(() => (this.panelPosition = { top: -9000, right: -9000 }), 200);
  }

  private setPanelPosition(event: MouseEvent, isInline: boolean) {
    const { clientY, clientX } = event;
    const { innerWidth, innerHeight } = window;
    const trigger = event.target as HTMLElement;
    const emojiPanel = document.getElementById('floating-emoji-panel');
    if (!emojiPanel || !trigger) {
      return;
    }

    const emojiPanelRect = emojiPanel.getBoundingClientRect();
    const emojiPanelHeight = emojiPanelRect.height;
    const { top, left, height, width } = trigger.getBoundingClientRect();
    const { scrollY } = window;

    const openLeft = innerWidth / 2 < clientX;

    const isPanelFitToBottom = innerHeight - clientY > emojiPanelHeight;
    const panelOffset = 10;

    let topPosition = isPanelFitToBottom
      ? top + height + scrollY + panelOffset
      : top - emojiPanelHeight + scrollY - panelOffset;

    const leftPosition = openLeft ? left + width - emojiPanelRect.width : left;
    const rightPosition = innerWidth - leftPosition - emojiPanelRect.width;

    if (topPosition < 64) {
      topPosition = 64;
    }

    if (topPosition + emojiPanelHeight > innerHeight - 24) {
      topPosition = innerHeight - emojiPanelHeight - 24;
    }

    this.panelPosition = {
      top: topPosition,
      right: isInline ? rightPosition : 16,
    };
  }
}
