import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { ButtonComponent } from '@spaces-ui/meadow/components/button';
import { IconComponent } from '@spaces-ui/meadow/icons';
import * as _icons from '@spaces-ui/meadow/icons/svgs';

@Component({
  selector: 'app-icons',
  standalone: true,
  imports: [CommonModule, IconComponent, ButtonComponent],
  templateUrl: './icons.component.html',
  styleUrl: './icons.component.scss',
})
export class IconsComponent {
  public useColor = false;

  public get allIcons() {
    const keys = Object.keys(_icons);
    const values = Object.values(_icons);

    // Group icons by the first segment of the key
    const groupedIcons = keys.reduce(
      (acc, key, index) => {
        const [prefix, style] = key.split('_');
        if (!acc[prefix]) {
          acc[prefix] = [];
        }
        acc[prefix].push({
          key,
          style,
          value: values[index],
        });
        return acc;
      },
      {} as { [key: string]: Array<{ key: string; style: string; value: string }> },
    );

    const icons = Object.keys(groupedIcons).map(key => {
      return {
        key,
        value: groupedIcons[key],
      };
    });

    // Convert the grouped object to an array of arrays
    return icons;
  }

  public homeIcon = _icons.Home_Fill;
}
