import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { IconComponent } from '@spaces-ui/meadow/icons';
import { Check_Large, Warning_Fill } from '@spaces-ui/meadow/icons/svgs';

import { ToastMessageConfig } from '../../models';

@Component({
  selector: 'mdw-notifications-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
  standalone: true,
  imports: [CommonModule, IconComponent],
})
export class ToastMessageComponent {
  public data = inject(MAT_SNACK_BAR_DATA) as ToastMessageConfig;

  public icons = {
    Check_Large,
    Warning_Fill,
  };
}
