<div class="vflex-200 relative scrollable">
  <button
    class="mdw-button mdw-tertiary mdw-aside-trigger"
    (click)="collapse.emit()"
    [icon]="icons.Sidebar_Duotone"
  ></button>
  <nav class="pad-200 vflex-200">
    <h3>Stylings</h3>
    <nav class="emphasized">
      <ul>
        <li routerLink="/tokens" routerLinkActive="active">Design Tokens</li>
        <li routerLink="/colors" routerLinkActive="active">Colors</li>
        <li routerLink="/typography" routerLinkActive="active">Typography</li>
        <li routerLink="/layout" routerLinkActive="active">Layout</li>
        <li routerLink="/containers" routerLinkActive="active">Containers</li>
        <li routerLink="/dividers" routerLinkActive="active">Dividers</li>
        <li routerLink="/icons" routerLinkActive="active">Icons</li>
      </ul>
    </nav>
  </nav>
  <nav class="pad-200 vflex-200">
    <h3>Components</h3>
    <nav class="emphasized">
      <ul>
        <li routerLink="/app-elements" routerLinkActive="active">App Elements</li>
        <li routerLink="/buttons" routerLinkActive="active">Buttons</li>
        <li routerLink="/list-items" routerLinkActive="active">List Items</li>
        <li routerLink="/tabs" routerLinkActive="active" badge="wip">Tabs</li>
        <li routerLink="/badges" routerLinkActive="active">Badges</li>
        <li routerLink="/chips" routerLinkActive="active" badge="wip">Chips</li>
        <li routerLink="/menus" routerLinkActive="active">Menus</li>
        <li routerLink="/dialogs" routerLinkActive="active" badge="wip">Dialogs</li>
        <li routerLink="/tooltips" routerLinkActive="active">Tooltips</li>
        <li routerLink="/notifications" routerLinkActive="active">Notifications</li>
        <li routerLink="/avatars" routerLinkActive="active">Avatars</li>
        <li routerLink="/emojis" routerLinkActive="active">Emojis</li>
        <li routerLink="/icon-callout" routerLinkActive="active" badge="wip">Icon Callout</li>
      </ul>
    </nav>
  </nav>
  <nav class="pad-200 vflex-200">
    <h3>Forms</h3>
    <nav class="emphasized">
      <ul>
        <li routerLink="/inputs" routerLinkActive="active">Inputs</li>
        <li routerLink="/selects" routerLinkActive="active">Selects</li>
      </ul>
    </nav>
  </nav>
</div>
