<div class="surface-elevated vflex-100 pad-000" [class.bam-content]="data.isBAM">
  <div
    class="vflex-000 dialog-content fill-height"
    [class.vpad-200]="!data.isBAM"
    [class.hpad-300]="!data.isBAM"
    [class.vpad-300]="data.isBAM"
    [class.hpad-400]="data.isBAM"
  >
    @if (showHeader()) {
      <mdw-dialog-header
        [mainTitle]="data.title || mainTitle()"
        [icon]="icon()"
        [rightConent]="rightHeaderConent()"
        [showCloseButton]="data.allowDismiss || !!data.isBAM || !!showCloseButton()"
        (closed)="complete(false)"
      ></mdw-dialog-header>
    }
    <div class="vflex-000" [class.has-header]="showHeader()">
      @if (data.message) {
        <div class="dialog-message">{{ data.message }}</div>
      } @else {
        <div class="fill-height">
          <ng-content></ng-content>
        </div>
      }
    </div>
  </div>
  @if (
    !data.isBAM &&
    (data.primaryButton || data.dangerButton || data.secondaryButton || data.tertiaryButton)
  ) {
    <mdw-dialog-footer
      [primaryButton]="data.primaryButton"
      [dangerButton]="data.dangerButton"
      [secondaryButton]="data.secondaryButton"
      [tertiaryButton]="data.tertiaryButton"
      [primaryButtonDisabled]="primaryButtonDisabled()"
      [dangerButtonDisabled]="dangerButtonDisabled()"
      [secondaryButtonDisabled]="secondaryButtonDisabled()"
      [tertiaryButtonDisabled]="tertiaryButtonDisabled()"
      (primaryButtonClicked)="customButtonHandlers() ? primaryButtonClicked.emit() : complete(true)"
      (dangerButtonClicked)="customButtonHandlers() ? dangerButtonClicked.emit() : complete(true)"
      (secondaryButtonClicked)="
        customButtonHandlers() ? secondaryButtonClicked.emit() : complete(false)
      "
      (tertiaryButtonClicked)="
        customButtonHandlers() ? tertiaryButtonClicked.emit() : complete(false)
      "
      [showSecondaryButton]="data.showSecondaryButton"
    ></mdw-dialog-footer>
  }
</div>
