import { CommonModule } from '@angular/common';
import { Component, output } from '@angular/core';

import { ButtonComponent } from '@spaces-ui/meadow/components/button';

import { Close } from '@spaces-ui/meadow/icons/svgs';

@Component({
  selector: 'mdw-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  standalone: true,
  imports: [CommonModule, ButtonComponent],
})
export class ChipComponent {
  public dismiss = output<MouseEvent>();

  public icons = { Close };
}
