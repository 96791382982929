export function sortByProperty<T>(arr: T[], propName: keyof T, desc = true): T[] {
  return arr.slice().sort((a: T, b: T) => {
    // eslint-disable-next-line
    const valA = a[propName];
    // eslint-disable-next-line
    const valB = b[propName];

    if (valA < valB) {
      return desc ? 1 : -1;
    }
    if (valA > valB) {
      return desc ? -1 : 1;
    }

    return 0;
  });
}

export function alphaSortByProperty<T>(arr: T[], propName: keyof T, desc = true): T[] {
  return arr.slice().sort((a: T, b: T) => {
    // eslint-disable-next-line
    const valA = a[propName]?.toString().toUpperCase() ?? '';
    // eslint-disable-next-line
    const valB = b[propName]?.toString().toUpperCase() ?? '';

    if (valA < valB) {
      return desc ? 1 : -1;
    }
    if (valA > valB) {
      return desc ? -1 : 1;
    }

    return 0;
  });
}

export function sort(arr: string[], desc = true) {
  return arr.slice().sort((a, b) => {
    if (a < b) {
      return desc ? 1 : -1;
    }
    if (a > b) {
      return desc ? -1 : 1;
    }

    return 0;
  });
}
