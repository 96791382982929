import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { DialogComponent } from '@spaces-ui/meadow/components/dialog';
import { InputDirective } from '@spaces-ui/meadow/components/input';

@Component({
  selector: 'app-simple-form-dialog',
  standalone: true,
  imports: [CommonModule, DialogComponent, InputDirective],
  templateUrl: './simple-form-dialog.component.html',
  styleUrl: './simple-form-dialog.component.scss',
})
export class SimpleFormDialogComponent {}
