import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

interface Snippet {
  html: string;
  code: string;
  styles: string;
}
@Injectable({ providedIn: 'root' })
export class SampleService {
  private _snippets$ = new BehaviorSubject<Record<string, Snippet>>({});
  public snippets$ = this._snippets$.asObservable();

  public constructor(private http: HttpClient) {
    this.getSnippets();
  }

  private getSnippets(): Promise<unknown> {
    return firstValueFrom(
      this.http
        .get('assets/snippets.json', { responseType: 'json' })
        .pipe(map(snippets => this._snippets$.next(snippets as Record<string, Snippet>))),
    );
  }
}
