<div class="pad-300 hflex-150">
  <div class="fill-flex">
    @if (tertiaryButton()) {
      <button
        class="mdw-button mdw-tertiary large-button"
        [disabled]="tertiaryButtonDisabled()"
        (click)="tertiaryButtonClicked.emit()"
      >
        {{ tertiaryButton() }}
      </button>
    }
  </div>

  @if (showSecondaryButton() && secondaryButton()) {
    <button
      class="mdw-button mdw-secondary large-button"
      [disabled]="secondaryButtonDisabled()"
      (click)="secondaryButtonClicked.emit()"
    >
      {{ secondaryButton() }}
    </button>
  }
  @if (primaryButton()) {
    <button
      class="mdw-button mdw-primary large-button"
      [disabled]="primaryButtonDisabled()"
      (click)="primaryButtonClicked.emit()"
    >
      {{ primaryButton() }}
    </button>
  }
  @if (dangerButton()) {
    <button
      class="mdw-button mdw-danger large-button"
      [disabled]="dangerButtonDisabled()"
      (click)="dangerButtonClicked.emit()"
    >
      {{ dangerButton() }}
    </button>
  }
</div>
