import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AsideComponent } from '@spaces-ui/meadow/components/aside';
import { ButtonComponent } from '@spaces-ui/meadow/components/button';
import { MdwNotificationsService } from '@spaces-ui/meadow/components/notifications';
import { Sidebar_Duotone } from '@spaces-ui/meadow/icons/svgs';
import { LoadableEntitiesUtils, ThemeService } from '@spaces-ui/shared';

import { AppSecondarySidebarComponent, AppTopSidebarComponent } from './components';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    AppSecondarySidebarComponent,
    AppTopSidebarComponent,
    AsideComponent,
    ButtonComponent,
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  private notificationService = inject(MdwNotificationsService);
  private themeService = inject(ThemeService);

  public icons = { Sidebar_Duotone };
  public sidebarIsCollapsed = false;
  public sidebarIsFloating = false;

  public constructor() {
    LoadableEntitiesUtils.registerNotificationService(this.notificationService);
  }

  toggleSidebar() {
    this.sidebarIsCollapsed = !this.sidebarIsCollapsed;
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }
}
