import { CommonModule } from '@angular/common';
import { Component, computed, ElementRef, input, output, viewChildren } from '@angular/core';

import { ListItemComponent } from '@spaces-ui/meadow/components/list-item';

@Component({
  selector: 'mdw-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  standalone: true,
  imports: [CommonModule, ListItemComponent],
})
export class TabGroupComponent {
  public tabs = input.required<Array<{ label: string; alias: string; icon?: string }>>();
  public selectedTab = input.required<string>();

  public goTo = output<string>();

  public listItemComponents = viewChildren(ListItemComponent, { read: ElementRef<HTMLElement> });

  public selectedListItem = computed(() => {
    const listItems = this.listItemComponents();
    const selectedId = this.selectedTab();

    const selectedElementRef = listItems.find(listItem => {
      return (listItem.nativeElement as HTMLElement).id === selectedId;
    });

    return selectedElementRef?.nativeElement as HTMLElement | undefined;
  });
}
