import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import { ToastMessageComponent } from '../components';
import { ToastMessageConfig } from '../models';

@Injectable({ providedIn: 'root' })
export class MdwNotificationsService {
  private snackBar = inject(MatSnackBar);

  public showSuccess(
    message: string,
    duration = 3000,
    horizontalPosition: MatSnackBarHorizontalPosition = 'center',
    verticalPosition: MatSnackBarVerticalPosition = 'top',
  ) {
    const opts: ToastMessageConfig = {
      message,
      type: 'success',
    };

    this.snackBar.openFromComponent(ToastMessageComponent, {
      data: opts,
      duration: duration,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }

  public showError(
    message: string,
    error?: string | HttpErrorResponse,
    duration = 3000,
    horizontalPosition: MatSnackBarHorizontalPosition = 'center',
    verticalPosition: MatSnackBarVerticalPosition = 'top',
  ) {
    const errorText = error instanceof HttpErrorResponse ? error.error : error;
    const messageWithError = errorText ? `${message}: ${errorText}` : message;

    const opts: ToastMessageConfig = {
      message: messageWithError,
      type: 'error',
    };

    this.snackBar.openFromComponent(ToastMessageComponent, {
      data: opts,
      duration: duration,
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
    });
  }
}
