import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { ButtonComponent } from '@spaces-ui/meadow/components/button';
import { IconComponent } from '@spaces-ui/meadow/icons';
import { Apple_Fill, Home_Fill } from '@spaces-ui/meadow/icons/svgs';

import { SampleComponent } from '../../components';

@Component({
  selector: 'app-buttons',
  standalone: true,
  imports: [CommonModule, ButtonComponent, IconComponent, SampleComponent],
  templateUrl: './buttons.component.html',
  styleUrl: './buttons.component.scss',
})
export class ButtonsComponent {
  public icons = { Home_Fill, Apple_Fill };
}
