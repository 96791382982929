<div class="vflex-300">
  <h2>Badges</h2>
  <p><span class="mdw-badge-info">Styles Only</span></p>
  <p>
    Badges are used to indicate the status of an item or to draw attention to it. We have a
    numerical badge, a dot badge, as well as alphanumeric badges.
  </p>
  <h3>Badges</h3>
  <ng-template #badges data-sample="badges">
    <div class="vflex-800">
      <section class="vflex-300 flex-no-stretch">
        <span class="mdw-badge">Default</span>
        <span class="mdw-badge mdw-success">Success</span>
        <span class="mdw-badge mdw-info">Info</span>
        <span class="mdw-badge mdw-warning">Warning</span>
        <span class="mdw-badge mdw-negative">Negative</span>
        <span class="mdw-badge mdw-danger">Urgent</span>
      </section>

      <section class="vflex-300 flex-no-stretch">
        <span class="mdw-badge">
          <mdw-icon [icon]="icons.Home_Fill"></mdw-icon>
          Default
        </span>
        <span class="mdw-badge mdw-success">
          <mdw-icon [icon]="icons.Home_Fill"></mdw-icon>
          Success
        </span>
        <span class="mdw-badge mdw-info">
          <mdw-icon [icon]="icons.Home_Fill"></mdw-icon>
          Info
        </span>
        <span class="mdw-badge mdw-warning">
          <mdw-icon [icon]="icons.Home_Fill"></mdw-icon>
          Warning
        </span>
        <span class="mdw-badge mdw-negative">
          <mdw-icon [icon]="icons.Home_Fill"></mdw-icon>
          Negative
        </span>
        <span class="mdw-badge mdw-danger">
          <mdw-icon [icon]="icons.Home_Fill"></mdw-icon>
          Urgent
        </span>
      </section>
    </div>
  </ng-template>
  <app-sample [sampleContent]="badges" sampleName="badges"></app-sample>
  <h3>Dot Notification Badge</h3>
  <ng-template #dotBadge data-sample="dotBadge">
    <section class="vflex-300 flex-no-stretch">
      <button
        class="mdw-button mdw-tertiary mdw-notification-badge-dot"
        [icon]="icons.Home_Fill"
      ></button>
      <button
        class="mdw-button mdw-primary mdw-notification-badge-dot"
        [icon]="icons.Home_Fill"
      ></button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="dotBadge" sampleName="dotBadge"></app-sample>

  <h3>Notification Badge</h3>
  <p>
    Notification badges are used to indicate the number of notifications or messages that a user
    has.
  </p>
  <p>
    You can create a standalone badge to be used in a custom manner, or provided to some components
    that have opinionated implementations of badges (button, list-item)
  </p>
  <ng-template #notificationBadge data-sample="notificationBadge">
    <section class="vflex-300 flex-no-stretch">
      <span class="mdw-notification-badge">99+</span>
      <button class="mdw-button mdw-primary" [icon]="icons.Home_Fill" badge="6"></button>
      <button class="mdw-button mdw-secondary" [icon]="icons.Home_Fill" badge="321"></button>
      <menu style="width: 100%">
        <ul>
          <li [icon]="icons.Home_Fill" badge="24">Home Space</li>
        </ul>
      </menu>
    </section>
  </ng-template>
  <app-sample [sampleContent]="notificationBadge" sampleName="notificationBadge"></app-sample>

  <h3>Passive Notification Badge</h3>
  <p>
    Passive notification badges are used to indicate the presence of notifications without having a
    count
  </p>
  <p>
    You can create a standalone badge to be used in a custom manner, or provided to some components
    that have opinionated implementations of badges (button)
  </p>
  <ng-template #passiveNotificationBadge data-sample="passiveNotificationBadge">
    <section class="vflex-300 flex-no-stretch">
      <span class="mdw-notification-passive-badge"></span>
      <button
        class="mdw-button mdw-primary"
        [icon]="icons.Home_Fill"
        [passiveBadge]="true"
      ></button>
      <button
        class="mdw-button mdw-secondary"
        [icon]="icons.Home_Fill"
        [passiveBadge]="true"
      ></button>
    </section>
  </ng-template>
  <app-sample
    [sampleContent]="passiveNotificationBadge"
    sampleName="passiveNotificationBadge"
  ></app-sample>
</div>
