<div class="vflex-200">
  <h2>Dividers</h2>
  <h3>hr</h3>
  <div class="vflex-400">
    <p>
      We have a few different dividers that can be used to separate content. The default divider
      uses the different border colors,
      <code>primary</code>
      (default),
      <code>muted</code>
      , and
      <code>strong</code>
      .
    </p>
    <ng-template #dividers data-sample="dividers">
      <div class="vflex-300">
        <p>Some paragraph of information</p>
        <hr />
        <p>Some paragraph of information ahead of an HR that's muted</p>
        <hr class="hr-muted" />
        <p>Some paragraph of information ahead of an HR that's empahised (aka strong)</p>
        <hr class="hr-strong" />
        <p>Some paragraph of information</p>
      </div>
    </ng-template>
    <app-sample [sampleContent]="dividers" sampleName="dividers"></app-sample>
  </div>
</div>
