<div class="pad-200 vflex-200">
  <h2>Avatars</h2>
  <div class="surface-muted pad-200">
    <code>
      import &#123; AvatarComponent &#125; from '&#64;spaces-ui/meadow/components/avatar';
    </code>
  </div>
  <h3>User Avatars</h3>
  <p>
    A user avatar requires either a name or a src. If a src is provided, you should also provide an
    alt text for accessibility. You can also provide the name instead of the alt attribute, and that
    will be used as the alt text.
  </p>
  <ng-template #userAvatars data-sample="userAvatars">
    <div class="vflex-200">
      <label for="">Default with Src</label>
      <div class="hflex-100 flex-align-items-center">
        <mdw-avatar
          size="small"
          src="https://blue-api.dev.medchatapp.com/orgs/39da3946-82e5-5612-0958-cbc25f0e076d/userPhotos/2fea6b81-6310-40a3-8df3-79b0c66fbaaa?version=48"
          [srcVersion]="1"
          [color]="1"
          name="Robbie"
          alt="Robbie's avatar"
        ></mdw-avatar>
        <mdw-avatar
          src="https://blue-api.dev.medchatapp.com/orgs/39da3946-82e5-5612-0958-cbc25f0e076d/userPhotos/2fea6b81-6310-40a3-8df3-79b0c66fbaaa?version=48"
          alt="Robbie's avatar"
          [srcVersion]="1"
          [color]="1"
          name="Robbie"
        ></mdw-avatar>
        <mdw-avatar
          size="large"
          src="https://blue-api.dev.medchatapp.com/orgs/39da3946-82e5-5612-0958-cbc25f0e076d/userPhotos/2fea6b81-6310-40a3-8df3-79b0c66fbaaa?version=48"
          alt="Robbie's avatar"
          [srcVersion]="1"
          [color]="1"
          name="Robbie"
        ></mdw-avatar>
        <mdw-avatar
          size="x-large"
          src="https://blue-api.dev.medchatapp.com/orgs/39da3946-82e5-5612-0958-cbc25f0e076d/userPhotos/2fea6b81-6310-40a3-8df3-79b0c66fbaaa?version=48"
          alt="Robbie's avatar"
          [srcVersion]="1"
          [color]="1"
          name="Robbie"
        ></mdw-avatar>
        <mdw-avatar
          size="jumbo"
          src="https://blue-api.dev.medchatapp.com/orgs/39da3946-82e5-5612-0958-cbc25f0e076d/userPhotos/2fea6b81-6310-40a3-8df3-79b0c66fbaaa?version=48"
          alt="Robbie's avatar"
          [srcVersion]="1"
          [color]="1"
          name="Robbie"
        ></mdw-avatar>
      </div>

      <label for="">Default with name</label>
      <div class="hflex-100 flex-align-items-center">
        <mdw-avatar
          size="small"
          name="Layton Miller"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          name="Layton Miller"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="large"
          name="Layton Miller"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="x-large"
          name="Layton Miller"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="jumbo"
          name="Layton Miller"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
      </div>

      <label for="">Deleted user avatar</label>
      <div class="hflex-100 flex-align-items-center">
        <mdw-avatar
          size="small"
          [isDeleted]="true"
          name="Test"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          [isDeleted]="true"
          name="Test"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="large"
          [isDeleted]="true"
          name="Test"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="x-large"
          [isDeleted]="true"
          name="Test"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="jumbo"
          [isDeleted]="true"
          name="Test"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
      </div>
    </div>
  </ng-template>
  <app-sample [sampleContent]="userAvatars" sampleName="userAvatars"></app-sample>
  <h3>Status Indicators</h3>
  <div class="pad-200 surface-muted vflex-200">
    <p>
      The status indicators are the little icons that show if a user is online, offline, and/or
      snoozed. This is driven by 2 input properties
    </p>
    <ul>
      <li>
        <code>online</code>
        - boolean - if the user is online
      </li>
      <li>
        <code>snoozed</code>
        - boolean - if the user is snoozed
      </li>
    </ul>
    <p>
      We also support a number badge that can be used to show the number of members within a DM.
      This is driven by the
      <code>numberBadge</code>
      input property. Any number greater than 0 will show the badge, and a number greater than 9
      will show a "9+" badge.
    </p>
  </div>
  <ng-template #statusAvatars data-sample="statusAvatars">
    <div class="vflex-200">
      <label for="">online</label>
      <div class="hflex-100 flex-align-items-center">
        <mdw-avatar
          size="small"
          name="Layton Miller"
          [online]="true"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          name="Layton Miller"
          [online]="true"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="large"
          name="Layton Miller"
          [online]="true"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="x-large"
          name="Layton Miller"
          [online]="true"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="jumbo"
          name="Layton Miller"
          [online]="true"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
      </div>
      <label for="">online + snoozed</label>
      <div class="hflex-100 flex-align-items-center">
        <mdw-avatar
          size="small"
          name="Layton Miller"
          [online]="true"
          [snoozed]="true"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          name="Layton Miller"
          [online]="true"
          [snoozed]="true"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="large"
          name="Layton Miller"
          [online]="true"
          [snoozed]="true"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="x-large"
          name="Layton Miller"
          [online]="true"
          [snoozed]="true"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="jumbo"
          name="Layton Miller"
          [online]="true"
          [snoozed]="true"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
      </div>
      <label for="">offline</label>
      <div class="hflex-100 flex-align-items-center">
        <mdw-avatar
          size="small"
          name="Layton Miller"
          [online]="false"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          name="Layton Miller"
          alt="Robbie's avatar"
          [online]="false"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="large"
          name="Layton Miller"
          [online]="false"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="x-large"
          name="Layton Miller"
          [online]="false"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="jumbo"
          name="Layton Miller"
          [online]="false"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
      </div>
      <label for="">offline + snoozed</label>
      <div class="hflex-100 flex-align-items-center">
        <mdw-avatar
          size="small"
          name="Layton Miller"
          [online]="false"
          [snoozed]="true"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          name="Layton Miller"
          [online]="false"
          [snoozed]="true"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="large"
          name="Layton Miller"
          [online]="false"
          [snoozed]="true"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="x-large"
          name="Layton Miller"
          [online]="false"
          [snoozed]="true"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        <mdw-avatar
          size="jumbo"
          name="Layton Miller"
          [online]="false"
          [snoozed]="true"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
      </div>
      <label for="">DM with multiple indicator</label>
      <div class="vflex-200">
        <div class="hflex-200">
          <mdw-avatar
            size="small"
            name="Robbie Rousseau"
            [numberBadge]="0"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="small"
            name="Robbie Rousseau"
            [numberBadge]="1"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="small"
            name="Robbie Rousseau"
            [numberBadge]="2"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="small"
            name="Robbie Rousseau"
            [numberBadge]="3"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="small"
            name="Robbie Rousseau"
            [numberBadge]="4"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="small"
            name="Robbie Rousseau"
            [numberBadge]="5"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
        </div>
        <div class="hflex-200">
          <mdw-avatar
            size="small"
            name="Robbie Rousseau"
            [numberBadge]="6"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="small"
            name="Robbie Rousseau"
            [numberBadge]="7"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="small"
            name="Robbie Rousseau"
            [numberBadge]="8"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="small"
            name="Robbie Rousseau"
            [numberBadge]="9"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="small"
            name="Robbie Rousseau"
            [numberBadge]="15"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
        </div>

        <div class="hflex-200">
          <mdw-avatar
            name="Robbie Rousseau"
            [numberBadge]="0"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            name="Robbie Rousseau"
            [numberBadge]="1"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            name="Robbie Rousseau"
            [numberBadge]="2"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            name="Robbie Rousseau"
            [numberBadge]="3"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            name="Robbie Rousseau"
            [numberBadge]="4"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            name="Robbie Rousseau"
            [numberBadge]="5"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
        </div>
        <div class="hflex-200">
          <mdw-avatar
            name="Robbie Rousseau"
            [numberBadge]="6"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            name="Robbie Rousseau"
            [numberBadge]="7"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            name="Robbie Rousseau"
            [numberBadge]="8"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            name="Robbie Rousseau"
            [numberBadge]="9"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            name="Robbie Rousseau"
            [numberBadge]="15"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
        </div>

        <div class="hflex-200">
          <mdw-avatar
            size="large"
            name="Robbie Rousseau"
            [numberBadge]="0"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="large"
            name="Robbie Rousseau"
            [numberBadge]="1"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="large"
            name="Robbie Rousseau"
            [numberBadge]="2"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="large"
            name="Robbie Rousseau"
            [numberBadge]="3"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="large"
            name="Robbie Rousseau"
            [numberBadge]="4"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="large"
            name="Robbie Rousseau"
            [numberBadge]="5"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
        </div>
        <div class="hflex-200">
          <mdw-avatar
            size="large"
            name="Robbie Rousseau"
            [numberBadge]="6"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="large"
            name="Robbie Rousseau"
            [numberBadge]="7"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="large"
            name="Robbie Rousseau"
            [numberBadge]="8"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="large"
            name="Robbie Rousseau"
            [numberBadge]="9"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="large"
            name="Robbie Rousseau"
            [numberBadge]="15"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
        </div>

        <div class="hflex-200">
          <mdw-avatar
            size="x-large"
            name="Robbie Rousseau"
            [numberBadge]="0"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="x-large"
            name="Robbie Rousseau"
            [numberBadge]="1"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="x-large"
            name="Robbie Rousseau"
            [numberBadge]="2"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="x-large"
            name="Robbie Rousseau"
            [numberBadge]="3"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="x-large"
            name="Robbie Rousseau"
            [numberBadge]="4"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="x-large"
            name="Robbie Rousseau"
            [numberBadge]="5"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
        </div>
        <div class="hflex-200">
          <mdw-avatar
            size="x-large"
            name="Robbie Rousseau"
            [numberBadge]="6"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="x-large"
            name="Robbie Rousseau"
            [numberBadge]="7"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="x-large"
            name="Robbie Rousseau"
            [numberBadge]="8"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="x-large"
            name="Robbie Rousseau"
            [numberBadge]="9"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="x-large"
            name="Robbie Rousseau"
            [numberBadge]="15"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
        </div>

        <div class="hflex-200">
          <mdw-avatar
            size="jumbo"
            name="Robbie Rousseau"
            [numberBadge]="0"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="jumbo"
            name="Robbie Rousseau"
            [numberBadge]="1"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="jumbo"
            name="Robbie Rousseau"
            [numberBadge]="2"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="jumbo"
            name="Robbie Rousseau"
            [numberBadge]="3"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="jumbo"
            name="Robbie Rousseau"
            [numberBadge]="4"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="jumbo"
            name="Robbie Rousseau"
            [numberBadge]="5"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
        </div>
        <div class="hflex-200">
          <mdw-avatar
            size="jumbo"
            name="Robbie Rousseau"
            [numberBadge]="6"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="jumbo"
            name="Robbie Rousseau"
            [numberBadge]="7"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="jumbo"
            name="Robbie Rousseau"
            [numberBadge]="8"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="jumbo"
            name="Robbie Rousseau"
            [numberBadge]="9"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
          <mdw-avatar
            size="jumbo"
            name="Robbie Rousseau"
            [numberBadge]="15"
            [src]="undefined"
            [srcVersion]="1"
            [color]="1"
          ></mdw-avatar>
        </div>
      </div>
    </div>
  </ng-template>
  <app-sample [sampleContent]="statusAvatars" sampleName="statusAvatars"></app-sample>
  <h3>Colors</h3>
  <p>
    The color prop is a number between 1 and 8. The color is determined by the number, and the
    initials are determined by the name prop.
  </p>
  <ng-template #colorAvatars data-sample="colorAvatars">
    <div class="vflex-200">
      <div class="hflex-200 align-center">
        <mdw-avatar
          name="Another User"
          alt="Avatar of Initials"
          [color]="1"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        Another User
      </div>
      <div class="hflex-200 align-center">
        <mdw-avatar
          name="Yet one more User"
          alt="Avatar of Initials"
          [color]="2"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        Yet one more User
      </div>
      <div class="hflex-200 align-center">
        <mdw-avatar
          name="Whodis User"
          alt="Avatar of Initials"
          [color]="3"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        Whodis User
      </div>
      <div class="hflex-200 align-center">
        <mdw-avatar
          name="Some User"
          alt="Avatar of Initials"
          [color]="4"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        Some User
      </div>
      <div class="hflex-200 align-center">
        <mdw-avatar
          name="Initialed User"
          alt="Avatar of Initials"
          [color]="5"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        Initialed User
      </div>
      <div class="hflex-200 align-center">
        <mdw-avatar
          name="Not Me User"
          alt="Avatar of Initials"
          [color]="6"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        Not Me User
      </div>
      <div class="hflex-200 align-center">
        <mdw-avatar
          name="My User"
          alt="Avatar of Initials"
          [color]="7"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        My User
      </div>
      <div class="hflex-200 align-center">
        <mdw-avatar
          name="Unknown User"
          alt="Avatar of Initials"
          [color]="8"
          [src]="undefined"
          [srcVersion]="1"
          [color]="1"
        ></mdw-avatar>
        Unknown User
      </div>
    </div>
  </ng-template>
  <app-sample [sampleContent]="colorAvatars" sampleName="colorAvatars"></app-sample>
  <h3>Sizes</h3>
  <p>
    The size prop is a string that can be "small", "medium", "large", or "x-large". The default size
    is "large".
  </p>
  <ng-template #avatarSizes data-sample="avatarSizes">
    <div class="vflex-200">
      <div class="hflex-200">
        <mdw-avatar
          name="Some User"
          [color]="1"
          alt="Avatar of Initials"
          size="small"
          [online]="true"
          [src]="undefined"
          [srcVersion]="1"
        ></mdw-avatar>
        <mdw-avatar
          name="Another User"
          [color]="1"
          alt="Avatar of Initials"
          size="medium"
          [online]="false"
          [online]="true"
          [src]="undefined"
          [srcVersion]="1"
        ></mdw-avatar>
        <mdw-avatar
          name="My User"
          [color]="1"
          alt="Avatar of Initials"
          size="large"
          [online]="true"
          [snoozed]="true"
          [online]="true"
          [src]="undefined"
          [srcVersion]="1"
        ></mdw-avatar>
        <mdw-avatar
          name="Initialed User"
          [color]="1"
          alt="Avatar of Initials"
          size="x-large"
          [online]="false"
          [snoozed]="true"
          [online]="true"
          [src]="undefined"
          [srcVersion]="1"
        ></mdw-avatar>
        <mdw-avatar
          name="Initialed User"
          [color]="1"
          alt="Avatar of Initials"
          size="jumbo"
          [online]="false"
          [snoozed]="true"
          [online]="true"
          [src]="undefined"
          [srcVersion]="1"
        ></mdw-avatar>
      </div>
      <div class="hflex-200">
        <mdw-avatar
          name="Some User"
          [color]="1"
          alt="Avatar of Initials"
          size="small"
          [numberBadge]="4"
          [online]="true"
          [src]="undefined"
          [srcVersion]="1"
        ></mdw-avatar>
        <mdw-avatar
          name="Another User"
          [color]="1"
          alt="Avatar of Initials"
          size="medium"
          [numberBadge]="8"
          [online]="true"
          [src]="undefined"
          [srcVersion]="1"
        ></mdw-avatar>
        <mdw-avatar
          name="My User"
          [color]="1"
          alt="Avatar of Initials"
          size="large"
          [numberBadge]="14"
          [online]="true"
          [src]="undefined"
          [srcVersion]="1"
        ></mdw-avatar>
        <mdw-avatar
          name="Initialed User"
          [color]="1"
          alt="Avatar of Initials"
          size="x-large"
          [numberBadge]="3"
          [online]="true"
          [src]="undefined"
          [srcVersion]="1"
        ></mdw-avatar>

        <mdw-avatar
          name="Initialed User"
          [color]="1"
          alt="Avatar of Initials"
          size="jumbo"
          [numberBadge]="3"
          [online]="true"
          [src]="undefined"
          [srcVersion]="1"
        ></mdw-avatar>
      </div>
    </div>
  </ng-template>
  <app-sample [sampleContent]="avatarSizes" sampleName="avatarSizes"></app-sample>

  <h3>Avatar with Tooltip</h3>
  <p>
    The tooltip is the meadow tooltip, and would need to be imported into the component to make this
    work.
  </p>
  <ng-template #avatarWithTooltip data-sample="avatarWithTooltip">
    <div class="vflex-200">
      <div>
        <mdw-avatar
          name="Xtra Large"
          [color]="1"
          alt="Avatar of Initials"
          size="x-large"
          matTooltip="Avatar with Tooltip"
          [online]="true"
          [src]="undefined"
          [srcVersion]="1"
        ></mdw-avatar>
      </div>
    </div>
  </ng-template>
  <app-sample [sampleContent]="avatarWithTooltip" sampleName="avatarWithTooltip"></app-sample>

  <h3>Stacked avatars</h3>
  <p>
    The CSS class
    <code>stacked-avatars</code>
    will created a grouped represenation of multiple avatars. There's no limit, but the recommended
    number of items is 3.
  </p>
  <ng-template #stackedAvatars data-sample="stackedAvatars">
    <div class="vflex-200">
      <div class="stacked-avatars">
        <mdw-avatar
          src="https://blue-api.dev.medchatapp.com/orgs/39da3946-82e5-5612-0958-cbc25f0e076d/userPhotos/2fea6b81-6310-40a3-8df3-79b0c66fbaaa?version=48"
          alt="Robbie's avatar"
          [srcVersion]="1"
          [color]="1"
          name="Robbie"
        ></mdw-avatar>
        <mdw-avatar
          name="Layton Miller"
          alt="Layton's avatar"
          [color]="8"
          [srcVersion]="1"
          [color]="1"
          [src]="undefined"
        ></mdw-avatar>
        <mdw-avatar
          src="https://blue-api.dev.medchatapp.com/orgs/39da3946-82e5-5612-0958-cbc25f0e076d/userPhotos/32ecbef7-aa3a-4fbe-882a-69e5d6e1db73?version=1"
          alt="Robbie's avatar"
          [srcVersion]="1"
          [color]="1"
          name="Robbie"
        ></mdw-avatar>
      </div>

      <div class="stacked-avatars">
        <mdw-avatar
          src="https://blue-api.dev.medchatapp.com/orgs/39da3946-82e5-5612-0958-cbc25f0e076d/userPhotos/2fea6b81-6310-40a3-8df3-79b0c66fbaaa?version=48"
          alt="Robbie's avatar"
          size="small"
          [srcVersion]="1"
          [color]="1"
          name="Robbie"
        ></mdw-avatar>
        <mdw-avatar
          name="Layton Miller"
          alt="Layton's avatar"
          [color]="8"
          size="small"
          [srcVersion]="1"
          [src]="undefined"
        ></mdw-avatar>
        <mdw-avatar
          src="https://blue-api.dev.medchatapp.com/orgs/39da3946-82e5-5612-0958-cbc25f0e076d/userPhotos/32ecbef7-aa3a-4fbe-882a-69e5d6e1db73?version=1"
          alt="Robbie's avatar"
          size="small"
          [srcVersion]="1"
          [color]="1"
          name="Robbie"
        ></mdw-avatar>
      </div>

      <div class="stacked-avatars">
        <mdw-avatar
          src="https://blue-api.dev.medchatapp.com/orgs/39da3946-82e5-5612-0958-cbc25f0e076d/userPhotos/2fea6b81-6310-40a3-8df3-79b0c66fbaaa?version=48"
          alt="Robbie's avatar"
          size="medium"
          [srcVersion]="1"
          [color]="1"
          name="Robbie"
        ></mdw-avatar>
        <mdw-avatar
          name="Layton Miller"
          alt="Layton's avatar"
          [color]="8"
          size="medium"
          [srcVersion]="1"
          [src]="undefined"
        ></mdw-avatar>
        <mdw-avatar
          src="https://blue-api.dev.medchatapp.com/orgs/39da3946-82e5-5612-0958-cbc25f0e076d/userPhotos/32ecbef7-aa3a-4fbe-882a-69e5d6e1db73?version=1"
          alt="Robbie's avatar"
          size="medium"
          [srcVersion]="1"
          [color]="1"
          name="Robbie"
        ></mdw-avatar>
      </div>

      <div class="stacked-avatars">
        <mdw-avatar
          src="https://blue-api.dev.medchatapp.com/orgs/39da3946-82e5-5612-0958-cbc25f0e076d/userPhotos/2fea6b81-6310-40a3-8df3-79b0c66fbaaa?version=48"
          alt="Robbie's avatar"
          size="x-large"
          [srcVersion]="1"
          [color]="1"
          name="Robbie"
        ></mdw-avatar>
        <mdw-avatar
          name="Layton Miller"
          alt="Layton's avatar"
          [color]="8"
          size="x-large"
          [srcVersion]="1"
          [src]="undefined"
        ></mdw-avatar>
        <mdw-avatar
          src="https://blue-api.dev.medchatapp.com/orgs/39da3946-82e5-5612-0958-cbc25f0e076d/userPhotos/32ecbef7-aa3a-4fbe-882a-69e5d6e1db73?version=1"
          alt="Robbie's avatar"
          size="x-large"
          [srcVersion]="1"
          [color]="1"
          name="Robbie"
        ></mdw-avatar>
      </div>
    </div>
  </ng-template>
  <app-sample [sampleContent]="stackedAvatars" sampleName="stackedAvatars"></app-sample>

  <h3>Squircle Avatars</h3>
  <p>
    Add the
    <code>squircle</code>
    class to the avatar to make it a squircle.
  </p>
  <p>
    This is just a specialized look used to differentiate a different type of entity, for example
    users will be represented as circles (default) but orgs will be represented as squircles
    (rounded squares)
  </p>
  <p>
    <b>NOTE!</b>
    the squicle avatar currently does not support any status indicators, and it also does not yet
    support stacked-avatars, but it will in the future.
  </p>
  <ng-template #squircleAvatars data-sample="squircleAvatars">
    <div class="vflex-200">
      <div class="hflex-200">
        <mdw-avatar
          name="Some Org"
          [color]="4"
          size="small"
          class="squircle"
          [srcVersion]="1"
          [src]="undefined"
        ></mdw-avatar>
        <mdw-avatar
          name="Another User"
          [color]="1"
          size="medium"
          class="squircle"
          [srcVersion]="1"
          [src]="undefined"
        ></mdw-avatar>
        <mdw-avatar
          name="My User"
          [color]="1"
          alt="Avatar of Initials"
          size="large"
          class="squircle"
          [srcVersion]="1"
          [src]="undefined"
        ></mdw-avatar>
        <mdw-avatar
          name="Initialed User"
          [color]="1"
          alt="Avatar of Initials"
          size="x-large"
          class="squircle"
          [srcVersion]="1"
          [src]="undefined"
        ></mdw-avatar>
      </div>

      <div class="stacked-avatars">
        <mdw-avatar
          src="https://blue-api.dev.medchatapp.com/orgs/39da3946-82e5-5612-0958-cbc25f0e076d/userPhotos/2fea6b81-6310-40a3-8df3-79b0c66fbaaa?version=48"
          alt="Robbie's avatar"
          class="squircle"
          name="Robbie"
          [color]="1"
          [srcVersion]="1"
        ></mdw-avatar>
        <mdw-avatar
          name="Layton Miller"
          alt="Layton's avatar"
          [color]="8"
          class="squircle"
          [src]="undefined"
          [srcVersion]="1"
        ></mdw-avatar>
        <mdw-avatar
          name="Carl"
          class="squircle"
          [color]="1"
          [src]="undefined"
          [srcVersion]="1"
        ></mdw-avatar>
      </div>
    </div>
  </ng-template>
  <app-sample [sampleContent]="squircleAvatars" sampleName="squircleAvatars"></app-sample>
</div>
