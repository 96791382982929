import { CommonModule } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'mdw-icon',
  standalone: true,
  imports: [CommonModule],
  template: `
    <span [innerHTML]="svg()"></span>
  `,
})
export class IconComponent {
  private sanitize = inject(DomSanitizer);
  public icon = input('');
  private _icon!: string; // programmatic icon setting

  // setIcon is used to set an icon when rendering the component programmatically
  public setIcon(icon: string) {
    this._icon = icon;
  }

  public svg = computed(() => {
    return this.sanitize.bypassSecurityTrustHtml(this.icon() || this._icon);
  });
}
