<div class="vflex-300">
  <h2>Dialogs</h2>
  <div class="surface-muted pad-200">This page is a work in progress.</div>
  <div class="surface-muted pad-200">
    <code>import &#123; DialogService &#125; from '&#64;spaces-ui/meadow/components/dialog';</code>
  </div>

  <h3>Alert Dialog</h3>
  <p>
    This is an opinionated dialog that just takes a
    <b>message</b>
    , and optionally a
    <b>title</b>
    and
    <b>buttonText</b>
    .
  </p>
  <ng-template #alertDialog data-sample="alertDialog">
    <section class="vflex-300 flex-no-stretch">
      <button class="mdw-button mdw-primary" (click)="triggerAlert()">Trigger Alert</button>
      <button class="mdw-button mdw-danger" (click)="triggerAlert(true)">
        Trigger Danger Alert
      </button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="alertDialog" sampleName="alertDialog"></app-sample>

  <h3>Confirmation Dialog</h3>
  <p>
    This is dialog that takes a config object that allows you to specify the content without the
    need to create a custom dialog component.
  </p>
  <ng-template #confirmationDialog data-sample="confirmationDialog">
    <section class="vflex-300 flex-no-stretch">
      <button class="mdw-button mdw-primary" (click)="triggerConfirmationDialog()">
        Trigger Confirm
      </button>
      <button class="mdw-button mdw-danger" (click)="triggerConfirmationDialog(true)">
        Trigger Danger Confirm
      </button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="confirmationDialog" sampleName="confirmationDialog"></app-sample>

  <h3>Simple Form Dialog</h3>
  <ng-template #simpleFormDialog data-sample="simpleFormDialog">
    <section class="vflex-300 flex-no-stretch">
      <button class="mdw-button mdw-primary" (click)="triggerSimpleFormDialog()">
        Trigger Simple Form
      </button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="simpleFormDialog" sampleName="simpleFormDialog"></app-sample>

  <h3>BAM Dialog</h3>
  <p>
    BAM (a.k.a. as Big A$$ Modal) dialogs take over the entire viewport. The dialog component should
    have a container with a class of
    <code>bam-content</code>
    , which would make the content full width and height.
  </p>
  <ng-template #bamDialog data-sample="bamDialog">
    <section class="vflex-300 flex-no-stretch">
      <button class="mdw-button mdw-primary" (click)="triggerBamDialog()">Trigger BAM</button>
    </section>
  </ng-template>
  <app-sample [sampleContent]="bamDialog" sampleName="bamDialog"></app-sample>
</div>
