import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { EmojiComponent } from '@ctrl/ngx-emoji-mart/ngx-emoji';

@Component({
  selector: 'mdw-emoji',
  templateUrl: './mdw-emoji.component.html',
  styleUrls: ['./mdw-emoji.component.scss'],
  standalone: true,
  imports: [CommonModule, EmojiComponent],
})
export class MdwEmojiComponent {
  public emojiName = input.required<string>();
}
