import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';

import { AsideComponent } from '@spaces-ui/meadow/components/aside';
import { ButtonComponent } from '@spaces-ui/meadow/components/button';

import { SampleComponent } from '../../components';

@Component({
  selector: 'app-app-elements',
  standalone: true,
  imports: [CommonModule, SampleComponent, AsideComponent, ButtonComponent],
  templateUrl: './app-elements.component.html',
  styleUrl: './app-elements.component.scss',
})
export class AppElementsComponent {
  public isCollapsed = signal<boolean>(false);
  public isFloating = signal<boolean>(false);
  public isSecondaryCollapsed = signal<boolean>(false);
  public isSecondaryFloating = signal<boolean>(false);
}
