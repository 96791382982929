import { CommonModule } from '@angular/common';
import { Component, computed, HostBinding, input } from '@angular/core';

import { IconComponent } from '@spaces-ui/meadow/icons';
import {
  BadgeNumber_1,
  BadgeNumber_2,
  BadgeNumber_3,
  BadgeNumber_4,
  BadgeNumber_5,
  BadgeNumber_6,
  BadgeNumber_7,
  BadgeNumber_8,
  BadgeNumber_9,
  BadgeNumber_9plus,
  Status_Offline,
  Status_Offline_snoozed,
  Status_Online,
  Status_Online_snoozed,
  User_Fill,
} from '@spaces-ui/meadow/icons/svgs';

@Component({
  selector: 'mdw-avatar',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
})
export class AvatarComponent {
  public src = input.required<string | undefined>();
  public srcVersion = input.required<number | undefined>();
  public name = input.required<string>();
  public color = input.required<number | undefined>(); // value between 1 and 8

  public alt = input<string>();
  public size = input<'small' | 'medium' | 'large' | 'x-large' | 'jumbo' | 'upload-preview'>(
    'medium',
  );
  public isDeleted = input<boolean>();

  // status
  public online = input<boolean>();
  public offline = input<boolean>();
  public snoozed = input<boolean>();
  public numberBadge = input<number>();

  public srcWithVersion = computed(() => {
    const src = this.src();
    const version = this.srcVersion();

    if (!src || version === undefined) {
      return undefined;
    }

    return `${src}?v=${version}`;
  });

  public icons = {
    Status_Online,
    Status_Offline,
    Status_Online_snoozed,
    Status_Offline_snoozed,
    BadgeNumber_1,
    BadgeNumber_2,
    BadgeNumber_3,
    BadgeNumber_4,
    BadgeNumber_5,
    BadgeNumber_6,
    BadgeNumber_7,
    BadgeNumber_8,
    BadgeNumber_9,
    BadgeNumber_9plus,
    User_Fill,
  };

  @HostBinding('class')
  public get sizeClass(): string {
    return `${this.size()}-avatar`;
  }

  // public iconSize = computed(() => {
  //   if (this.size() === 'x-large') {
  //     return 'large';
  //   } else if (this.size() === 'small') {
  //     return 'medium';
  //   } else {
  //     return this.size();
  //   }
  // });
}
