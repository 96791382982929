<div class="vflex-300">
  <h2>Emojis</h2>
  <div class="surface-muted pad-200">
    <code>
      import &#123; MdwEmojiComponent, MdwEmojiPanelComponent &#125; from
      '&#64;spaces-ui/meadow/components/emojis';
    </code>
  </div>

  <h3>Emoji Panel</h3>
  <ng-template #emojiExample data-sample="emojiExample">
    <section class="vflex-300 flex-no-stretch">
      @if (selectedEmoji) {
        <mdw-emoji class="large" [emojiName]="selectedEmoji.colons"></mdw-emoji>
      }
      <mdw-emoji-panel (emojiSelect)="handleEmojiSelect($event)"></mdw-emoji-panel>
    </section>
  </ng-template>
  <app-sample [sampleContent]="emojiExample" sampleName="emojiExample"></app-sample>
</div>
