<form [formGroup]="viewForm">
  <div class="vflex-200">
    <h2>Selects</h2>
    <p>
      Selects are our custom implementation of dropdowns, with additionsl functionality like search,
      grouping, single/multi select, keyboard navigation, and more.
    </p>
    <p>
      The select component is built on top of Angular CDK's Listbox component, which provides a
      foundation for building custom select components and the options are styled with our own
      Meadow's ListItem.
    </p>
    <div class="surface-muted mdw-warning pad-300">
      <b>DEV NOTE</b>
      <p>
        The select component has built in search functionality, but due to how CDK listbox works all
        the options need to remain within the list for all the form related functionality to work
        correctly. This means that search doesn't filter the list, but rather disables those options
        that don't match the search criteria.
      </p>
      <p>
        These disabled options are hidden from view (by setting height to 0), but are still present
        in the list.
      </p>
      <p>Just be aware of this for when you need to modify the component.</p>
    </div>
    <h3>Basic Select</h3>
    <p>This shows a simple custom version of a basic dropdown list</p>
    <ng-template #basicSelect data-sample="basicSelect">
      <div class="vflex-200">
        <mdw-select
          label="State"
          description="Select the state where your primary work hours occur"
          [options]="basicOptions"
          [placeholder]="'Select an option'"
          formControlName="state"
        ></mdw-select>
        <div>
          <button class="mdw-button mdw-primary" (click)="selectState('MD')">Select 'MD'</button>
        </div>
      </div>
    </ng-template>
    <app-sample [sampleContent]="basicSelect" sampleName="basicSelect"></app-sample>

    <h3>Basic Multi Select</h3>
    <p>
      This shows a simple custom version of a basic dropdown list but it allows multiple selected
      options
    </p>
    <ng-template #basicMultiSelect data-sample="basicMultiSelect">
      <div class="vflex-200">
        <mdw-select
          label="State"
          description="Select the state where your primary work hours occur"
          [options]="basicOptions"
          [placeholder]="'Select an option'"
          [multiSelect]="true"
          formControlName="states"
        ></mdw-select>

        <div>
          <button class="mdw-button mdw-primary" (click)="selectStates(['WA', 'GA'])">
            Select 'WA' and 'GA'
          </button>
        </div>
      </div>
    </ng-template>
    <app-sample [sampleContent]="basicMultiSelect" sampleName="basicMultiSelect"></app-sample>

    <h3>Rich Object List with Custom Templates</h3>
    <p>
      By default, the select component accepts an array of strings, but it can also support objects.
      For This to work you need to either provide a custom template, or provide properties for the
      label and value of the option.
    </p>
    <h4>Label and Value fields</h4>
    <p>
      The select component can accept
      <code>labelProperty</code>
      (default: 'label') and
      <code>valueProperty</code>
      (default: 'value').
    </p>
    <ng-template #labelAndValueSelect data-sample="labelAndValueSelect">
      <div class="vflex-200">
        <mdw-select
          label="State Abbreviation as Value"
          description="Select the state where your primary work hours occur"
          [options]="objectOptions"
          [placeholder]="'Select an option'"
          labelProperty="name"
          valueProperty="abbreviation"
          formControlName="richStateAbbreviation"
        ></mdw-select>
        <mdw-select
          label="Entire State Object as Value"
          description="Select the state where your primary work hours occur"
          [options]="objectOptions"
          [placeholder]="'Select an option'"
          labelProperty="name"
          formControlName="richState"
        ></mdw-select>
      </div>
    </ng-template>
    <app-sample [sampleContent]="labelAndValueSelect" sampleName="labelAndValueSelect"></app-sample>

    <h3>Select with Item Template</h3>
    <p>
      The select component can also accept a custom template for each item in the list. This is
      useful when you need to display more than just a simple string in the dropdown.
    </p>
    <p>
      You should still provide a labelProperty so the selected value is displayed correctly in the
      input field.
    </p>
    <ng-template #templateItemSelect data-sample="templateItemSelect">
      <mdw-select
        label="State Abbreviation"
        description="Select the state where your primary work hours occur"
        [options]="objectOptions"
        [placeholder]="'Select a state from the list'"
        labelProperty="name"
        valueProperty="abbreviation"
        formControlName="templateStateAbbreviation"
        [templateRef]="itemTemplate"
      ></mdw-select>
      <ng-template let-option #itemTemplate>
        <div class="pad-100">
          <div>{{ option.name }}</div>
          <div class="secondary">
            {{ option.abbreviation }}
            <small>{{ option.statehoodDate }}</small>
          </div>
        </div>
      </ng-template>
    </ng-template>
    <app-sample [sampleContent]="templateItemSelect" sampleName="templateItemSelect"></app-sample>

    <h3>Select with Selected Item Template</h3>
    <p>
      The select component can also accept a custom template for each selected item in the trigger.
      This is useful when you need to display more than just a simple string in the trigger.
    </p>
    <p>
      You can optionally allow the selected items to be dismissed from the trigger (defaults to
      true)
    </p>
    <ng-template #selectedItemTemplateItemSelect data-sample="selectedItemTemplateItemSelect">
      <mdw-select
        label="State Abbreviation"
        description="Select the state where your primary work hours occur"
        [options]="objectOptions"
        [placeholder]="'Select a state from the list'"
        labelProperty="name"
        valueProperty="abbreviation"
        formControlName="templateStateAbbreviation"
        [multiSelect]="true"
        [templateRef]="itemTemplate"
        [selectedItemTemplateRef]="itemTemplate"
      ></mdw-select>
      <ng-template let-option #itemTemplate>
        <div class="pad-100 hflex-050">
          <div>{{ option.name }}</div>
          <div class="secondary">
            {{ option.abbreviation }}
            <small>{{ option.statehoodDate }}</small>
          </div>
        </div>
      </ng-template>
    </ng-template>
    <app-sample
      [sampleContent]="selectedItemTemplateItemSelect"
      sampleName="selectedItemTemplateItemSelect"
    ></app-sample>

    <div class="surface-muted pad-200">
      <b>Form Value:</b>
      {{ viewForm.value | json }}
    </div>
  </div>
</form>
