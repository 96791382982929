import {
  trigger,
  style,
  animate,
  state,
  transition,
  AnimationMetadata,
  animateChild,
  query,
} from '@angular/animations';

import { AnimationState } from './animation-state';

const fadeInGenerator = (
  animationEnterExtras: Record<string, string | number>,
  animationLeaveExtras: Record<string, string | number>,
  useState: boolean,
  animationDurationSec: number,
  animationDelaySec: number,
  animationEasing: string,
) => {
  const animation: AnimationMetadata[] = [];

  if (useState) {
    animation.push(
      state(AnimationState.NotAnimated, style({ opacity: 0, ...animationEnterExtras })),
    );
    animation.push(state(AnimationState.Animated, style({ opacity: 1, ...animationLeaveExtras })));
    animation.push(
      transition(`${AnimationState.NotAnimated} => ${AnimationState.Animated}`, [
        animate(`${animationDurationSec}s ${animationDelaySec}s ${animationEasing}`),
      ]),
    );
  } else {
    animation.push(
      transition(':enter', [
        style({ opacity: 0, ...animationEnterExtras }),
        animate(
          `${animationDurationSec}s ${animationDelaySec}s ${animationEasing}`,
          style({ opacity: 1, ...animationLeaveExtras }),
        ),
        query('@*', animateChild(), { optional: true }),
      ]),
    );
    animation.push(
      transition(':leave', [
        style({ opacity: 1, ...animationLeaveExtras }),
        query('@*', animateChild(), { optional: true }),
        animate(
          `${animationDurationSec}s ${animationEasing}`, // configurable delay is only for :enter, so it's not used here
          style({ opacity: 0, ...animationEnterExtras }),
        ),
      ]),
    );
  }
  return animation;
};

const fadeInAndScaleGenerator = (
  animationEnterExtras: Record<string, string | number>,
  animationLeaveExtras: Record<string, string | number>,
  useState: boolean,
  animationDurationSec: number,
  animationDelaySec: number,
  animationEasing: string,
  startingScale: number,
  endingScale: number,
) => {
  const startTransform = { transform: `scale(${startingScale})` };
  const endTransform = { transform: `scale(${endingScale})` };

  const enterExtras = { ...startTransform, ...animationEnterExtras };
  const leaveExtras = { ...endTransform, ...animationLeaveExtras };

  return fadeInGenerator(
    enterExtras,
    leaveExtras,
    useState,
    animationDurationSec,
    animationDelaySec,
    animationEasing,
  );
};

const fadeInAndSlideGenerator = (
  animationEnterExtras: Record<string, string | number>,
  animationLeaveExtras: Record<string, string | number>,
  useState: boolean,
  animationDurationSec: number,
  animationDelaySec: number,
  animationEasing: string,
  startingDistance: number,
  endingDistance: number,
) => {
  const startTransform = { transform: `translateY(${startingDistance}px)` };
  const endTransform = { transform: `translateY(${endingDistance}px)` };

  const enterExtras = { ...startTransform, ...animationEnterExtras };
  const leaveExtras = { ...endTransform, ...animationLeaveExtras };

  return fadeInGenerator(
    enterExtras,
    leaveExtras,
    useState,
    animationDurationSec,
    animationDelaySec,
    animationEasing,
  );
};

export const fadeInAnimation = (
  _opts: Partial<{
    animationName: string;
    useState: boolean;
    animationDurationSec: number;
    animationDelaySec: number;
    animationEasing: string;
    animationEnterExtras: Record<string, string | number>;
    animationLeaveExtras: Record<string, string | number>;
  }> = {},
) => {
  const opts = {
    animationName: 'fadeIn',
    useState: false,
    animationDurationSec: 0.2,
    animationDelaySec: 0.2,
    animationEasing: 'ease-in',
    animationEnterExtras: {},
    animationLeaveExtras: {},
    ..._opts,
  };

  return trigger(opts.animationName, [
    ...fadeInGenerator(
      opts.animationEnterExtras,
      opts.animationLeaveExtras,
      opts.useState,
      opts.animationDurationSec,
      opts.animationDelaySec,
      opts.animationEasing,
    ),
  ]);
};

export const fadeInAndScale = (
  _opts: Partial<{
    startingScale: number;
    endingScale: number;
    animationName: string;
    useState: boolean;
    animationDurationSec: number;
    animationDelaySec: number;
    animationEasing: string;
    animationEnterExtras: Record<string, string | number>;
    animationLeaveExtras: Record<string, string | number>;
  }> = {},
) => {
  const opts = {
    startingScale: 0.7,
    endingScale: 1,
    animationName: 'fadeInAndScale',
    useState: false,
    animationDurationSec: 0.2,
    animationDelaySec: 0.2,
    animationEasing: 'ease-in',
    animationEnterExtras: {},
    animationLeaveExtras: {},
    ..._opts,
  };

  return trigger(opts.animationName, [
    ...fadeInAndScaleGenerator(
      opts.animationEnterExtras,
      opts.animationLeaveExtras,
      opts.useState,
      opts.animationDurationSec,
      opts.animationDelaySec,
      opts.animationEasing,
      opts.startingScale,
      opts.endingScale,
    ),
  ]);
};

export const fadeInAndSlide = (
  _opts: Partial<{
    startingDistance: number;
    endingDistance: number;
    animationName: string;
    useState: boolean;
    animationDurationSec: number;
    animationDelaySec: number;
    animationEasing: string;
    animationEnterExtras: Record<string, string | number>;
    animationLeaveExtras: Record<string, string | number>;
  }> = {},
) => {
  const opts = {
    startingDistance: 6,
    endingDistance: 0,
    animationName: 'fadeInAndScale',
    useState: false,
    animationDurationSec: 0.2,
    animationDelaySec: 0,
    animationEasing: 'ease-in',
    animationEnterExtras: {},
    animationLeaveExtras: {},
    ..._opts,
  };

  return trigger(opts.animationName, [
    ...fadeInAndSlideGenerator(
      opts.animationEnterExtras,
      opts.animationLeaveExtras,
      opts.useState,
      opts.animationDurationSec,
      opts.animationDelaySec,
      opts.animationEasing,
      opts.startingDistance,
      opts.endingDistance,
    ),
  ]);
};
