<div class="vflex-400">
  <h2>Typography</h2>
  <h3>Font hierarchy</h3>
  <table>
    <thead>
      <tr>
        <th>Sample</th>
        <th>Element/Class</th>
        <th>Description</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><h1>Large heading</h1></td>
        <td>h1</td>
        <td>Large headings</td>
      </tr>
      <tr>
        <td><h2>Medium heading</h2></td>
        <td>h2</td>
        <td>Page headers, medium headings</td>
      </tr>
      <tr>
        <td><h3>Small heading</h3></td>
        <td>h3</td>
        <td>Sub headers, small headings</td>
      </tr>
      <tr>
        <td class="default">Body Default</td>
        <td>
          default body or
          <pre>.default</pre>
        </td>
        <td>Default body copy, paragraph text</td>
      </tr>
      <tr>
        <td class="dense">Body dense</td>
        <td><pre>.dense</pre></td>
        <td>Message transcripts, tables, dense body copy</td>
      </tr>
      <tr>
        <td class="small">Small</td>
        <td>
          small or
          <pre>.small</pre>
        </td>
        <td>Helper text, tertiary info</td>
      </tr>
      <tr>
        <td class="body-default"><strong>Bold</strong></td>
        <td>strong, b, .strong, .bold</td>
        <td>Strong emphasis, bold text</td>
      </tr>
    </tbody>
  </table>
</div>
