import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { AvatarComponent } from '@spaces-ui/meadow/components/avatar';
import { ChipComponent } from '@spaces-ui/meadow/components/chip';
import { IconComponent } from '@spaces-ui/meadow/icons';
import { Apple_Fill } from '@spaces-ui/meadow/icons/svgs';

import { SampleComponent } from '../../components';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
  standalone: true,
  imports: [CommonModule, ChipComponent, SampleComponent, IconComponent, AvatarComponent],
})
export class ChipsComponent {
  public icons = { Apple_Fill };
}
