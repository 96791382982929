<div class="interactive-overlay">
  @if (icon()) {
    <mdw-icon [icon]="icon()!" [ngClass]="classes()"></mdw-icon>
  }
  <span class="text-content" #textContent>
    <ng-content></ng-content>
  </span>
  @if (badge()) {
    <div @fadeIn class="mdw-notification-badge">{{ badge() }}</div>
  } @else if (passiveBadge()) {
    <div @fadeIn class="mdw-notification-passive-badge"></div>
  }
  @if (showDownArrow()) {
    <mdw-icon [icon]="icons.DropdownArrow"></mdw-icon>
  }
</div>
