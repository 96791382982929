<h1>Colors</h1>
<p>
  We use a combination of neutrals and accents to bring about the right balance of color in our UI.
</p>
<div class="color-grid">
  <!-- Neutrals -->
  <div
    class="color-box shadow-300"
    *ngFor="let color of neutrals"
    [ngStyle]="{ 'background-color': color.hex }"
  >
    <span class="tooltip">{{ color.name }}: {{ color.hex }}</span>
  </div>
  <!-- Blues -->
  <div
    class="color-box shadow-300"
    *ngFor="let color of blues"
    [ngStyle]="{ 'background-color': color.hex }"
  >
    <span class="tooltip">{{ color.name }}: {{ color.hex }}</span>
  </div>
  <!-- Greens -->
  <div
    class="color-box shadow-300"
    *ngFor="let color of greens"
    [ngStyle]="{ 'background-color': color.hex }"
  >
    <span class="tooltip">{{ color.name }}: {{ color.hex }}</span>
  </div>
  <!-- Teals -->
  <div
    class="color-box shadow-300"
    *ngFor="let color of teals"
    [ngStyle]="{ 'background-color': color.hex }"
  >
    <span class="tooltip">{{ color.name }}: {{ color.hex }}</span>
  </div>
  <!-- Yellows -->
  <div
    class="color-box shadow-300"
    *ngFor="let color of yellows"
    [ngStyle]="{ 'background-color': color.hex }"
  >
    <span class="tooltip">{{ color.name }}: {{ color.hex }}</span>
  </div>
  <!-- Oranges -->
  <div
    class="color-box shadow-300"
    *ngFor="let color of oranges"
    [ngStyle]="{ 'background-color': color.hex }"
  >
    <span class="tooltip">{{ color.name }}: {{ color.hex }}</span>
  </div>
  <!-- Reds -->
  <div
    class="color-box shadow-300"
    *ngFor="let color of reds"
    [ngStyle]="{ 'background-color': color.hex }"
  >
    <span class="tooltip">{{ color.name }}: {{ color.hex }}</span>
  </div>
  <!-- Pinks -->
  <div
    class="color-box shadow-300"
    *ngFor="let color of pinks"
    [ngStyle]="{ 'background-color': color.hex }"
  >
    <span class="tooltip">{{ color.name }}: {{ color.hex }}</span>
  </div>
  <!-- Purples -->
  <div
    class="color-box shadow-300"
    *ngFor="let color of purples"
    [ngStyle]="{ 'background-color': color.hex }"
  >
    <span class="tooltip">{{ color.name }}: {{ color.hex }}</span>
  </div>
</div>
