import { Subject } from 'rxjs';

export enum StorageType {
  Local = 'localStorage',
  Session = 'sessionStorage',
}

export interface NoAccessWrite {
  storageType: StorageType;
  key: string;
  value: string;
}

const DEFAULT_PREFIX = 'spaces-ui';

export class BrowserStorageService {
  public static hasAccessToStorage = true;

  private static localStorageDict: Record<string, string> = {};
  private static sessionStorageDict: Record<string, string> = {};
  private static _noAccessWrites$ = new Subject<NoAccessWrite>();

  public static noAccessWrites$ = this._noAccessWrites$.asObservable();

  public static getLocalStorageItem(key: string, prefix?: string) {
    const storageKey = `${prefix ?? DEFAULT_PREFIX}-${key}`;
    return this.getItem(storageKey, StorageType.Local);
  }

  public static setLocalStorageItem(key: string, value: string, prefix?: string) {
    const storageKey = `${prefix ?? DEFAULT_PREFIX}-${key}`;
    this.setItem(storageKey, value, StorageType.Local);
  }

  public static removeLocalStorageItem(key: string, prefix?: string) {
    const storageKey = `${prefix ?? DEFAULT_PREFIX}-${key}`;
    this.removeItem(storageKey, StorageType.Local);
  }

  public static getSessionStorageItem(key: string, prefix?: string) {
    const storageKey = `${prefix ?? DEFAULT_PREFIX}-${key}`;
    return this.getItem(storageKey, StorageType.Session);
  }

  public static setSessionStorageItem(key: string, value: string, prefix?: string) {
    const storageKey = `${prefix ?? DEFAULT_PREFIX}-${key}`;
    this.setItem(storageKey, value, StorageType.Session);
  }

  public static removeSessionStorageItem(key: string, prefix?: string) {
    const storageKey = `${prefix ?? DEFAULT_PREFIX}-${key}`;
    this.removeItem(storageKey, StorageType.Session);
  }

  private static getAvailableStorage(storageType: StorageType) {
    if (!this.hasAccessToStorage) {
      // no need to keep throwing errors on every storage hit
      // once we fail the first time, don't attempt to get storage again
      return undefined;
    }

    try {
      const storageTest = 'spaces-storage-test';
      const storage = storageType === StorageType.Session ? sessionStorage : localStorage;
      storage.setItem(storageTest, storageTest);
      storage.removeItem(storageTest);

      return storage;
    } catch (e) {
      console.warn(`Storage not Available: `, e);
      this.hasAccessToStorage = false;

      return undefined;
    }
  }

  private static getItem(key: string, storageType: StorageType) {
    const storage = this.getAvailableStorage(storageType);
    if (storage) {
      return storage.getItem(key);
    }

    if (storageType === StorageType.Session) {
      return this.sessionStorageDict[key];
    } else {
      return this.localStorageDict[key];
    }
  }

  private static setItem(key: string, value: string, storageType: StorageType) {
    const storage = this.getAvailableStorage(storageType);
    const parsedValue = this.getParsedValue(value);
    if (storage) {
      storage.setItem(key, parsedValue);
    } else {
      this._noAccessWrites$.next({
        storageType,
        key,
        value: parsedValue,
      });
    }

    if (storageType === StorageType.Session) {
      this.sessionStorageDict[key] = parsedValue;
    } else {
      this.localStorageDict[key] = parsedValue;
    }
  }

  private static removeItem(key: string, storageType: StorageType = StorageType.Session) {
    const storage = this.getAvailableStorage(storageType);
    if (storage) {
      storage.removeItem(key);
    }
  }

  private static getParsedValue(value: string) {
    try {
      return JSON.stringify(JSON.parse(value));
    } catch (err) {
      return value;
    }
  }
}
